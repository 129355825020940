export const experimentConfig = {
    'PPLP Date': {
      Datepicker: 0.5,
      Dropdown: 0.5,
    }, 
    'SOLP Date': {
      Datepicker: 0.5,
      Dropdown: 0.5,
    },
  };
  
