import React from 'react'

import { useLocale } from '../../hooks';
import allMessages from './messages';
import { cn, formatCurrency } from '../../utils';
import Button from '../Button';
import { paths } from '../../routes';

import { wrapper, historyRow, infoRow, balance, statusTag, paidTag, failureTag, pendingTag } from './styles.scss';

const PaymentHistorySummarized = ({ history, length }) => {
  const { formatDate, messages } = useLocale(allMessages);

  const mapStatusCode = (statusCode) => {
    switch (statusCode.toLowerCase()) {
      case 'paid':
        return paidTag
      case 'failure':
        return failureTag
      default:
      case 'pending':
        return null
    }
  }

  return (
    <div className={wrapper}>
      <h4>{messages.header}</h4>
      {history.slice(0, length || history.length).map((payment, i) => (
        <div className={historyRow} key={i}>
          <div className={infoRow}>
            <span>{formatDate(payment.date, { month: 'long', day: 'numeric' })}</span>
            <span>{messages.paymentMethod[payment.paymentMethod]}</span>
          </div>
          <div className={infoRow}>
            <div className={balance}>
              <span>$ <h5>{formatCurrency(payment.amount, '', true)}</h5></span>
            </div>
            <span className={cn(statusTag, mapStatusCode(payment.statusCode))}>
              {messages.statusCode[payment.statusCode]}
            </span>
          </div>
        </div>
      ))}
      <Button variant={['secondary', 'full']} href={paths.paymentHistory}>
        {messages.viewAll}
      </Button>
    </div>
  )
}

export default PaymentHistorySummarized;
