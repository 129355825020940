import React from 'react';
import { useLocale, useOnce, useStateView } from '../../hooks';
import { accountView } from '../../state/views';
import { mixpanel } from '../../utils';

import allMessages from './messages';
import { accountDetails } from './styles.scss';
import { MIXPANEL_EVENTS, PAYMENT_INFO_FORMAT } from '../../constants';

const AccountDetails = () => {
  const { formatDate, messages } = useLocale(allMessages);
  const [ account ] = useStateView(accountView);

  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewAccountDetailsPage));

  return (
    <table className={accountDetails}>
      <tbody>
        {account.cardStoreBrand && <tr>
          <th>{messages.brandName}</th>
          <td>{account.cardStoreBrand}</td>
        </tr>}
        <tr>
          <th>{messages.originalCreditor}</th>
          <td>{account.clientName || messages.notAvailable}</td>
        </tr>
        <tr>
          <th>{messages.originalAccountNumber}</th>
          <td>{account.originalAccountNumber}</td>
        </tr>
        <tr>
          <th>{messages.referenceNumber}</th>
          <td>{account.accountId}</td>
        </tr>
        <tr>
          <th>{messages.accountOpenedDate}</th>
          <td>{formatDate(account.accountOpenedDate, PAYMENT_INFO_FORMAT)}</td>
        </tr>
      </tbody>
    </table>
  )
};

export default AccountDetails;
