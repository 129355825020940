import React, { useState } from 'react';

import {
  stepper,
  progressBar,
  currentStep,
  title,
  twoGrid,
  flexBox,
  dropdownBox,
  bordered,
  percentageRow,
  percentageMessage,
  numberInputWrapper,
  numberInput,
  padded,
  chooseHalfline,
  line,
  or,
  amountToPayRow
} from '../styles.scss';

import { useLocale, useStateView } from '../../../hooks';

import rightArrowIcon from '../../../static/images/icons/rightArrow.svg';
import allMessages from '../messages';
import { cn, formatCurrency, mixpanel } from '../../../utils';
import { MIXPANEL_EVENTS } from '../../../constants';
import { Button, PlanDetailBox } from '../../../components';
import { paymentPlanView } from '../../../state/views';

const PartialPaymentStep = ({
  referenceNumber,
  progress,
  remainingBalance,
  setProgress,
  stepperRef,
  setCurrentStep
}) => {
  const { formatMessage, messages } = useLocale(allMessages);
  const [ selectedValue, setSelectedValue ] = useState(-1);
  const [ checkboxToggled, setCheckboxToggled ] = useState(false);
  const [ paymentPlan, setPaymentPlan ] = useStateView(paymentPlanView);

  const percentages = [
    { label: '10%', value: .1 },
    { label: '25%', value: .25 },
    { label: '50%', value: .50 },
    { label: '75%', value: .75 },
    { label: '100%', value: 1 },
  ];

  const calculatePercentage = (value) => {
    return ((value / remainingBalance) * 100).toFixed(2);
  }

  const calculateValue = (percentage) => {
    return Math.floor(percentage * remainingBalance * 100) / 100;
  }

  const setCheckboxValue = (percentage) => {
    const amount = calculateValue(percentage);

    mixpanel.track(
      MIXPANEL_EVENTS.selectPartialPaymentPercentage,
      { percentage, amount, remainingBalance }
    );

    setCheckboxToggled(true);
    setSelectedValue(amount);
    setProgress({ ...progress, value: 2 });
    setPaymentPlan.update({ standardPaymentAmount: amount });
  };

  const setValue = (inputValue) => {
    if (inputValue === '') {
      setSelectedValue(-1);
      setPaymentPlan.update({ standardPaymentAmount: 0 });
      return;
    }

    let value = parseFloat(inputValue);
    value = value < 0 ? 0 : value;
    value = value > remainingBalance ? remainingBalance : value;

    setCheckboxToggled(false);
    setSelectedValue(value);
    setProgress({ ...progress, value: 2 });
    mixpanel.track(MIXPANEL_EVENTS.selectPartialPaymentPercentage, { percentage: calculatePercentage(value) }, remainingBalance);
    setPaymentPlan.update({ standardPaymentAmount: value });
  }

  const submitAmount = () => {
    setProgress({ ...progress, value: progress.value + 1 });
    setCurrentStep('setup');
    stepperRef.current.goToStep(2);
  }

  const percentagePaid = messages.partialPlan.percentagePaid;
  const inputToggled = selectedValue >= 0 && !checkboxToggled;

  return (
    <>
      <progress className={progressBar} value={progress.value/progress.total} />

      <div className={stepper}>
        <h5>{messages.payments}</h5>
        <img src={rightArrowIcon} />
        <h5 className={currentStep}>{messages.partialPlan.instruction}</h5>
      </div>

      <h2 className={title}>{messages.partialPlan.title}</h2>
      <p>{messages.partialPlan.subtitle}</p>

      <div className={twoGrid}>
        <div className={flexBox}>
          <div className={dropdownBox}>
            <p className={bordered}>{messages.partialPlan.boxTitle}</p>
            {checkboxToggled && selectedValue > 0 && <div className={amountToPayRow}>
              <span>{messages.amountToPay}</span>
              <strong>{formatCurrency(selectedValue)}</strong>
            </div>}
            {percentages.map((percentage, i) => (
              <div key={i} className={percentageRow}>
                <span>{percentage.label} {messages.ofBalance}</span>
                <input
                  type='radio'
                  value={percentage.value}
                  checked={checkboxToggled && selectedValue === calculateValue(percentage.value)}
                  onClick={() => setCheckboxValue(percentage.value)}
                />
              </div>
            ))}

            <div className={chooseHalfline}>
              <div className={line} />
              <div className={or}>{messages.choose}</div>
              <div className={line} />
            </div>

            <p>{messages.partialPlan.inputTitle}</p>
            <div className={numberInputWrapper}>
              {inputToggled && <span>$</span>}
              <input
                type='number'
                className={cn(numberInput, inputToggled ? padded : null)}
                value={inputToggled && selectedValue}
                placeholder='$0'
                step="0.01"
                min={0}
                max={remainingBalance}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            {inputToggled && <div className={percentageMessage}>
              <>{formatMessage(percentagePaid, { percentage: <strong>{calculatePercentage(selectedValue)}%</strong> })}</>
            </div>}
          </div>
          <Button variant={['primary', 'full']} onClick={() => submitAmount()}>{messages.continue}</Button>
        </div>

        <PlanDetailBox
          referenceNumber={referenceNumber}
          paymentPlan={paymentPlan}
          currentBalance={remainingBalance}
          planType={messages.plans[paymentPlan.planType]}
          selectedAmount={selectedValue}
          showFrequency={false}
        />
      </div>
    </>
  );
};

export default PartialPaymentStep;
