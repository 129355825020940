// Exports an object that performs console logging on every
// environment except production, allowing us to leave useful
// tidbits of dev/testing info in the console without exposing
// it to our users.

// "fail closed" if ENV is undefined or an unexpected value
export const isDebug = process.env.ENV === 'dev' || process.env.ENV === 'qa';

// the console methods we want to support
const methods = [
  'clear',
  'error',
  'group',
  'groupCollapsed',
  'groupEnd',
  'info',
  'log',
  'warn'
];

const debugConsole = {};

// if we're on prod, every one of these methods will be a no-op
// otherwise, they just call the underlying console method
for (const methodName of methods) {
  debugConsole[methodName] = isDebug
    ? (...args) => console[methodName](...args)
    : () => {};
}

export default debugConsole;
