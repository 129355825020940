import React from 'react';
import { oneOf } from 'prop-types';
import { cn } from '../../utils';
import { split, splitSmall, splitMedium, splitLarge } from './styles.scss';

const classNames = {
  small: splitSmall,
  medium: splitMedium,
  large: splitLarge
};

const Split = ({ breakpoint = 'medium', className, children }) =>
  <div className={cn(split, classNames[breakpoint], className)}>{ children }</div>;

Split.propTypes = {
  breakpoint: oneOf(Object.keys(classNames))
};

export default Split;
