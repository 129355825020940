import { getQueryParams } from '../utils';
import { useParams } from 'react-router-dom';

// react hook that returns query params combined with (and overridden by)
// url params parsed by react router

export default () => {
  return {
    ...getQueryParams(),
    ...useParams()
  };
};
