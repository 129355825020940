export default {
  en: {
    originalCreditor: 'Original Creditor',
    originalAccountNumber: 'Original Account Number',
    referenceNumber: 'Reference Number',
    accountOpenedDate: 'Date Account Opened',
    brandName: 'Brand Name'
  },
  es: {
    originalCreditor: 'Acreedor original',
    originalAccountNumber: 'Número de cuenta original',
    referenceNumber: 'Número de referencia',
    accountOpenedDate: 'Fecha de apertura de la cuenta',
    brandName: 'Nombre de la marca'
  }
};
