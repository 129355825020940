// convert an object of any type to a dependency array for useEffect/useMemo hooks
//  - objects are converted to an array containing each top-level property value,
//    sorted alphabetically by key
//  - arrays are passed through intact
//  - all other types are wrapped in a single-element array
const objectToDependencyArray = obj => {
  // typeof null === 'object', so we switch on (obj && typeof obj)
  // to prevent the 'object' case from handing obj === null
  switch (obj && typeof obj) {
    case 'array':
      return obj;
    case 'object':
      return Object.keys(obj).sort().map(key => obj[key]);
    default:
      return [ obj ];
  }
}

export default objectToDependencyArray;
