export default {
  en: {
    currentBalance: 'Current Balance',
    resolveBalance: 'Resolve Your Balance For',
    referenceNumber: 'Reference Number'
  },
  es: {
    currentBalance: 'Saldo actual',
    resolveBalance: 'Resuelva su saldo para',
    referenceNumber: 'Número de referencia'
  }
};
