import React, { useMemo } from 'react';
import { bool, object, string } from 'prop-types';
import { useLocale, useStateView } from '../../hooks';
import { cn, formatCurrency, templateString } from '../../utils';
import { PAYMENT_FREQUENCIES, PAYMENT_PLAN_TYPES } from '../../constants';
import { paymentPlanView } from '../../state/views';

import { resumedPayment, numberPayments, informationRow, withTooltip, tooltipPrint } from './styles.scss';

import { Tooltip } from 'react-tooltip'

import informationSvg from '../../static/images/icons/informationIcon.svg';
import Notice from '../Notice';

const ResumedPaymentSummary = ({ 
  paymentPlan = null,
  frequencyMessage,
  isSuccessPage = false,
  isCustomPlan = false,
  selectedAmount = null,
  fullPayment = false,
  showFrequency = true,
  telLink = false
}) => {
  const { locale, formatMessage, messages } = useLocale();

  const [ storedPaymentPlan ] = useStateView(paymentPlanView);

  if (!paymentPlan) {
    paymentPlan = storedPaymentPlan;
  }

  const {
    planType,
    numPayments,
    paymentFrequency,
    standardPaymentAmount,
    lastPaymentAmount,
    paymentSchedule
  } = paymentPlan;

  return useMemo(
    () => {
      if (!(numPayments && standardPaymentAmount)) {
        return null;
      }

      const isFinalAmountDifferent = lastPaymentAmount && standardPaymentAmount !== lastPaymentAmount;
      let numStandardPayments = isFinalAmountDifferent ? numPayments - 1 : numPayments;
      let numOtherPayments = 1;
      
      const tooltipText = messages.tooltip;
      const isMonthly = paymentFrequency === PAYMENT_FREQUENCIES.monthly;

      if (paymentSchedule && paymentSchedule.length) {
        numStandardPayments = paymentSchedule.filter(
          schedule => schedule.paymentAmount === standardPaymentAmount || schedule.amount === standardPaymentAmount
        ).length;
        numOtherPayments = numPayments - numStandardPayments;
      }

      const showPaymentFrequency = () => messages.paymentFrequencies[paymentFrequency === PAYMENT_FREQUENCIES.biweekly ? 'biweekly' : paymentFrequency];

      if (isCustomPlan) {
        return (
          <div className={resumedPayment}>
            <div>
              <h5>{messages.amount}</h5>
              <div className={informationRow}>
                <h2><span>$</span>{formatCurrency(selectedAmount, '$', true)}</h2>
                <span className={cn(numberPayments, informationRow)}>
                  {isMonthly &&
                    <>
                      <img data-tooltip-id='monthly-frequencty-tooltip' src={informationSvg} />
                      <Tooltip
                        place="bottom"
                        variant="info"
                        id='monthly-frequencty-tooltip'
                        content={tooltipText}
                        className={withTooltip}
                      />
                    </>
                  }
                  {frequencyMessage}
                </span>
              </div>
            </div>
          </div>
        )
      }
    
      return (
        <div className={resumedPayment}>
          <div>
            {!fullPayment && showFrequency && <>
              <h4>{messages.frequency}</h4>
              {frequencyMessage ? (
                <div className={informationRow}>
                  <h2>{showPaymentFrequency()}</h2>
                  <span className={cn(numberPayments, informationRow)}>
                    {isMonthly &&
                      <>
                        <img data-tooltip-id='monthly-frequencty-tooltip' src={informationSvg} />
                        <Tooltip
                          place="bottom"
                          variant="info"
                          id='monthly-frequencty-tooltip'
                          content={tooltipText}
                          className={withTooltip}
                        />
                      </>
                    }
                    {frequencyMessage}
                  </span>
                </div>
                ) : <h2>{showPaymentFrequency()}</h2>
              }
              {isSuccessPage && isMonthly && <div className={tooltipPrint}>{tooltipText}</div>}
            </>}
            {fullPayment ? <h4>{messages.totalAmount}</h4> : showFrequency ? <h5>{messages.amount}</h5> : <h4>{messages.amount}</h4>}
            <div className={informationRow}>
              <h2><span>$</span>{formatCurrency(standardPaymentAmount, '$', true)}</h2>
              <span className={numberPayments}>X{numStandardPayments}</span>
            </div>
          </div>
          <div>
            {isFinalAmountDifferent && (
              <>
                <h5>*{formatMessage(messages.resumedPaymentOf)}</h5>
                <div className={informationRow}>
                  <h2><span>$</span>{formatCurrency(lastPaymentAmount, '$', true)}</h2>
                  <span className={numberPayments}>X{numOtherPayments}</span>
                </div>
              </>
            )}
          </div>
          {telLink && <Notice>{formatMessage(messages.paymentContactNotice, { telLink: <strong>{messages.paymentContactNumber}</strong> })}</Notice>}
        </div>
      )
    },
    [
      locale,
      paymentSchedule,
      numPayments,
      planType,
      standardPaymentAmount,
      lastPaymentAmount
    ]
  );
};

ResumedPaymentSummary.propTypes = {
  paymentPlan: object
};

export default ResumedPaymentSummary;
