import React, { useMemo } from 'react';
import { useLocale, useOnce, useStateView } from '../../hooks';
import { logout, mixpanel } from '../../utils';
import { MIXPANEL_EVENTS } from '../../constants';
import allMessages from './messages';
import { Button, LegalFooter } from '../../components';

import { accountSuppressed } from './styles.scss';
import { accountView } from '../../state/views';

const AccountErrorPage = () => {
  const { formatMessage, locale, messages } = useLocale(allMessages);
  const [ , , clearAccount ] = useStateView(accountView);
  
  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewAccountSuppressedPage));

  const contactNumber = useMemo(
    () => {
      const { contactNumber } = messages;
      return <a href={`tel:+1${contactNumber.replace(/-/g, '')}`}>{contactNumber}</a>;
    },
    [ locale ]
  );

  const contactUsLink = useMemo(
    () => <a href={`https://springoakscapital.com/contact-us?lang=${encodeURIComponent(locale)}`}>{ messages.contactUs }</a>,
    [ locale ]
  );

  const tryAgain = () => {
    clearAccount();
    history?.go(-1);
  };

  return <div className={accountSuppressed}>
      <h2>{ formatMessage(messages.body, { contactNumber, contactUsLink }) }</h2>
      <Button variant={[ 'full', 'large' ]} onClick={tryAgain}>{ messages.tryAgain }</Button>
      <a onClick={() => logout()}>{ messages.orLogout }</a>
      <LegalFooter />
    </div>;
};

export default AccountErrorPage;
