export default {
  en: {
    body: 'Online access to your account is not available at this time. Please contact us at {contactNumber}\n or through the {contactUsLink} page.',
    orLogout: 'or Log Out'
  },
  es: {
    body: 'El acceso en línea a su cuenta no está disponible en este momento. Comuníquese con nosotros llamando al {contactNumber}\n o a través de la página {contactUsLink}.',
    orLogout: 'o Cerrar sesión'
  }
};
