import { debugConsole } from "../utils";
import paths from './paths';

// helper fn to create domain-relative route urls populated with params.
// params not present in the path string will be added to the query string.

export default (routeName, params = {}) => {
  if (routeName in paths === false) {
    debugConsole.error(`Error: no route found with name "${routeName}"`);
    return '#error';
  }

  // make a copy of URI encoded params that we can remove stuff from

  const paramsRemaining = {};

  for (const key in Object.keys(params)) {
    paramsRemaining[key] = encodeURIComponent(params[key]);
  }

  // interpolate url params
  
  let result = paths[routeName].replace(/:([a-z0-9]*)/ig, ([, key]) => {
    const value = paramsRemaining[key];
    delete paramsRemaining[key];
    return value;
  });

  // put any remaining params in query string

  let queryParamNames = Object.keys(paramsRemaining);

  if (queryParamNames.length > 0) {
    const queryParams = queryParamNames
      .map(key => `${key}=${paramsRemaining[key]}`)
      .join('&');
    
    result = `${result}?${queryParams}`;
  }

  return result;
};
