import React from 'react';
import { experimentConfig } from '../../constants';

// get cached variant rolls from localStorage

const storageKey = '_soc_experiments';
const variantStatus = JSON.parse(localStorage.getItem(storageKey)) || {};

// roll on variants for experiments not yet defined in localStorage

for (const experimentName in experimentConfig) {
  if (experimentName in variantStatus === true) {
    continue;
  }

  const variants = experimentConfig[experimentName];

  variantStatus[experimentName] = {};

  let totalWeights = 0;
  for (const variantName in variants) {
    totalWeights += variants[variantName];
  }

  const roll = Math.random() * totalWeights;
  let acc = 0;
  let alreadyFoundResult = false;
  let variantName;

  for (variantName in variants) {
    const variantWeight = variants[variantName];
    acc += variantWeight;
    const result = acc > roll;
    variantStatus[experimentName][variantName] = !!(result ^ alreadyFoundResult);
    alreadyFoundResult = result;
  }

  // if we still haven't had a successful match, enable last variant

  if (alreadyFoundResult === false) {
    variantStatus[experimentName][variantName] = true;
  }
}

// remove experiments that have been retired (that no longer appear in experimentsConfig)

for (const experimentName in variantStatus) {
  if (experimentName in experimentConfig === false) {
    delete variantStatus[experimentName];
  }
}

// persist updated variant rolls to localStorage

localStorage.setItem(storageKey, JSON.stringify(variantStatus));

export const getVariantStatus = () => variantStatus;
export const ExperimentContext = React.createContext(variantStatus);
export const ExperimentProvider = ({ children }) => (
  <ExperimentContext.Provider value={variantStatus}>{children}</ExperimentContext.Provider>
);
