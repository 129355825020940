import { useMemo } from "react";
import { arrayWrap } from "../utils";

const useStyleVariants = (definitions, variant) => {
  const variants = useMemo(
    () => arrayWrap(variant),
    [ variant ]
  );

  return Array.from(useMemo(
    () => variants.map(v => v in definitions && definitions[v]),
    [ definitions, ...variants ]
  ));
};

export default useStyleVariants;
