import React from 'react';
import { bool, number, oneOfType, string } from 'prop-types';
import { formatCurrency } from '../../utils';
import { useLocale } from '../../hooks';
import allMessages from './messages';
import { offerHeader, offerHeaderDesc } from './styles.scss';

const OfferHeader = ({ currentBalance = 0, offerAmount = false, referenceNumber = '' }) => {
  const { messages } = useLocale(allMessages);

  return <div className={offerHeader}>
      { offerAmount === false
          ? <div>
              <p className={offerHeaderDesc}>{ messages.currentBalance }:</p>
              <p>{ formatCurrency(currentBalance) }</p>
            </div>
          : <div>
              <p><span className={offerHeaderDesc}>{ messages.currentBalance }:</span> { formatCurrency(currentBalance) }</p>
              <p><span className={offerHeaderDesc}>{ messages.resolveBalance }:</span> { formatCurrency(offerAmount) }</p>
            </div> }
      <div>
        <p className={offerHeaderDesc}>{ messages.referenceNumber }:</p>
        <p>{ referenceNumber }</p>
      </div>
    </div>;
};

const numberOrStringRequired = oneOfType([ number, string ]).isRequired;

OfferHeader.propTypes = {
  currentBalance: numberOrStringRequired,
  offerAmount: oneOfType([ bool, number, string ]),
  referenceNumber: numberOrStringRequired
};

export default OfferHeader;
