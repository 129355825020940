import React, { useMemo } from "react";
import { number } from "prop-types";
import { useLocale } from "../../hooks";

const fns = {
  en: digits => {
    let suffix = 'th';

    switch (digits[digits.length - 1]) {
      case '1':
        suffix = digits === '11' ? 'th' : 'st';
        break;
      case '2':
        suffix = digits === '12' ? 'th' : 'nd';
        break;
      case '3':
        suffix = digits === '13' ? 'th' : 'rd';
        break;
    }

    return <>{suffix}</>;
  },
  es: () => <></>,
  default: n => n
};

const OrdinalNumber = ({ num }) => {
  const { locale } = useLocale();

  const digits = useMemo(
    () => num.toString().replace(/\D+/g, ''),
    [ num ]
  );

  const suffix = useMemo(
    () => fns[locale in fns ? locale : 'default'](digits),
    [ locale, digits ]
  );

  if (digits === '' || digits === '0') return digits;

  return <>{num}{suffix}</>;
};

OrdinalNumber.propTypes = {
  num: number.isRequired
};

export default OrdinalNumber;
