import React from 'react';
import { useLocale } from '../../hooks';
import { cn } from '../../utils';
import { notice, noticeContent } from './styles.scss';
import informationSvg from '../../static/images/icons/informationIcon.svg';

const Notice = ({ children }) => {
  const { messages } = useLocale();

  return <div className={notice}>
    <img src={informationSvg} alt={messages.notice} />
    <div className={noticeContent}>{children}</div>
  </div>;
};

export default Notice;
