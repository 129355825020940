import React from 'react';
import { string } from 'prop-types';
import { paths } from '../../routes';

import { Button } from '../';
import { HumanSittingIcon } from '../../components/icons';
import { errorPage } from './styles.scss';

const ErrorPageContent = ({
  title,
  subtitle,
  action,
  cta,
  href = paths.home,
  onClick = _e => {}
}) =>
  <div className={errorPage}>
    <HumanSittingIcon/>
    <div>
      <h1>{ title }</h1>
      <h2>{ subtitle }</h2>
      <p>{ action }</p>
    </div>
    <Button
      onClick={onClick}
      { ...(href ? { href } : {}) }
      variant={['alternate', 'outline', 'large']}>
      { cta }
    </Button>
  </div>;

ErrorPageContent.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  action: string.isRequired,
  cta: string.isRequired,
  href: string.isRequired
};

export default ErrorPageContent;
