export default {
  en: {
    header: 'Disclosures',
    continueAria: 'Continue to Portal',

    defaultDisclosures: [
      {
        paragraph:
          'This is a communication from a debt collector. This is an attempt to collect a debt and any information obtained will be used for that purpose.'
      },
      {
        paragraph:
          'The following jurisdictions require us to furnish you with the following information.These disclosures are requirements of these jurisdictions and do not contain a complete list of the rights all consumers have under city, state, and federal laws.'
      },
      {
        title: 'California Residents',
        paragraph: `Spring Oaks Capital, LLC's California Department of Financial Protection & Innovation license number: 10603-99.`
      },
      {
        title: 'Colorado Residents',
        paragraph:
          'The address and telephone number of our local office is as follows: 8690 Wolff Court, Suite 110, Westminster, CO 80031; Colorado telephone for Colorado residents only: 303-920-4763. For residents of all other states, please use our toll free number 866-539-7525.'
      },
      {
        title: 'Connecticut Residents',
        paragraph:
          'This collection agency is licensed in Connecticut, license number CCA-1916692. NMLS #1916692.'
      },
      {
        title: 'Massachusetts Residents',
        subtitle: 'NOTICE OF IMPORTANT RIGHTS',
        paragraph:
          'You have the right to make a written or oral request that telephone calls regarding your debt not be made to you at your place of employment. Any such oral request will be valid for only ten days unless you provide written confirmation of the request postmarked or delivered within seven days of such request. You may terminate this request by writing to the debt collector.'
      },
      {
        title: 'North Carolina Residents',
        paragraph: 'Spring Oaks Capital, LLC’s North Carolina company numbers are: 119507661 and 522394787'
      },
      {
        title: 'New York Residents',
        paragraph:
          'If a creditor or debt collector receives a money judgment against you in court, state and federal laws may prevent the following types of income from being taken to pay the debt: 1. Supplemental security income, (SSI); 2. Social security; 3. Public assistance (welfare); 4. Spousal support, maintenance (alimony) or child support; 5. Unemployment benefits; 6. Disability benefits; 7. Workers’ compensation benefits; 8. Public or private pensions; 9. Veterans’ benefits; 10. Federal student loans, federal student grants, and federal work study funds; and 11. Ninety percent of your wages or salary earned in the last sixty days.'
      },
      {
        title: 'New York City Residents',
        paragraph:
          'You may reach us by calling Tim Rees at 866-539-7554. New York City Department of Consumer Affairs License Numbers: 2117984-DCWP and 2122260-DCWP.'
      },
      {
        title: 'Washington, D.C. Residents',
        paragraph:
          'You might have income or resources that are protected from being taken by debt collectors. These might include certain sources of income, funds, or property, including, but not limited to, Social Security, Supplemental Security Income (SSI), disability or unemployment benefits, veterans’ benefits, or child support payments. If you believe your property or income may be protected, you may wish to seek legal advice, including at a legal services provider or legal aid office, before paying this debt.'
      }
    ],

    // SoL disclosure messages
    // backend logic here: https://github.com/Spring-Oaks-Capital-LLC/rootstrap_api/blob/main/so_rootstrap/api/v1/enums/accounts.py

    // Disclosures for the following states:
    // AK, AR, CO, DE, HI, ID, IL, IN, KS, KY, MI, MT, ND,
    // NE, NH, NJ, OH, OK, OR, PA, RI, SC, SD, TN, VT, WY
    solDisclosure1: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it.
      If you do not pay the debt, we may report the debt to any credit reporting agency for as long as the law permits this reporting.
      In many circumstances, you can renew the debt and start the time period for the filing of a lawsuit against you if you take specific actions
      such as making certain payment on the debt or making a written promise to pay. You should determine the effect of any actions you take with respect to this debt.`,
    solDisclosure2: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt, we
    may continue to report the debt to any credit reporting agency for as long as the law permits this reporting.   In many circumstances, you can
      renew the debt and start the time period for the filing of a lawsuit against you if you take specific actions such as making certain payment
      on the debt or making a written promise to pay. You should determine the effect of any actions you take with respect to this debt.`,
    solDisclosure3: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it and this debt cannot be reported
    to any credit reporting agency. In many circumstances, you can renew the debt and start the time period for the filing of a lawsuit against you
      if you take specific actions such as making certain payment on the debt or making a written promise to pay. You should determine the effect
      of any actions you take with respect to this debt.`,

    // Disclosures for the following states:
    // AL, AZ, FL, GA, IA, NV, VA, WA
    solDisclosure4: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
    we may report the debt to any credit reporting agency for as long as the law permits this reporting.   In many circumstances, you can renew
      the debt and start the time period for the filing of a lawsuit against you if you take specific action such as making a written promise to pay.
      You should determine the effect of any actions you take with respect to this debt.`,
    solDisclosure5: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
    we may continue to report the debt to any credit reporting agency for as long as the law permits this reporting. In many circumstances,
      you can renew the debt and start the time period for the filing of a lawsuit against you if you take specific action such as making a written
      promise to pay. You should determine the effect of any actions you take with respect to this debt.`,
    solDisclosure6: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it and this debt cannot be reported
    to any credit reporting agency. In many circumstances, you can renew the debt and start the time period for the filing of a lawsuit against you
      if you take specific action such as making a written promise to pay. You should determine the effect of any actions you take with respect to this debt.`,
    
    
    // Disclosures for the following states:
    // DC, LA, MD, ME, MN, MO, UT
    solDisclosure7: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued  for it. If you do not pay the debt,
    we may report the debt to any credit reporting agency for as long as the law permits this reporting.`,
    solDisclosure8: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued  for it. If you do not pay the debt,
    we may continue to report the debt to any credit reporting agency for as long as the law permits this reporting.`,
    solDisclosure9: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it and this debt cannot be reported
    to any credit reporting agency.`,

    // Disclosures for CA
    solDisclosure10: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
    we may report it to the credit reporting agencies as unpaid for as long as the law permits this reporting.`,
    solDisclosure11: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
    we may continue to report it to the credit reporting agencies as unpaid for as long as the law permits this reporting.`,
    solDisclosure12: `The law limits how long you can be sued on a debt. Because of the age of your debt, you will not be sued for it, and we will not report it
    to any credit reporting agency`,
    
    // Disclosures for CT
    solDisclosure13: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
    we may report or continue to report it to the credit reporting agencies as unpaid.`,
    solDisclosure14: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it and we will not report it to
    any credit reporting agencies.`,
    
    // Disclosures for MA
    solDisclosure15: `WE ARE REQUIRED BY REGULATION OF THE MASSACHUSETTS ATTORNEY GENERAL TO NOTIFY YOU OF THE FOLLOWING INFORMATION. THIS INFORMATION IS NOT LEGAL ADVICE:
    THIS DEBT MAY BE TOO OLD FOR YOU TO BE SUED ON IT IN COURT. IF IT IS TOO OLD, YOU CANNOT BE REQUIRED TO PAY IT THROUGH A LAWSUIT. TAKE NOTE:
      YOU CAN RENEW THE DEBT AND THE STATUTE OF LIMITATIONS FOR THE FILING OF A LAWSUIT AGAINST YOU IF YOU DO ANY OF THE FOLLOWING:
      MAKE ANY PAYMENT ON THE DEBT; SIGN A PAPER IN WHICH YOU ADMIT THAT YOU OWE THE DEBT OR IN WHICH YOU MAKE A NEW PROMISE TO PAY;
        SIGN A PAPER IN WHICH YOU GIVE UP OR WAIVE YOUR RIGHT TO STOP THE CREDITOR FROM SUING YOU IN COURT TO COLLECT THE DEBT.
        WHILE THIS DEBT MAY NOT BE ENFORCEABLE THROUGH A LAWSUIT, IT MAY STILL AFFECT YOUR ABILITY TO OBTAIN CREDIT OR AFFECT YOUR CREDIT SCORE OR RATING.`,
    
    // Disclosures for NM
    solDisclosure16: `We are required by New Mexico Attorney General Rule to notify you of the following information. This information is not legal advice:
    This debt may be too old for you to be sued on it in court. If it is too old, you can’t be required to pay it through a lawsuit.
      You can renew the debt and start the time for the filing of a lawsuit against you to collect the debt if you do any of the following:
      make any payment of the debt; sign a paper in which you admit that you owe the debt or in which you make a new promise to pay;
        sign a paper in which you give up (“waive”) your right to stop the debt collector from suing you in court to collect the debt.`,
    
    // Disclosures for NY
    solDisclosure17: `We are required by regulation of the New York State Department of Financial Services to notify you of the following information.
    This information is NOT legal advice:
    Your creditor or debt collector believes that the legal time limit (statute of limitations) for suing you to collect this debt may have expired.
    It is a violation of the Fair Debt Collection Practices Act, 15 U.S.C. § 1692 et seq., to sue to collect on a debt for which the statute of
      limitations has expired. However, if the creditor sues you to collect on this debt, you may be able to prevent the creditor from obtaining
      a judgment against you. To do so, you must tell the court that the statute of limitations has expired.
    Even if the statute of limitations has expired, you may choose to make payments on the debt.
    If you would like to learn more about your legal rights and options, you can consult an attorney or a legal assistance or legal aid organization.`,
    
    // Disclosures for TX
    solDisclosure18: `THE LAW LIMITS HOW LONG YOU CAN BE SUED ON A DEBT. BECAUSE OF THE AGE OF YOUR DEBT, WE WILL NOT SUE YOU FOR IT.
    IF YOU DO NOT PAY THE DEBT, {currentCreditorName?currentCreditorName:SPRING OAKS CAPITAL, LLC} MAY CONTINUE TO
    REPORT IT TO CREDIT REPORTING AGENCIES AS UNPAID FOR AS LONG AS THE LAW PERMITS THIS REPORTING. THIS NOTICE IS REQUIRED BY LAW.`,
    solDisclosure19: `THE LAW LIMITS HOW LONG YOU CAN BE SUED ON A DEBT. BECAUSE OF THE AGE OF YOUR DEBT, WE  WILL NOT SUE YOU FOR IT, AND WE WILL NOT REPORT IT
    TO ANY CREDIT REPORTING AGENCY.  THIS NOTICE IS REQUIRED BY LAW.`,
    solDisclosure22: `THE LAW LIMITS HOW LONG YOU CAN BE SUED ON A DEBT. BECAUSE OF THE AGE OF YOUR DEBT,
    WE WILL NOT SUE YOU FOR IT. THIS NOTICE IS REQUIRED BY LAW.`,
    
    // Disclosures for WV
    solDisclosure20: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
    we may report or continue to report it to the credit reporting agencies as unpaid.`,
    solDisclosure21: `The law limits how long you can be sued on a debt.  Because of the age of your debt, you cannot be sued for it and we cannot report it to
    any credit reporting agencies.`,
  },
  es: {
    header: 'Divulgaciones',
    continueAria: 'Continuar al portal',

    defaultDisclosures: [
      {
        paragraph:
          'Esta es una comunicación de un cobrador de deudas. Este es un intento de cobrar una deuda y cualquier información obtenida se utilizará para ese propósito.'
      },
      {
        paragraph:
          'Las siguientes jurisdicciones nos exigen proporcionarle la siguiente información. Estas divulgaciones son requisitos de estas jurisdicciones y no contienen una lista completa de los derechos que todos los consumidores tienen en virtud de las leyes municipales, estatales y federales.'
      },
      {
        title: 'Para residentes de California',
        paragraph: `Número de licencia del Departamento de Protección Financiera e Innovación de California de Spring Oaks Capital, LLC: 10603-99.`
      },
      {
        title: 'Para residentes de Colorado',
        paragraph:
          'La dirección y el número de teléfono de nuestra oficina local son los siguientes: 8690 Wolff Court, Suite 110, Westminster, CO 80031; teléfono de Colorado solo para residentes de Colorado: 303-920-4763. Para residentes de todos los demás estados, utilice nuestro número gratuito 866-539-7525.'
      },
      {
        title: 'Para residentes de Connecticut',
        paragraph:
          'Esta agencia de cobros tiene licencia en Connecticut, número de licencia CCA-1916692. NMLS #1916692.'
      },
      {
        title: 'Para residentes de Massachusetts',
        subtitle: 'AVISO DE DERECHOS IMPORTANTES',
        paragraph:
          'Tiene derecho a realizar una solicitud escrita o verbal de que no se le realicen llamadas telefónicas con respecto a su deuda en su lugar de empleo. Dicha solicitud verbal será válida solo durante diez días, a menos que proporcione una confirmación por escrito de la solicitud con franqueo postal o entregada en un plazo de siete días a partir de dicha solicitud. Puede rescindir esta solicitud escribiendo al cobrador de deudas.'
      },
      {
        title: 'Para residentes de Carolina del Norte',
        paragraph:
          'Los números de empresa de Spring Oaks Capital, LLC en Carolina del Norte son: 119507661 y 522394787'
      },
      {
        title: 'Para residentes de Nueva York',
        paragraph:
          'Si un acreedor o cobrador de deudas recibe una sentencia monetaria contra usted en un tribunal, las leyes estatales y federales pueden impedir que se tomen los siguientes tipos de ingresos para pagar la deuda: 1. Ingresos de seguridad suplementaria (Supplemental Security Income, SSI); 2. Seguro social; 3. Asistencia pública (bienestar); 4. pensión conyugal, manutención (pensión alimenticia) o manutención infantil; 5. Beneficios por desempleo; 6. Beneficios por discapacidad; 7. Beneficios de indemnización laboral; 8. Pensiones públicas o privadas; 9. Beneficios para veteranos; 10. Préstamos federales para estudiantes, subvenciones federales para estudiantes y fondos federales para estudio de trabajo; y 11. El noventa por ciento de su salario o sueldo obtenido en los últimos sesenta días.'
      },
      {
        title: 'Para residentes de la ciudad de Nueva York',
        paragraph:
          'También puede comunicarse con nosotros llamando a Tim Rees al 866-539-7554.  Números de licencia del Departamento de Asuntos del Consumidor de la Ciudad de Nueva York: 2117984-DCWP y 2122260-DCWP.'
      },
      {
        title: 'Para residentes de Washington, D.C.',
        paragraph:
          'Es posible que tenga ingresos o recursos que estén protegidos de ser tomados por cobradores de deudas. Estas podrían incluir ciertas fuentes de ingresos, fondos o bienes, incluidos, entre otros, Seguro Social, Ingresos de Seguridad Suplementaria (Supplemental Security Income, SSI), beneficios por discapacidad o desempleo, beneficios para veteranos o pagos de manutención de hijos. Si usted cree que su propiedad o sus ingresos podrían estar protegidos, es posible que desee solicitar asesoramiento legal, incluso de un proveedor de servicios legales u oficina de asistencia legal, antes de pagar esta deuda.'
      }
    ],

    // SoL disclosure messages
    // backend logic here: https://github.com/Spring-Oaks-Capital-LLC/rootstrap_api/blob/main/so_rootstrap/api/v1/enums/accounts.py

    // Disclosures for the following states:
    // AK, AR, CO, DE, HI, ID, IL, IN, KS, KY, MI, MT, ND,
    // NE, NH, NJ, OH, OK, OR, PA, RI, SC, SD, TN, VT, WY
    solDisclosure1: `La ley limita el tiempo durante el cual se le puede demandar por una deuda. Debido a la antigüedad de su deuda, no se le puede demandar por ella.
      Si usted no paga la deuda, podremos reportarla a cualquier agencia de informes de crédito mientras la ley lo permita.   En muchas circunstancias, usted puede
      renovar la deuda e iniciar el período de tiempo para presentar una demanda contra usted si toma medidas específicas, como realizar ciertos pagos sobre la deuda
      o hacer una promesa de pago por escrito. Debe determinar el efecto de cualquier acción que tome con respecto a esta deuda.`,
    solDisclosure2: `La ley limita el tiempo durante el cual se le puede demandar por una deuda. Debido a la antigüedad de su deuda, no se le puede demandar por ella.
      Si usted no paga la deuda, podremos continuar reportando la deuda a cualquier agencia de informes de crédito mientras la ley lo permita.   En muchas
      circunstancias, usted puede renovar la deuda e iniciar el período de tiempo para presentar una demanda contra usted si toma medidas específicas, como realizar
      ciertos pagos sobre la deuda o hacer una promesa de pago por escrito. Debe determinar el efecto de cualquier acción que tome con respecto a esta deuda.`,
    solDisclosure3: `La ley limita el tiempo durante el cual se le puede demandar por una deuda. Debido a la antigüedad de su deuda, no se le puede demandar por ella
      y esta deuda no puede reportarse a ninguna agencia de informes de crédito. En muchas circunstancias, usted puede renovar la deuda e iniciar el período de tiempo
      para presentar una demanda contra usted si toma medidas específicas, como realizar ciertos pagos sobre la deuda o hacer una promesa de pago por escrito. Debe
      determinar el efecto de cualquier acción que tome con respecto a esta deuda.`,

    // Disclosures for the following states:
    // AL, AZ, FL, GA, IA, NV, VA, WA
    solDisclosure4: `La ley limita el tiempo durante el cual se le puede demandar por una deuda. Debido a la antigüedad de su deuda, no se le puede demandar por ella. Si usted no paga la deuda, podremos reportarla a cualquier agencia de informes de crédito mientras la ley lo permita.   En muchas circunstancias, usted puede renovar la deuda e iniciar el período de tiempo para presentar una demanda contra usted si toma una medida específica, como realizar una promesa de pago por escrito. Debe determinar el efecto de cualquier acción que tome con respecto a esta deuda.`,
    solDisclosure5: `La ley limita el tiempo durante el cual se le puede demandar por una deuda. Debido a la antigüedad de su deuda, no se le puede demandar por ella. Si usted no paga la deuda, podremos continuar reportando la deuda a cualquier agencia de informes de crédito mientras la ley lo permita.   En muchas circunstancias, usted puede renovar la deuda e iniciar el período de tiempo para presentar una demanda contra usted si toma una medida específica, como realizar una promesa de pago por escrito. Debe determinar el efecto de cualquier acción que tome con respecto a esta deuda.`,
    solDisclosure6: `La ley limita el tiempo durante el cual se le puede demandar por una deuda. Debido a la antigüedad de su deuda, no se le puede demandar por ella y esta deuda no puede reportarse a ninguna agencia de informes de crédito. En muchas circunstancias, usted puede renovar la deuda e iniciar el período de tiempo para presentar una demanda contra usted si toma una medida específica, como realizar una promesa de pago por escrito. Debe determinar el efecto de cualquier acción que tome con respecto a esta deuda.`,

    // Disclosures for the following states:
    // DC, LA, MD, ME, MN, MO, UT
    solDisclosure7: `La ley limita el tiempo durante el cual se le puede demandar por una deuda. Debido a la antigüedad de su deuda, no se le puede demandar por ella. Si usted no paga la deuda, podremos reportarla a cualquier agencia de informes de crédito mientras la ley lo permita.`,
    solDisclosure8: `La ley limita el tiempo durante el cual se le puede demandar por una deuda. Debido a la antigüedad de su deuda, no se le puede demandar por ella. Si usted no paga la deuda, podremos continuar reportando la deuda a cualquier agencia de informes de crédito mientras la ley lo permita.`,
    solDisclosure9: `La ley limita el tiempo durante el cual se le puede demandar por una deuda. Debido a la antigüedad de su deuda, no se le puede demandar por ella y esta deuda no puede reportarse a ninguna agencia de informes de crédito.`,

    // Disclosures for CA
    solDisclosure10: `La ley limita el tiempo durante el cual se le puede demandar por una deuda. Debido a la antigüedad de su deuda, no se le puede demandar por ella. Si usted no paga la deuda, podremos reportarla como impaga a cualquier agencia de informes de crédito mientras la ley lo permita.`,
    solDisclosure11: `La ley limita el tiempo durante el cual se le puede demandar por una deuda. Debido a la antigüedad de su deuda, no se le puede demandar por ella. Si usted no paga la deuda, podremos continuar reportándola como impaga a cualquier agencia de informes de crédito mientras la ley lo permita.`,
    solDisclosure12: `La ley limita el tiempo durante el cual se le puede demandar por una deuda. Debido a la antigüedad de su deuda, no se lo demandará por ella y no la reportaremos a ninguna agencia de informes de crédito.`,

    // Disclosures for CT
    solDisclosure13: `La ley limita el tiempo durante el cual se le puede demandar por una deuda. Debido a la antigüedad de su deuda, no se le puede demandar por ella. Si usted no paga la deuda, podremos reportarla o continuar reportándola a las agencias de informes de crédito como impaga.`,
    solDisclosure14: `La ley limita el tiempo durante el cual se le puede demandar por una deuda. Debido a la antigüedad de su deuda, no se le puede demandar por ella y no la reportaremos a ninguna agencia de informes de crédito.`,

    // Disclosures for MA
    solDisclosure15: `LA REGULACIÓN DEL FISCAL GENERAL DE MASSACHUSETTS NOS EXIGE QUE LE NOTIFIQUEMOS LA SIGUIENTE INFORMACIÓN. ESTA INFORMACIÓN NO ES ASESORAMIENTO LEGAL: ES POSIBLE QUE ESTA DEUDA SEA DEMASIADO ANTIGUA PARA QUE USTED SEA DEMANDADO POR ELLA EN UN TRIBUNAL. SI ES DEMASIADO ANTIGUA, NO SE LE PUEDE EXIGIR QUE LA PAGUE A TRAVÉS DE UNA DEMANDA. TOME NOTA: PUEDE RENOVAR LA DEUDA Y EL PERIODO DE PRESCRIPCIÓN PARA LA PRESENTACIÓN DE UNA DEMANDA CONTRA USTED SI REALIZA CUALQUIERA DE LAS SIGUIENTES ACCIONES: REALIZA CUALQUIER PAGO DE LA DEUDA; FIRMA UN DOCUMENTO EN EL QUE ADMITE LA DEUDA O EN EL QUE HACE UNA NUEVA PROMESA DE PAGO; FIRMA UN DOCUMENTO EN EL QUE CEDE O RENUNCIA A SU DERECHO DE IMPEDIR QUE EL ACREEDOR LO DEMANDE EN UN TRIBUNAL PARA COBRAR LA DEUDA. SI BIEN ESTA DEUDA PUEDE NO SER EXIGIBLE A TRAVÉS DE UNA DEMANDA, AÚN PUEDE AFECTAR SU CAPACIDAD PARA OBTENER CRÉDITO, O AFECTAR SU CALIFICACIÓN O PUNTAJE DE CRÉDITO.`,

    // Disclosures for NM
    solDisclosure16: `La Norma del Fiscal General de Nuevo México nos exige que le notifiquemos la siguiente información. Esta información no es asesoramiento legal: Es posible que esta deuda sea demasiado antigua para que usted sea demandado por ella en un tribunal. Si es demasiado antigua, no se le puede exigir que la pague a través de una demanda. Puede renovar la deuda e iniciar el plazo para presentar una demanda en su contra para cobrar la deuda si hace cualquiera de las siguientes acciones: realiza cualquier pago de la deuda; firma un documento en el que admite la deuda o en el que hace una nueva promesa de pago; firma un documento en el que cede (“renuncia”) a su derecho de impedir que el cobrador de deudas lo demande en un tribunal para cobrar la deuda.`,

    // Disclosures for NY
    solDisclosure17: `La regulación del Departamento de Servicios Financieros del Estado de Nueva York nos exige que le notifiquemos la siguiente información. Esta información NO es asesoramiento legal:
    Su acreedor o cobrador de deudas cree que el límite de tiempo legal (periodo de prescripción) para demandarlo para cobrar esta deuda puede haber vencido. Iniciar una demanda para cobrar una deuda para la cual ha vencido el periodo de prescripción constituye una violación de la Ley de Prácticas Justas de Cobro de Deudas, Título 15 del Código de los Estados Unidos, párrafo 1692 y siguientes. Si el acreedor igualmente le demanda para cobrar esta deuda, usted puede evitar que el acreedor obtenga una sentencia en su contra. Para hacerlo, debe informar al tribunal que el periodo de prescripción ha vencido.
    Incluso si el periodo de prescripción ha vencido, puede elegir realizar pagos por la deuda.
    Si desea obtener más información sobre sus derechos y opciones legales, puede consultar a un abogado, o una organización de asistencia legal o ayuda legal.`,

    // Disclosures for TX
    solDisclosure18: `LA LEY LIMITA EL TIEMPO DURANTE EL CUAL SE LE PUEDE DEMANDAR POR UNA DEUDA.  DEBIDO A LA ANTIGÜEDAD DE SU DEUDA, NO LO DEMANDAREMOS POR ELLO.  SI NO PAGA LA DEUDA, {currentCreditorName?currentCreditorName:SPRING OAKS CAPITAL, LLC} PUEDE CONTINUAR REPORTÁNDOLA A LAS AGENCIAS DE INFORMES DE CRÉDITO COMO NO PAGADA SIEMPRE Y CUANDO LA LEY LO PERMITA.  ESTA NOTIFICACIÓN ES EXIGIDA POR LEY.`,
    solDisclosure19: `LA LEY LIMITA EL TIEMPO DURANTE EL CUAL SE LE PUEDE DEMANDAR POR UNA DEUDA.  DEBIDO A LA ANTIGÜEDAD DE SU DEUDA, NO LO DEMANDAREMOS POR ELLO, Y NO LO REPORTAREMOS A NINGUNA AGENCIA DE INFORMES DE CRÉDITO.  ESTA NOTIFICACIÓN ES EXIGIDA POR LEY.`,
    solDisclosure22: `LA LEY LIMITA EL TIEMPO DURANTE EL CUAL SE LE PUEDE DEMANDAR POR UNA DEUDA. DEBIDO A LA ANTIGÜEDAD DE SU DEUDA, NO LO DEMANDAREMOS POR ELLO. ESTA NOTIFICACIÓN ES EXIGIDA POR LEY.`,

    // Disclosures for WV
    solDisclosure20: `La ley limita el tiempo durante el cual se le puede demandar por una deuda. Debido a la antigüedad de su deuda, no se le puede demandar por ella. Si usted no paga la deuda, podremos reportarla o continuar reportándola a las agencias de informes de crédito como impagada.`,
    solDisclosure21: `La ley limita el tiempo durante el cual se le puede demandar por una deuda.  Debido a la antigüedad de su deuda, no se le puede demandar por ella y no podemos reportarla a ninguna agencia de informes de crédito.`,
  }
}