import React from 'react';
import { Button, PlanDetailBox } from '../../../components';

import stepFourCustomPlanSvg from '../../../static/images/icons/stepFourCustomPlan.svg';

import allMessages from '../messages';
import {
  stepper,
  hasBackArrow,
  planTag,
  buttonWrapper,
  buttonSmall,
} from '../styles.scss';
import { useLocale } from '../../../hooks';
import { cn } from '../../../utils';
import PaymentFrequencyMessage from '../../../components/PaymentFrequencyMessage';

const AcceptCustomPlanStep = ({ referenceNumber, paymentPlan, currentBalance }) => {
  const { formatDate, formatMessage, messages } = useLocale(allMessages);

  let lastPayment = '';
  let upcomingPayment = '';

  const isCustomPlan = paymentPlan.numPayments > 1;

  if (!isCustomPlan) {
    const lastDatePayment = formatDate(paymentPlan.paymentStartDate, { month: 'long', day: 'numeric' });
    upcomingPayment = `${messages.upcomingPayment} 1 ${messages.of} 1 (${messages.dueOn} ${lastDatePayment})`;
    lastPayment = 'full payment';
  }

  return (
    <>
      <div className={stepper}>
        <img src={stepFourCustomPlanSvg} />
      </div>

      {isCustomPlan ?
        <>
          <div className={planTag}>{messages.customPlan}</div>
          <h1>{messages.steps.acceptCustomPlan.title}</h1>
          <p>{messages.steps.acceptCustomPlan.subtitle}</p>
        </> :
        <>
          <div className={planTag}>{messages.payInFullPlan}</div>
          <h1>{messages.steps.payInFull.title}</h1>
          <p>{messages.steps.payInFull.instructions}</p>
        </>
      }

      <PlanDetailBox
        planType={isCustomPlan ? messages.customPlan : messages.payInFullPlan}
        referenceNumber={referenceNumber}
        paymentPlan={paymentPlan}
        currentBalance={currentBalance}
        fullPayment={!isCustomPlan}
        upcomingPayment={upcomingPayment}
        paymentFrequency={isCustomPlan ? <PaymentFrequencyMessage /> : null}
        lastPayment={lastPayment}
      />

      <div className={cn(buttonWrapper, buttonSmall)}>
        <Button
          data-action="confirm payment"
          variant={[ 'small', 'primary' ]}
          data-goto-step={3}
        >
          {messages.steps.acceptCustomPlan.button}
        </Button>
      </div>
    </>
  )
};

export default AcceptCustomPlanStep;
