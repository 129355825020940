/*
  Accepts a string with the specified content delimiters, and a key: value
  collection of content, and returns an array of React nodes for rendering
  in a component. For example:

  templateString('Hiya {buddy} howru', { buddy: 'pal' });
  > ['Hiya', 'pal', 'howru']

  If a string begins or ends with a template key, there will be an empty
  string at the beginning or end of the array. React ignores this and so
  can you!
*/

export default (str, content, delimiter = /[{}]/) =>
  str.split(delimiter).map((part, i) => i % 2 ? content[part] : part);
