export default {
  en: {
    welcome: 'Welcome {firstName}!',
    journey: 'The journey to being debt-free starts with a single step',
    viewPaymentPlans: 'View Your Payment Plans',
    addPaymentMethodPrompt: 'Add a payment method to automatically pay down your debt.',
    addPaymentMethodCTA: 'Add Payment Method',
    accountDetails: 'Account Details',
    creditNoticeHeading: 'Notice',
    creditNotice: 'If Spring Oaks Capital, LLC is reporting your account to a credit bureau, Spring Oaks Capital, LLC will request deletion of its tradeline from your credit report within 30 days of resolving the balance.',
  },
  es: {
    welcome: '¡Bienvenido {firstName}!',
    journey: 'El camino para estar libre de deudas comienza con un solo paso',
    viewPaymentPlans: 'Ver sus planes de pago',
    addPaymentMethodPrompt: 'Agregue un método de pago para pagar automáticamente su deuda.',
    addPaymentMethodCTA: 'Agregue un método de pago',
    accountDetails: 'Detalles de la cuenta',
    creditNoticeHeading: 'Aviso',
    creditNotice: 'Si Spring Oaks Capital, LLC reporta esta cuenta a una agencia de informes de crédito en el transcurso de 30 días a partir de la recepción del pago final que resuelve esta cuenta, Spring Oaks Capital, LLC, solicitará la eliminación de su cuenta de crédito de su informe de crédito.'
  }
};
