import debugConsole from "./debugConsole";

/*
  Redirect helpers that can filter out external URLs. This is good when the url parameter
  comes from an external source such as an API response or localStorage, as it prevents
  a possible URL injection attack
*/

const isExternalUrl = url => {
  if (/^[a-z]+:/.test(url)) {
    // the URL constructor is pretty particular about formatting, so the above
    // regex is actually enough to reject any url that can't be external
    const destination = new URL(url);

    if (
      destination.protocol !== location.protocol ||
      destination.hostname !== location.hostname ||
      destination.port !== location.port
    ) {
      return true;
    }
  }

  return false;
};

const redirect = (url, allowExternal, target = window) => {
  if (!allowExternal && isExternalUrl(url)) {
    debugConsole.error('Redirect to external URL blocked:', url);
    return;
  }

  debugConsole.log('Redirecting to', url);
  target.location.assign(url);
};

const replaceUrl = (url, allowExternal, target = window) => {
  if (!allowExternal && isExternalUrl(url)) {
    debugConsole.error('Replacement with external URL blocked:', url);
    return;
  }

  debugConsole.log('Replacing current URL with', url);
  target.location.replace(url);
};


// allow us to programmatically follow a link without a click event,
// useful if we're pausing link navigation while some async process completes
const followLink = element => {
  if (element instanceof HTMLElement === false) return;

  const a = element instanceof HTMLAnchorElement
    ? element
    : element.closest('a');

  const { href } = a;
  if (!href) return;

  const targetName = a.target || '_self';

  let target;
  switch (targetName) {
    case '_blank':
      // new window/tab
      window.open(href);
      return; // we're done here
    case '_self':
      target = window;
      break;
    case '_top':
      target = window?.top;
      break;
    case '_parent':
      target = window?.parent;
      break;
    default:
      // named target frame
      target = window?.[targetName];
      break;
  };

  redirect(href, true, target);
}

export { followLink, isExternalUrl, redirect, replaceUrl };
