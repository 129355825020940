import { useMemo } from 'react';
import useStateView from './useStateView'
import { activePaymentPlansView } from '../state/views';

// A hook that returns a small set of useful flags for determining the path a user
// can take through certain portal pages
const useActivePaymentPlanStatus = () => {
  const [ activePaymentPlans ] = useStateView(activePaymentPlansView);
  const plans = activePaymentPlans?.paymentPlans || [];

  // does the account have NO active plans?
  const hasNoActivePlans = useMemo(
    () => !(plans?.length > 0),
    [ plans ]
  );

  // does this account have at least one promise (a non-finalized plan without a payment method)?
  const hasPromise = useMemo(
    () => hasNoActivePlans ? false : !!(plans.find(p => p.paymentSchedule ? p.isPromise : false)),
    [ hasNoActivePlans, plans ]
  );

  // does the account have at least one active payment plan (that isn't a promise)?
  const hasNonPromisePlans = useMemo(
    () => hasNoActivePlans ? false : !!(plans.find(p => !p.isPromise)),
    [ hasNoActivePlans, plans ]
  );

  // is there a promise among the active payment plans?
  const [ promisePlan, isPromise ] = useMemo(
    () => {
      const promisePlan = plans.find(p => p.isPromise);
      return [ promisePlan, !!promisePlan ];
    },
    [ plans ]
  );

  // can the user create a new payment plan?
  const canCreatePlan = hasNoActivePlans || !hasNonPromisePlans;

  return {
    canCreatePlan,
    hasPromise,
    hasNoActivePlans,
    hasNonPromisePlans,
    promisePlan,
    isPromise
  };
};

export default useActivePaymentPlanStatus;
