import React from 'react';

import { useLocale, useOnce, useQuiqChat, useStateView } from '../../hooks';
import { defaultSpecialPlanView, paymentMethodView, paymentPlanView, settlementOffersView, splashPageView } from '../../state/views';

import { MIXPANEL_EVENTS, NEGOTIABLE_PLAN_TYPES, PAYMENT_PLAN_TYPES } from '../../constants';
import allMessages from './messages';

import { Button, GoBack, PaymentInfo } from '../../components';
import { PrintIcon } from '../../components/icons';

import { printButton, buttonsWrapper, successPage, title, oldSuccessPage } from './styles.scss';
import { cn, logout, mixpanel } from '../../utils';
import { paths } from '../../routes';

import { pplp, paymentPlanBox, stepper, legal, centered } from '../PaymentPlanOfferLandingPage/styles.scss';
import Header from '../../components/Header';

import stepFinalSvg from '../../static/images/icons/stepFinal.svg';
import paymentSuccessSvg from '../../static/images/images/payment-success.svg';
import { Link } from 'react-router-dom';
import OldPaymentInfo from '../../components/OldPaymentInfo';

const PaymentSuccessPage = () => {
  const [ { planType, paymentSchedule, referenceNumber } ] = useStateView(paymentPlanView);
  const [ { currentBalance: settlementAmount } ] = useStateView(settlementOffersView);
  const [ { planType: specialPlanType } ] = useStateView(defaultSpecialPlanView);
  const [ { type } ] = useStateView(paymentMethodView);
  const [settlementOffers] = useStateView(settlementOffersView);
  const [ comesFromSplashPage ] = useStateView(splashPageView);

  const { formatMessage, locale, messages } = useLocale(allMessages);

  if (!type) {
    logout();
  }

  useQuiqChat({ debtId: referenceNumber });

  useOnce(() => {
    const isSettlementOffer = !!settlementAmount;
    const isSpecialOffer = !!specialPlanType;
    const isSinglePayment = paymentSchedule?.length === 1;

    let event;

    if (!planType) {
      // add payment method to promise conversion
      event = MIXPANEL_EVENTS.viewAddPaymentMethodSuccessPage;
    } else if (isSettlementOffer) {
      // settlement splash page conversion
      event = isSinglePayment
        ? MIXPANEL_EVENTS.viewSettlementOfferSuccessPagePIF
        : MIXPANEL_EVENTS.viewSettlementOfferSuccessPagePlan;
    } else if (isSpecialOffer) {
      // payment plan splash page conversion
      event = isSinglePayment
        ? MIXPANEL_EVENTS.viewSpecialOfferSuccessPagePIF
        : MIXPANEL_EVENTS.viewSpecialOfferSuccessPagePlan;
    } else {
      // generic main portal payment conversion
      event = MIXPANEL_EVENTS.viewPaymentConfirmationPage;
    }

    mixpanel.track(event, { send_immediately: true });
  });

  const handlePrint = () => {
    // TODO mixpanel event 
    window?.print();
  };

  const isSettlement = [
    PAYMENT_PLAN_TYPES.settleInFull,
    PAYMENT_PLAN_TYPES.settlementPlan
  ].includes(planType);

  return <>
    {comesFromSplashPage && <>
      <Header isSplashScreen />
      <div className={cn(pplp, successPage)}>
        <div className={paymentPlanBox}>
          <div className={stepper}>
            <img src={stepFinalSvg} />
          </div>

          <div className={title}>
            <img src={paymentSuccessSvg} />
            <h1 className={centered}>{messages.heading}</h1>
          </div>

          <PaymentInfo isSuccessPage isSettlement={isSettlement} settlementOffers={settlementOffers} />

          <div className={buttonsWrapper}>
            <Button
              className={printButton}
              onClick={handlePrint}
              variant={['alternate', 'outline']}>
                <PrintIcon/>
                <div>{ messages.printReceipt }</div>
            </Button>
            <Link to={`${process.env.SOC_WEB_URL}?lang=${locale}`}>
              {messages.backToHome}
            </Link>
          </div>

          <div className={legal}>
            <p>{formatMessage(messages.authAgreement[type], {
              poText: <strong>{messages.poText}</strong>,
              businessHours: <strong>{messages.businessHours}</strong>
            })}</p>
            <p>{messages.authAgreement.print}</p>
            <p>{messages.copyrightNotice}</p>
          </div>
        </div>
      </div>
    </>}
    {!comesFromSplashPage && <>
      <div className={oldSuccessPage}>
        <GoBack href={`${process.env.SOC_WEB_URL}?lang=${locale}`}>{ messages.backToHome }</GoBack>
        <h1 className={centered}>{messages.heading}</h1>

        <section>
          <OldPaymentInfo />
        </section>

        <div className={legal}>
          <p>{formatMessage(messages.authAgreement[type], {
            poText: <strong>{messages.poText}</strong>,
            businessHours: <strong>{messages.businessHours}</strong>
          })}</p>
          <p>{messages.authAgreement.print}</p>
          <p>{messages.copyrightNotice}</p>
        </div>

        <p>
          <Button
            className={printButton}
            onClick={handlePrint}
            variant={['alternate', 'outline']}>
              <PrintIcon/>
              <div>{ messages.printReceipt }</div>
          </Button>
        </p>
      </div>
    </>}
  </>;
};

export default PaymentSuccessPage;
