import React, { useMemo, useState } from 'react';
import { number, string } from 'prop-types';

import {
  MIXPANEL_EVENTS,
  NEGOTIABLE_PLAN_TYPES,
  PAYMENT_FREQUENCIES, PAYMENT_FREQUENCY_MAPPINGS,
  PAYMENT_PLAN_TYPES
} from '../../constants';
import { useLocale, useStateView } from '../../hooks';

import { negotiatedPlanView, paymentPlanView } from '../../state/views';
import { cn, mixpanel, paymentPlanFromPlanOption } from '../../utils';
import { Button, Carousel, MobileComponent, ResumedPaymentSummary } from '..';

import { boxesWrapper, frequencyBox, active, selectButton } from './styles.scss';

// this mainly exists so we can manually order the repayment frequencies in the ui
const paymentFrequencies = [
  PAYMENT_FREQUENCIES.monthly,
  PAYMENT_FREQUENCIES.biweekly,
  PAYMENT_FREQUENCIES.weekly,
];

const RepaymentFrequencyBoxes = ({ name = 'repaymentFrequency', initialFrequency = 1 }) => {
  const [ negotiatedPlan ] = useStateView(negotiatedPlanView);
  const [ paymentPlan, setPaymentPlan ] = useStateView(paymentPlanView);
  const [ selectedFrequency, setSelectedFrequency ] = useState(PAYMENT_FREQUENCY_MAPPINGS[initialFrequency]);
  const { messages } = useLocale();

  const changeFrequency = (frequency) => {
    const isSettlement = PAYMENT_PLAN_TYPES.settlementPlan === paymentPlan.planType;
    const selectedPlanType = isSettlement ? PAYMENT_PLAN_TYPES.settlementPlan : NEGOTIABLE_PLAN_TYPES.specialPaymentPlan;
    const fromOption = paymentPlanFromPlanOption(negotiatedPlan, frequency, selectedPlanType);
    setPaymentPlan({ ...paymentPlan, ...fromOption });
    mixpanel.track(MIXPANEL_EVENTS.selectSpecialOfferRepaymentFrequency, { frequency });
  };

  return (
    <MobileComponent
      WebOption={props => <div className={boxesWrapper} {...props}></div>}
      MobileOption={props => <div className={boxesWrapper}><Carousel {...props}></Carousel></div>}
    >
      {paymentFrequencies.map(frequency => {
        if (!frequency in negotiatedPlan.planOptions) {
          return null;
        }

        const id = `${name}-${frequency}`;

        return (
          <Button
            key={id}
            onClick={() => changeFrequency(frequency)}
            className={cn(frequencyBox, selectedFrequency === frequency ? active : null)}
          >
            <ResumedPaymentSummary
              paymentPlan={paymentPlanFromPlanOption(negotiatedPlan, frequency, NEGOTIABLE_PLAN_TYPES.specialPaymentPlan)}
            />
            <Button
              className={selectButton}
              variant={[ 'full', 'large', 'primary' ]}
              data-next-step
            >
              {messages.selectPaymentFrequencies[frequency]}
            </Button>
          </Button>
        );
      })}
    </MobileComponent>
  )
};

RepaymentFrequencyBoxes.propTypes = {
  initialFrequency: number,
  name: string
};

export default RepaymentFrequencyBoxes;