import React from 'react';
import { Link } from 'react-router-dom';

import { useLocale, useStateView } from '../../hooks';
import { sessionView } from '../../state/views';

import { paths } from '../../routes';

import allMessages from './messages';

import { link, footerText, copyRight, wrapper } from './styles.scss';

const LegalFooter = ({ showContactUs = true }) => {
  const [ { authenticated } ] = useStateView(sessionView);
  const { formatMessage, messages } = useLocale(allMessages);

  return <div className={footerText}>
    {showContactUs && <p>
      {formatMessage(messages.accountContact, {
        contactUsLink: <Link className={link} to={messages.contactUsUrl}>{ messages.contactUsLink }</Link>,
        phoneNumber: <strong>866-539-7525</strong>
      })}
    </p>}
    <div className={wrapper}>
      <p>
        {formatMessage(messages.notification, {
          knowYourRightsLink: <Link className={link} to={ authenticated ? paths.homeDisclosures : paths.disclosures } >{ messages.disclosuresLink }</Link>
        })}
      </p>
      <p className={copyRight}>{ messages.copyrightNotice }</p>
    </div>
  </div>;
};

export default LegalFooter;
