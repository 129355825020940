import SvgIcon from './SvgIcon';

import { default as alertIconSrc } from '../../static/images/icons/alertIcon.svg';
import { default as bankIconSrc } from '../../static/images/icons/bankIcon.svg';
import { default as biweeklyIconSrc } from '../../static/images/icons/biweekly.svg';
import { default as calendarIconSrc } from '../../static/images/icons/calendar.svg';
import { default as cardIconSrc } from '../../static/images/icons/cardIcon.svg';
import { default as closeIconSrc } from '../../static/images/icons/closeIcon.svg';
import { default as downloadIconSrc } from '../../static/images/icons/downloadIcon.svg';
import { default as errorIconSrc } from '../../static/images/icons/errorIcon.svg';
import { default as hamburgerIconSrc } from '../../static/images/icons/hamburgerIcon.svg';
import { default as humanSittingIconSrc } from '../../static/images/icons/humanSitting.svg';
import { default as humanSpeakingIconSrc } from '../../static/images/icons/humanSpeaking.svg';
import { default as infoIconSrc } from '../../static/images/icons/infoIcon.svg';
import { default as logoIconSrc } from '../../static/images/icons/logoIcon.svg';
import { default as logoMiniIconSrc } from '../../static/images/icons/logoMinIcon.svg';
import { default as logoutIconSrc } from '../../static/images/icons/logoutIcon.svg';
import { default as monthlyIconSrc } from '../../static/images/icons/monthly.svg';
import { default as partialPaymentIconSrc } from '../../static/images/icons/partialPayment.svg';
import { default as payInFullIconSrc } from '../../static/images/icons/payInFull.svg';
import { default as paymentPlanIconSrc } from '../../static/images/icons/paymentPlan.svg';
import { default as printIconSrc } from '../../static/images/icons/printerIcon.svg';
import { default as questionModalIconSrc } from '../../static/images/icons/questionModalIcon.svg';
import { default as questionTooltipIconSrc } from '../../static/images/icons/questionTooltipIcon.svg';
import { default as successIconSrc } from '../../static/images/icons/successIcon.svg';
import { default as weeklyIconSrc } from '../../static/images/icons/weekly.svg';

/* we probably won't need this
export {
  alertIconSrc,
  bankIconSrc,
  biweeklyIconSrc,
  calendarIconSrc,
  cardIconSrc,
  closeIconSrc,
  downloadIconSrc,
  errorIconSrc,
  hamburgerIconSrc,
  humanSittingIconSrc,
  humanSpeakingIconSrc,
  infoIconSrc,
  logoIconSrc,
  logoMiniIconSrc,
  logoutIconSrc,
  monthlyIconSrc,
  partialPaymentIconSrc,
  payInFullIconSrc,
  paymentPlanIconSrc,
  questionModalIconSrc,
  questionTooltipIconSrc,
  successIconSrc,
  weeklyIconSrc
};
*/

export const AlertIcon = SvgIcon(alertIconSrc);
export const BankIcon = SvgIcon(bankIconSrc);
export const BiweeklyIcon = SvgIcon(biweeklyIconSrc);
export const CalendarIcon = SvgIcon(calendarIconSrc);
export const CardIcon = SvgIcon(cardIconSrc);
export const CloseIcon = SvgIcon(closeIconSrc);
export const DownloadIcon = SvgIcon(downloadIconSrc);
export const ErrorIcon = SvgIcon(errorIconSrc);
export const HamburgerIcon = SvgIcon(hamburgerIconSrc);
export const HumanSittingIcon = SvgIcon(humanSittingIconSrc);
export const HumanSpeakingIcon = SvgIcon(humanSpeakingIconSrc);
export const InfoIcon = SvgIcon(infoIconSrc);
export const LogoIcon = SvgIcon(logoIconSrc);
export const LogoMiniIcon = SvgIcon(logoMiniIconSrc);
export const LogoutIcon = SvgIcon(logoutIconSrc);
export const MonthlyIcon = SvgIcon(monthlyIconSrc);
export const PartialPaymentIcon = SvgIcon(partialPaymentIconSrc);
export const PayInFullIcon = SvgIcon(payInFullIconSrc);
export const PaymentPlanIcon = SvgIcon(paymentPlanIconSrc);
export const PrintIcon = SvgIcon(printIconSrc);
export const QuestionModalIcon = SvgIcon(questionModalIconSrc);
export const QuestionTooltipIcon = SvgIcon(questionTooltipIconSrc);
export const SuccessIcon = SvgIcon(successIconSrc);
export const WeeklyIcon = SvgIcon(weeklyIconSrc);
