import React from 'react';

export default {
  en: {
    title: 'Oh no!',
    subtitle: 'Something went wrong',
    error: <>We are experiencing issues processing payments. Please try again later or call us at <a href="tel:+1866-539-7525">866-539-7525</a>.</>,
    declined: 'The payment you submitted was not authorized by your bank. Please check your information and try again or contact your bank.',
    cta: 'Try again'
  },
  es: {
    title: '¡Oh no!',
    subtitle: 'Algo salió mal',
    error: <>Estamos teniendo problemas para procesar los pagos. Intente de nuevo más tarde o llámenos al <a href="tel:+1866-539-7525">866-539-7525</a>.</>,
    declined: 'El pago que envió no fue autorizado por su banco. Compruebe su información e intente de nuevo o comuníquese con su banco.',
    cta: 'Intente de nuevo'
  }
};
