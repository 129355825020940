export const MIXPANEL_AB_TESTING = {
  startExperiment: '$experiment_started',
  emitWin: 'Experiments Win'
};

export const MIXPANEL_SPLASH_PAGE_NAMES = {
  PPLP: 'Payment Plan Landing Page',
  SOLP: 'Settlement Plan Landing Page',
  FOLP: 'Frictionless Landing Page'
};

export const MIXPANEL_EVENTS = {
  acceptDisclosures: 'Acknowledge Disclosures',
  clickLink: 'Click Link',
  clickBackToWebsite: 'Click Back to website',
  clickHeaderLogo: 'Click Header Logo',
  clickKnowYourRights: 'Click Know Your Rights',
  clickMorePaymentOptions: 'Click More Payment Options',
  clickAddPaymentMethodToPromise: 'Click Add Payment Method To Promise',
  clickPay: 'Click Pay',
  clickPayInFull: 'Click Pay in Full',
  clickPaymentPlan: 'Click Create Plan',
  clickPrintReceipt: 'Click Print Receipt',
  closeSideMenu: 'Close Menu',
  collapseAccountDetails: 'Collapse Account Details Page',
  downloadDocument: 'Download Document',
  expandAccountDetails: 'Expand Account Details',
  frequencySelected: 'Select Schedule Frequency',
  loginAttempt: 'Login Attempt',
  loginFail: 'Login Failure',
  loginSuccess: 'Login Success',
  logOut: 'Log Out',
  openSideMenu: 'Open Menu',
  selectPaymentFrequency: 'Select Payment Frequency',
  selectPaymentMethod: 'Select Payment Method',
  selectPayInFullOption: 'Select Pay in Full Option',
  selectCreatePaymentPlanOption: 'Select Create Payment Plan Option',
  selectPartialPaymentOption: 'Select Partial Payment Option',
  addPaymentMethodFailure: 'Add Payment Method Failure',
  selectPartialPaymentPercentage: 'Select Partial Payment Percentage',
  startDateSelected: 'Select Start Date',
  termsSelected: 'Select Schedule Terms',
  viewLoginPage: 'View Login Page',
  viewAccountDetailsPage: 'View Account Details Page',
  viewAccountSuppressedPage: 'View Account Suppressed Page',
  viewAuthorizationPaymentPage: 'View Payment Authorization Page',
  viewCreatePlanPage: 'View Choose your Payment Frequency Page',
  viewDisclosuresPage: 'View Disclosures Page',
  viewDocumentsPage: 'View Documents Page',
  viewPaymentConfirmationPage: 'View Payment Confirmation Page',
  viewPaymentErrorPage: 'View Payment Error Page',
  viewPaymentHistoryPage: 'View Payment History Page',
  viewPaymentPlansPage: 'View Payment Plans Page',
  viewRepaymentOptions: 'View Choose your Repayment Option Page',
  viewSetupPaymentPage: 'View Set up your Payment Page',
  clickedShortLink: 'Clicked Short Link',
  viewSettlementOfferLandingPage: 'View Settlement Offer Landing Page',
  viewSettlementOfferSuccessPagePIF: 'Settle In Full Conversion',
  viewSettlementOfferSuccessPagePlan: 'Settlement Plan Conversion',
  viewSpecialOfferSuccessPagePIF: 'Special Payment In Full Conversion',
  viewSpecialOfferSuccessPagePlan: 'Special Payment Plan Conversion',
  viewAddPaymentMethodPage: 'View Add Payment Method Page',
  viewPaymentPlanOfferLandingPage: 'View Payment Plan Landing Page',
  initiateAddDetailsToPromise: 'Initiate Add Details To Promise',
  authorizeAddDetailsToPromise: 'Authorize Add Details To Promise',
  viewAddPaymentMethodSuccessPage: 'Conversion Add Details To Promise',
  viewConfirmAddPaymentMethodPage: 'View Add Payment Method Confirmation Page',
  togglePaymentPlanLandingPage: 'Toggle payment option',
  acceptDefaultSpecialOffer: 'Accept Default Special Offer',
  createCustomSpecialOffer: 'Create Custom Special Offer',
  payFullSpecialOfferBalance: 'Pay Full Special Offer Balance',
  proposeSpecialPaymentPlan: 'Propose special payment plan',
  proposedSpecialPaymentPlanApproved: 'Proposed special payment plan approved',
  proposedSpecialPaymentPlanRejected: 'Proposed special payment plan rejected',
  openChatWidget: 'User opened chat widget',
  selectAccount: 'Select Reference Number',
  inactivityTimeout: 'Inactivity Timeout',
  deviceVerificationAttempt: 'Device Verification Attempt',
  deviceVerificationSuccess: 'Device Verification Success',
  deviceVerificationFailure: 'Device Verification Failure',
  selectSettlementPlanTerms: 'Select Settlement Plan Terms',
  selectSettleInFull: 'Select Settle In Full',
  selectCreateSettlementPlan: 'Select Create Settlement Plan',
  selectSpecialOfferRepaymentFrequency: 'Select Special Payment Plan Frequency'
};
