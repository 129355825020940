import React, { useMemo } from 'react';
import { bool, object, string } from 'prop-types';
import { useLocale, useStateView } from '../../hooks';
import { formatCurrency } from '../../utils';
import { PAYMENT_FREQUENCIES, PAYMENT_PLAN_TYPES } from '../../constants';
import { paymentPlanView, localeView } from '../../state/views';

const PaymentSummary = ({ paymentPlan = null, finalAmountClass = '', extraPaymentClass = '' }) => {
  const { locale, formatMessage, messages } = useLocale();
  
  const [ savedLocale ] = useStateView(localeView);
  const [ storedPaymentPlan ] = useStateView(paymentPlanView);

  if (!paymentPlan) {
    paymentPlan = storedPaymentPlan;
  }
  
  const {
    planType,
    numPayments,
    paymentFrequency,
    standardPaymentAmount,
    lastPaymentAmount,
    paymentSchedule
  } = paymentPlan;

  // TODO can simplify the "classic" display logic to use the paymentGroups mapping

  return useMemo(
    () => {
      if (paymentSchedule?.length > 0) {
        const paymentGroups = [];
    
        let i = 0;
        let numPayments = 0;
        let currentAmount = paymentSchedule[0].amount;
    
        while (i < paymentSchedule.length) {
          const { amount } = paymentSchedule[i];
          
          if (amount !== currentAmount) {
            paymentGroups.push({
              numPayments,
              amount: currentAmount
            });
            
            numPayments = 0;
            currentAmount = amount;
          }
          
          ++numPayments;
          ++i;
        }
    
        paymentGroups.push({
          numPayments,
          amount: currentAmount
        });
    
        return <>
            { paymentGroups
              .map(({ numPayments, amount }, i) => {
                const paymentString = formatMessage(
                  messages.paymentsOf,
                  {
                    finalPayment: i !== 0 && i === paymentGroups.length - 1,
                    multiplePayments: numPayments !== 1,
                    numPayments
                  }
                );
    
                const amountString = formatCurrency(amount);
                const divider = paymentGroups.length > 1 && i < paymentGroups.length - 2
                  ? ', '
                  : ' ';
    
                return `${paymentString} ${amountString}${divider}`;
              }) }
          </>;
      }
      
      if (!(numPayments && standardPaymentAmount)) {
        return null;
      }
      
      const isFinalAmountDifferent = lastPaymentAmount && standardPaymentAmount !== lastPaymentAmount;
      const numStandardPayments = isFinalAmountDifferent ? numPayments - 1 : numPayments;
      const isPlan = planType === PAYMENT_PLAN_TYPES.paymentPlan;

      const showPaymentFrequency = () => isPlan && messages.paymentFrequencies[paymentFrequency === PAYMENT_FREQUENCIES.biweekly ? 'biweekly' : paymentFrequency].toLowerCase();
      const pluralizedPayments = () => formatMessage(messages.payments, { multiplePayments: numStandardPayments !== 1 });
      const EnglishPayments = () => <>{showPaymentFrequency()} {pluralizedPayments()}</>
      const SpanishPayments = () => <>{pluralizedPayments()} {showPaymentFrequency()}</>

      return (
        <>
          <div className={finalAmountClass}>
            {numStandardPayments} {savedLocale === 'en' ? <EnglishPayments /> : <SpanishPayments />} {messages.of}
            <br />
            <div>
              $ <span>{formatCurrency(standardPaymentAmount, '$', true)}</span>
            </div>
          </div>
          <div className={extraPaymentClass}>
            {isFinalAmountDifferent && (
              <>
                {'*' + formatMessage(messages.paymentsOf, { finalPayment: true })} <strong>{formatCurrency(lastPaymentAmount)}</strong>
              </>
            )}
          </div>
        </>
      )
    },
    [
      locale,
      paymentSchedule,
      numPayments,
      planType,
      standardPaymentAmount,
      lastPaymentAmount
    ]
  );
};

PaymentSummary.propTypes = {
  paymentPlan: object,
  finalAmountClass: string,
  extraPaymentClass: string
};

export default PaymentSummary;
