import { NEGOTIABLE_PLAN_TYPES, PAYMENT_FREQUENCIES, PAYMENT_FREQUENCY_MAPPINGS, PAYMENT_PLAN_TYPES } from "../constants";

// transform a negotiate-plan response into a paymentPlan.
// If no planType is given, it will be omitted from the result.
export const paymentPlanFromPlanOption = (
  response,
  frequency,
  planType
) => {
  const {
    numPayments,
    planScheduleFrequency,
    perPaymentAmount: {
      standard: standardPaymentAmount,
      last: lastPaymentAmount
    }
  } = response?.planOptions?.[frequency] || {};

  if (planType === NEGOTIABLE_PLAN_TYPES.paymentPlan || planType === NEGOTIABLE_PLAN_TYPES.specialPaymentPlan) {
    planType = numPayments === 1
      ? PAYMENT_PLAN_TYPES.payInFull
      : PAYMENT_PLAN_TYPES.paymentPlan;
  } else if (planType === NEGOTIABLE_PLAN_TYPES.settlementPlan) {
    planType === numPayments === 1
      ? PAYMENT_PLAN_TYPES.settleInFull
      : PAYMENT_PLAN_TYPES.settlementPlan;
  }

  return {
    planType,
    numPayments,
    paymentFrequency: PAYMENT_FREQUENCY_MAPPINGS[planScheduleFrequency],
    standardPaymentAmount,
    lastPaymentAmount,
    totalPaymentAmount: response?.currentBalance
  };
};

export const paymentFrequencyFromSchedule = (paymentFrequency) => {
  const dateObjects = paymentFrequency.map(frequency => new Date(frequency.paymentDate));
  let differences = [];

  for (let i = 1; i < dateObjects.length; i++) {
    const diffInTime = dateObjects[i] - dateObjects[i - 1];
    const diffInDays = diffInTime / (1000 * 60 * 60 * 24);
    differences.push(diffInDays);
  }
  
  const isWeekly = differences.every(diff => diff === 7);
  const isBiWeekly = differences.every(diff => diff === 14);
  const isMonthly = differences.every(diff => diff >= 28 && diff <= 31);

  return isMonthly ? PAYMENT_FREQUENCIES.monthly : (isBiWeekly ? PAYMENT_FREQUENCIES.biweekly : PAYMENT_FREQUENCIES.weekly);
}
