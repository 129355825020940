import React from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';

const rollbarConfig = {
  accessToken: process.env.ROLLBAR_TOKEN,
  environment: process.env.ROLLBAR_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true
};

const RollbarProvider = ({ children, ...props }) => 
  <Provider config={rollbarConfig} {...props}>
    <ErrorBoundary>{ children }</ErrorBoundary>
  </Provider>;

export default RollbarProvider;
