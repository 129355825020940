import React, { useState } from 'react';
import { bool, node, object, oneOfType, string } from 'prop-types';
import { cn } from '../../utils';
import { expander } from './styles.scss';
import DropdownArrow from '../../static/images/icons/DropdownArrow';

const Expander = ({ label, expanded = false, className, children, ...props }) => {
  const [ isExpanded, setIsExpanded ] = useState(expanded);

  return <div className={cn(expander, className)} {...props}>
    <label onClick={() => setIsExpanded(!isExpanded)} data-expanded={isExpanded}>
      {label}
      <DropdownArrow variant />
    </label>
    { isExpanded && children }
  </div>;
};

Expander.propTypes = {
  className: oneOfType([ string, object ]),
  expanded: bool,
  label: node.isRequired
};

export default Expander;
