import { useEffect } from 'react';

// multi-variable destructuring breaks dotenv-webpack
const QUIQ_CHAT_URL = process.env.QUIQ_CHAT_URL;
const QUIQ_CHAT_HOST = process.env.QUIQ_CHAT_HOST;
const QUIQ_CHAT_CONTACT_POINT = process.env.QUIQ_CHAT_CONTACT_POINT;
const QUIQ_CHAT_SECONDS_BEFORE_RENDER = process.env.QUIQ_CHAT_SECONDS_BEFORE_RENDER;

const config = {
  pageConfigurationId: QUIQ_CHAT_CONTACT_POINT,
  host: QUIQ_CHAT_HOST,
  autoPopTime: (QUIQ_CHAT_SECONDS_BEFORE_RENDER || 0) * 1000
};

const scriptId = 'quiq-chat-load';
const contentScriptId = 'quiq-chat-run';

export default function useQuiqChat(context = {}, verified = true) {
  useEffect(() => {
    // abort if unverified or if not in a browser
    if (!verified || typeof window === 'undefined') return;

    // create Quiq chat script if it doesn't exist
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');

      // when Quiq JS loads, append another script instantiating the chat widget
      script.onload = () => {
        if (typeof Quiq !== 'undefined') {
          const quiqChat = new Quiq(config);
          quiqChat.setContext(context, true);
        }
      };

      script.setAttribute('id', scriptId);
      script.setAttribute('async', true);
      script.setAttribute('charset', 'UTF-8');
      script.setAttribute('type', 'application/javascript');
      script.setAttribute('src', QUIQ_CHAT_URL);

      document.body.appendChild(script);
    }

    // return cleanup callback
    return () => {
      window.quiqChat?.clearUserSession();

      if (window.quiqChat?.unload instanceof Function) window.quiqChat.unload();

      document.getElementById(scriptId)?.remove();
      document.getElementById(contentScriptId)?.remove();
    };
  }, [verified]);
}
