import React from 'react';

import { PAYMENT_FREQUENCIES } from "../../constants";
import OrdinalNumber from '../OrdinalNumber';
import { weekdayString } from '../../utils';
import { useLocale, useStateView } from '../../hooks';
import { paymentPlanView } from '../../state/views';

const PaymentFrequencyMessage = () => {
  const [ paymentPlan ] = useStateView(paymentPlanView);
  const { messages, locale } = useLocale();

  const {
    paymentFrequency,
    paymentDayOfWeek,
    paymentStartDate,
  } = paymentPlan;

  const isEveryTwoWeeks = [PAYMENT_FREQUENCIES.everyTwoWeeks, PAYMENT_FREQUENCIES.biweekly].includes(paymentFrequency);
  const isMonthly = paymentFrequency === PAYMENT_FREQUENCIES.monthly;

  if (isMonthly) {
    return (
      <>
        <span>{messages.every} <OrdinalNumber num={new Date(paymentStartDate).getUTCDate()} /> {messages.ofTheMonth}</span>
      </>
    )
  } else {
    return (
      <>
        {isEveryTwoWeeks ? messages.everyOther : messages.every} {weekdayString(paymentDayOfWeek, locale)}
      </>
    )
  }
};

export default PaymentFrequencyMessage;
