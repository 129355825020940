// extracted by mini-css-extract-plugin
var _1 = "df34u8Z1yqIByXqA9ZXq";
var _2 = "Nnc7vZHVdsM7oxwDZBhm";
var _3 = "n4q7dO8W6ZAgNcN3Ahqo";
var _4 = "bTjnYvDH1AeTL8YSdAcI";
var _5 = "Q1TUyKXPipEMzaV6jEdW";
var _6 = "lsGAsGgulZwL0m1DOeM3";
var _7 = "unXkNu5bbsje_nnL7K2U";
var _8 = "E2aYZGW6j2cigr_3KT9R";
var _9 = "ec0bIYtH_8gTiEnbVpeu";
export { _1 as "alignRight", _2 as "balance", _3 as "detailBox", _4 as "infoRow", _5 as "mobileDisplay", _6 as "planTypeTag", _7 as "separator", _8 as "webDisplay", _9 as "wrapper" }
