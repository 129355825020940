import React, { useMemo, useState } from "react";
import Modal from "../Modal";
import { useLocale, useStateView } from "../../hooks";
import { shortLinkRedirectView } from "../../state/views";
import MaskedInput from "../MaskedInput";
import { dateOfBirth, formEventValues, inputValues, mixpanel, transformValues } from "../../utils";
import Button from "../Button";
import allMessages from "../../pages/LoginPage/messages";
import { loginForm, userMessages } from './styles.scss';
import { verifyIdentity } from "../../service/account";
import { MIXPANEL_EVENTS } from "../../constants";

const UnverifiedPhoneLogin = () => {
  const [
    {
      referenceNumber,
      fullUrlPath,
      shortUrlPath,
      ref,
      verified
    },
    setShortLink
  ] = useStateView(shortLinkRedirectView);

  const [ isDisabled, setIsDisabled ] = useState(true);
  const [ formError, setFormError ] = useState();
  const [ isLoading, setIsLoading ] = useState(false);

  const { locale, messages } = useLocale(allMessages);

  const transform = useMemo(
    () => ({ dateOfBirth: dateOfBirth[locale] }),
    [ locale ]
  );

  const handleChange = e => {
    setIsDisabled(Object.keys(inputValues(e.target.form.elements, true)).length < 1);
  };

  const handleSubmit = async e => {
    setIsLoading(!isLoading);
    setIsDisabled(true);
    e.preventDefault();

    // clear form error message
    setFormError(undefined);

    // Fill the credentials object with form data

    const credentials = {
      // default values for form fields
      dateOfBirth: '',
      ssnLastFour: '',
      // user-provided form data
      ...transformValues(formEventValues(e), transform),
      // other required fields
      fullUrl: fullUrlPath,
      shortUrl: shortUrlPath,
      ref,
      debtId: referenceNumber
    };

    // make request

    mixpanel.track(MIXPANEL_EVENTS.deviceVerificationAttempt);

    const response = (await verifyIdentity(credentials).catch(() => false));

    if (!response) {
      // there was either a validation or request error
      mixpanel.track(MIXPANEL_EVENTS.deviceVerificationFailure);
      setFormError(true);
      setIsLoading(false);
      return;
    }

    setFormError(false);

    mixpanel.track(MIXPANEL_EVENTS.deviceVerificationSuccess);
    setShortLink.update({ verified: response.verified });
  };

  return verified
    ? null // show nothing if user is verified
    : <Modal visible closable={false}>
        <div className={loginForm}>
          <form onSubmit={handleSubmit} onChange={handleChange}>
            <h1>{messages.header}</h1>
            <p>{messages.pleaseVerify}</p>
            <MaskedInput
              name='dateOfBirth'
              maxLength='10'
              autoComplete='off'
              mask='00/00/0000'
              {...messages.formFields.dateOfBirth} />
            <MaskedInput
              name='ssnLastFour'
              maxLength='4'
              autoComplete='off'
              mask='0000'
              {...messages.formFields.ssnLastFour} />
            <div className={userMessages}>
              <p>{messages.minimumFields}</p>
              {formError && <span>{messages.formError}</span>}
            </div>
            <Button
              type='submit'
              variant={['large', 'full']}
              disabled={isDisabled}
              loading={isLoading} >
              {messages.verifyButton}
            </Button>
          </form>
        </div>
      </Modal>;
}

export default UnverifiedPhoneLogin;
