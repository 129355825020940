export default {
  en: {
    originalCreditor: 'Original Creditor',
    referenceNumber: 'Reference Number',

    payInFull: 'Pay in Full',
    settleInFull: 'Settle in Full',
    paymentPlan: 'Payment Plan',
    settlementPlan: 'Settlement Plan',
    paymentPartial: 'Partial Payment',
    
    paymentDate: 'Payment Date{singlePayment?:s}',
    paymentFrequency: 'Frequency',
    everyOther: 'Every other',
    ofTheMonth: 'of the month',
    
    paymentMethod: 'Payment Method',
    accountType: 'Account Type',
    type: {
      ach: 'Bank Account (ACH)',
      card: 'Credit/Debit Card'
    },
    source: {
      CHECKING: 'CHECKING',
      SAVINGS: 'SAVINGS',
      DEBIT: 'DEBIT',
      CREDIT: 'CREDIT',
      VISA: 'VISA',
      MASTERCARD: 'MASTERCARD'
    },
    bankAccount: 'Bank Account',
    endingIn: 'ending in',
    totalBalance: 'Total Balance',
    
    routingNumber: 'Routing Number',
    consumerName: 'Consumer Name',
    transactionId: 'Transaction ID',

    summary: 'Summary',

    saveAsPdf: 'Save as PDF',
    printReceipt: 'Print Receipt',
    emailReceipt: 'Email Receipt'
  },
  es: {
    originalCreditor: 'Acreedor original',
    referenceNumber: 'Número de referencia',
    
    payInFull: 'Pague en su totalidad',
    settleInFull: 'Pague en su totalidad',
    paymentPlan: 'Plan de pago',
    settlementPlan: 'Plan de pago',
    paymentPartial: 'Pago parcial',
    
    paymentDate: 'Fecha{singlePayment?:s} de pago',
    paymentFrequency: 'Frecuencia',
    everyOther: 'Cada dos',
    ofTheMonth: 'del mes',
    
    paymentMethod: 'Método de pago',
    accountType: 'Tipo de cuenta',
    type: {
      ach: 'Cuenta bancaria (ACH)',
      card: 'Tarjeta de crédito/débito'
    },
    source: {
      CHECKING: 'CUENTA CORRIENTE',
      SAVINGS: 'CUENTA DE AHORROS',
      DEBIT: 'TARJETA DE DÉBITO',
      CREDIT: 'TARJETA DE CRÉDITO',
      VISA: 'VISA',
      MASTERCARD: 'MASTERCARD'
    },
    bankAccount: 'Cuenta bancaria',
    endingIn: 'que termina en',
    totalBalance: 'Saldo total',

    routingNumber: 'Número de ruta',
    consumerName: 'Nombre del consumidor',
    transactionId: 'ID de transacción',

    summary: 'Resumen',

    saveAsPdf: 'Guardar como PDF',
    printReceipt: 'Imprimir recibo',
    emailReceipt: 'Recibo de correo electrónico',
  }
};