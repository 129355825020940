import React from 'react'
import { useLocale } from '../../hooks';
import { creditDisclosure } from './styles.scss';

import creditDisclosureSvg from '../../static/images/images/credit-disclosure.svg';

const CreditDisclosure = ({ allMessages }) => {
  const { formatMessage, locale, messages } = useLocale(allMessages);

  return (
    <div className={creditDisclosure}>
      <img src={creditDisclosureSvg} />
      <p>{formatMessage(messages.creditDisclosure, { receiptText: <b>{messages.receiptText}</b> })}</p>
    </div>
  )
};

export default CreditDisclosure;
