/*
  Takes as input a shallow data object, and applies transformations
  to its fields based on the rules specified in the transforms object.

  For example:

  input = {
    hi: 'buddy',
    zip: '12345-6789'
  }

  transform = {
    zip: s => s.substring(0, 5),
    asdf: s => `${s}jkl` <-- only applied if property 'asdf' exists in input
  }

  output = {
    hi: 'buddy', <-- untouched because no transform was defined for it
    zip: '12345' <-- transformation applied
  }

  Useful for sanitizing form inputs and stuff
*/

export default (input, transforms) => {
  const result = { ...input };

  for (let name in transforms) {
    if (name in input) {
      result[name] = transforms[name](input[name], input, name);
    }
  }

  return result;
};
