import debugConsole from './debugConsole';
import { leftPad } from '../utils';
import { PAYLOAD_DATE_FORMAT, PAYMENT_FREQUENCIES, PAYMENT_PLAN_TYPES } from '../constants';

export const formatDateWithString = (date, format = PAYLOAD_DATE_FORMAT) => {
  date = new Date(date);
  
  const y = date.getFullYear().toString();
  const m = (date.getMonth() + 1).toString();
  const d = date.getDate().toString();

  const yy = leftPad(y, 2);
  const yyyy = leftPad(yy, 2);
  const mm = leftPad(m, 2);
  const dd = leftPad(d, 2);

  return format
    .replace(/YYYY/g, yyyy)
    .replace(/YY/g, yy)
    .replace(/Y/g, y)
    .replace(/MM/g, mm)
    .replace(/M/g, m)
    .replace(/DD/g, dd)
    .replace(/D/g, d);
};

export const MS_PER_DAY = 86400000;

export const daysUntil = date => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  date = new Date(date);
  date.setHours(0, 0, 0, 0);

  return Math.max(Math.floor((date - today) / MS_PER_DAY), 0);
};

export const getMonthAbbreviation = (m = new Date(), locale = undefined) => {
  const date = m instanceof Date
    ? m
    : new Date(0, m - 1, 0, 0, 0, 0, 0);
  
  return date.toLocaleDateString(locale, { month: 'short' });
};

export const numDaysInMonth = (month = new Date(), year) => {
  const date = month instanceof Date
    ? new Date(month)
    : new Date(year || new Date().getFullYear(), month + 1, 1);
  
  date.setDate(0);

  return date.getDate();
};

export const weekdayString = (dayNumber, locale = undefined) => {
  // first of the month but also Monday; date and day are both 1
  const date = new Date('5/1/2023');
  // which lets us do this
  date.setDate(dayNumber);

  // take just the weekday as a string
  return date.toLocaleDateString(locale, { weekday: 'long' });
};

export const generatePaymentScheduleFromPaymentPlan = ({
  numPayments,
  paymentFrequency,
  paymentStartDate,
  paymentDayOfWeek,
  paymentSchedule,
  planType,
  standardPaymentAmount,
  lastPaymentAmount
}) => {
  let paymentDate = new Date(paymentStartDate);
  paymentDate.setMinutes(paymentDate.getMinutes() + paymentDate.getTimezoneOffset());

  const finalPaymentSchedule = [];

  if (paymentSchedule?.length > 0) {
    return paymentSchedule.map(
      ({ date, amount }) => {
        return { amount: Math.round(amount * 100) / 100, date: typeof date === 'string' ? date : formatDateWithString(date) };
      }
    );
  } else if (planType === PAYMENT_PLAN_TYPES.paymentPlan || planType === PAYMENT_PLAN_TYPES.settlementPlan) {
    switch (paymentFrequency) {
      case PAYMENT_FREQUENCIES.monthly:
        const initialDay = paymentDate.getDate();
        const initialMonth = paymentDate.getMonth();
        const initialYear = paymentDate.getFullYear();

        for (let i = 0; i < numPayments; ++i) {
          const monthOffset = initialMonth + i;
          const month = monthOffset % 12;
          const year = initialYear + Math.floor(monthOffset / 12);
          const day = Math.min(initialDay, numDaysInMonth(month, year));

          finalPaymentSchedule.push({
            amount: parseFloat(standardPaymentAmount),
            date: formatDateWithString(new Date(year, month, day))
          });
        }

        break;
      
      case PAYMENT_FREQUENCIES.weekly:
      case PAYMENT_FREQUENCIES.everyTwoWeeks:
      case PAYMENT_FREQUENCIES.biweekly:
        if (paymentDate.getDay() !== paymentDayOfWeek) {
          paymentDate = new Date();
          paymentDate.setDate(paymentDate.getDate() + (7 + paymentDayOfWeek - paymentDate.getDay()) % 7);
        }
        
        const intervalDays = paymentFrequency === PAYMENT_FREQUENCIES.weekly
          ? 7
          : 14;

        for (let i = 0; i < numPayments; ++i) {
          finalPaymentSchedule.push({
            amount: parseFloat(standardPaymentAmount),
            date: formatDateWithString(paymentDate)
          });

          paymentDate.setDate(paymentDate.getDate() + intervalDays);
        }
        
        break;
      
      default:
        debugConsole.error(`Failed to generate payment schedule: unknown paymentFrequency ${paymentFrequency}`);
        return finalPaymentSchedule;
    }

    if (lastPaymentAmount && finalPaymentSchedule.length > 0) {
      finalPaymentSchedule[finalPaymentSchedule.length - 1].amount = parseFloat(lastPaymentAmount);
    }

    return finalPaymentSchedule;
  } else {
    // single payment
    return [{ amount: parseFloat(standardPaymentAmount), date: formatDateWithString(paymentDate) }];
  }
};
