export default {
  en: {
    journey: 'The journey to being debt-free\nstarts with a single step',
    welcome: 'Welcome!',
    formError: 'Please check your data and try again.',
    inputErrors: {
      ssnLastFour: 'You must enter a 4 digit SSN number'
    },
    body: 'Please validate your identity to enter the portal.',
    formFields: {
      lastName: {
        label: 'Last Name',
        placeholder: 'Enter your last name'
      },
      dateOfBirth: {
        label: 'Date of Birth',
        placeholder: 'MM/DD/YYYY'
      },
      ssnLastFour: {
        label: 'Last Four SSN',
        placeholder: 'Enter the last four numbers of SSN'
      }
    },
    submitButton: 'Log In',
    pleaseVerify: 'Please verify your identity to see your offer by providing one of the following fields.',
    verifyButton: 'Verify',
    minimumFields: 'You must submit at least 1 field to access.'
  },
  es: {
    journey: 'El camino para estar libre de deudas comienza con un solo paso',
    welcome: '¡Bienvenido!',
    formError: 'Compruebe sus datos e intente de nuevo.',
    inputErrors: {
      ssnLastFour: 'Ingrese los últimos cuatro dígitos del número de Seguro Social'
    },
    body: 'Valide su identidad para acceder al portal.',
    formFields: {
      lastName: {
        label: 'Apellido',
        placeholder: 'Ingrese su apellido'
      },
      dateOfBirth: {
        label: 'Fecha de nacimiento',
        placeholder: 'DD/MM/AAAA'
      },
      ssnLastFour: {
        label: 'Últimos cuatro dígitos del número de Seguro Social',
        placeholder: 'Ingrese los últimos cuatro dígitos del número de Seguro Social'
      }
    },
    submitButton: 'Iniciar sesión',
    pleaseVerify: 'Verifique su identidad para ver su oferta proporcionando uno de los siguientes campos.',
    verifyButton: 'Verificar',
    minimumFields: 'Debe enviar al menos 1 campo para acceder.'
  }
};