import React from 'react';
import { node, string, bool } from 'prop-types';
import { Modal } from '..';
import {
  inputElement,
  inputError,
  inputLabel,
  inputTooltip,
  inputRequired,
  errorBorder
} from './styles.scss';

let nextId = 1;

const TextInput = ({
  type = 'text',
  id = `textinput-${nextId++}`,
  label,
  placeholder = '',
  className = undefined,
  error,
  required = false,
  hasError = false,
  ...props
}) => {
  const handleClickShowTooltip = e => {
    e.preventDefault();
    setShowTooltip(true);
  };

if (error && typeof error === 'object' && props.name) {
    // if error is an object with a property matching the name of the input,
    // use that value as the error message
    error = error[props.name];
  }

  return (
    <div className={className}>
      {label && (
        <label className={inputLabel} htmlFor={id}>
          {label}
          {required && <span className={inputRequired}>*</span>}
        </label>
      )}
      <input type={type} placeholder={placeholder} className={`${inputElement} ${hasError ? errorBorder : ''}`} id={id} {...props}/>
      {error && <p className={inputError}>{error}</p>}
    </div>
  )
};

TextInput.propTypes = {
  type: string,
  label: node,
  id: string,
  name: string,
  placeholder: string,
  className: string,
  error: string,
  required: bool,
  hasError: bool
};

export default TextInput;
