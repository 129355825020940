export default {
  en: {
    heading: 'Congratulations!',
    printReceipt: 'Print Receipt'
  },
  es: {
    heading: 'Felicitaciones',
    printReceipt: 'Imprimir recibo'
  }
};
