export default {
  en: {
    heading: 'Choose your Payment Amount',
    subheading: 'Select the percentage you want to pay',
    percentage: 'Make a partial payment of {percentage}%',
    enterAmount: 'Or enter the exact amount you want to pay',
    amount: 'Amount',
    amountTooHigh: 'Amount must be equal or less than remaining balance',
    amountTooLow: 'Amount must be $1 minimum',
  },
  es: {
    heading: 'Elija su monto de pago',
    subheading: 'Seleccione el porcentaje que desea pagar',
    percentage: 'Realice un pago parcial de {percentage}%',
    enterAmount: 'O bien, ingrese el monto exacto que desea pagar',
    amount: 'Monto',
    amountTooHigh: 'El monto debe ser igual o menor que el saldo restante',
    amountTooLow: 'El monto debe ser de $1 como mínimo',
  }
};
