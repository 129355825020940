import React, { useEffect, useState } from 'react';
import {
  stepper,
  progressBar,
  currentStep,
  repaymentWrapper,
  selectRepayment,
  selected,
  blurred,
  optionsWrapper,
  repaymentOption,
  paymentsTag,
  selectedOptionTitle,
  selectedOptionSubtitle,
  currencyRow,
  title
} from '../styles.scss';

import { useLocale, useStateView } from '../../../hooks';
import { paymentPlanView } from '../../../state/views';

import rightArrowIcon from '../../../static/images/icons/rightArrow.svg';
import allMessages from '../messages';
import { cn, formatCurrency, generatePaymentScheduleFromPaymentPlan, mixpanel } from '../../../utils';
import { MIXPANEL_EVENTS, PAYMENT_FREQUENCIES } from '../../../constants';
import { Button, Carousel, MobileComponent } from '../../../components';
import { getRepaymentOptions } from '../../../service/paymentPlan';

const CreatePaymentStep = ({
  progress,
  referenceNumber,
  remainingBalance,
  stepperRef,
  setProgress,
  setCurrentStep
}) => {
  const { formatMessage, messages } = useLocale(allMessages);
  const [ paymentPlan, setPaymentPlan ] = useStateView(paymentPlanView);

  const [ paymentPlanOptions, setPaymentPlanOptions ] = useState(false);
  const [ selectedOption, setSelectedOption ] = useState();
  const [ selectedSlide, setSelectedSlide ] = useState(0);

  const handleSelectFrequency = (paymentFrequency, slideNumber) => {
    mixpanel.track(MIXPANEL_EVENTS.frequencySelected, { type: paymentFrequency, remainingBalance });
    setProgress({ ...progress, value: 2 });
    setSelectedOption(paymentFrequency);
    setSelectedSlide(slideNumber);
  };

  useEffect(() => {
    // fetch payment plan options when selected accountId changes
    getRepaymentOptions(referenceNumber)
      .then(response => {
        setPaymentPlan.update({
          paymentStartDateLimit: response.allowedPlanStartLatency
        });

        const { planOptions } = response;

        setPaymentPlanOptions({
          [PAYMENT_FREQUENCIES.weekly]: planOptions.weekly,
          [PAYMENT_FREQUENCIES.biweekly]: planOptions.biweekly,
          [PAYMENT_FREQUENCIES.monthly]: planOptions.monthly
        });
      });
  }, [ referenceNumber ]);

  const selectOption = option => {
    const {
      perPaymentAmount,
      planTerm,
      planScheduleFrequency
    } = paymentPlanOptions[selectedOption][option];

    mixpanel.track(MIXPANEL_EVENTS.termsSelected, { terms: planTerm, selectedOption, remainingBalance });

    setPaymentPlan.update({
      totalPaymentAmount: remainingBalance,
      paymentFrequency: selectedOption,
      standardPaymentAmount: perPaymentAmount.standard,
      lastPaymentAmount: perPaymentAmount.last || null,
      numPayments: planTerm * planScheduleFrequency,
    });

    setProgress({ ...progress, value: progress.value + 1 });
    setCurrentStep('setup');
    stepperRef.current.goToStep(2);
  };

  const hasPaymentOption = (paymentOption) => {
    return paymentPlanOptions[paymentOption]?.length > 0
  }

  const selectedOptionClass = (option) => {
    if (!selectedOption) return null;

    return selectedOption === option ? selected : blurred;
  }

  const paymentOptions = [PAYMENT_FREQUENCIES.monthly, PAYMENT_FREQUENCIES.biweekly, PAYMENT_FREQUENCIES.weekly];

  return (
    <>
      <progress className={progressBar} value={progress.value/progress.total} />

      <div className={stepper}>
        <h5>{messages.payments}</h5>
        <img src={rightArrowIcon} />
        <h5 className={currentStep}>{messages.createPayment.instruction}</h5>
      </div>

      <h2 className={title}>{messages.createPayment.title}</h2>
      <p>{messages.createPayment.subtitle}</p>

      {paymentPlanOptions && <MobileComponent
          WebOption={props => <div className={repaymentWrapper} {...props}></div>}
          MobileOption={props => <div className={repaymentWrapper}><Carousel initialSlide={selectedSlide} {...props}></Carousel></div>}
        >
          {paymentOptions.map((option, i) => (
            hasPaymentOption(option) && <Button
              key={i}
              className={cn(selectRepayment, selectedOptionClass(option))}
              onClick={() => handleSelectFrequency(option, i)}
            >
              <div>
                <span>{messages.frequency}</span>
                <h3>{messages[option]}</h3>
                <p>{messages.frequencySubtitle[option]}</p>
              </div>
            </Button>
          ))}
        </MobileComponent>
      }

      {selectedOption && <>
        <h3 className={selectedOptionTitle}>{messages.createPayment.boxesTitle}</h3>
        <div className={optionsWrapper}>
          {paymentPlanOptions[selectedOption].map((planOption, i) => {
            const {
              perPaymentAmount,
              planTerm,
              planScheduleFrequency
            } = planOption;

            const numPayments = planTerm * planScheduleFrequency;

            return <div key={i} className={repaymentOption}>
              <div>
                <span className={paymentsTag}>{numPayments} {formatMessage(messages.payments, { multiplePayments: numPayments !== 1 })}</span>
                <div className={currencyRow}>
                  <h3>{formatCurrency(perPaymentAmount.standard)}</h3>
                  <span>{messages.paymentFrequencies[selectedOption]}*</span>
                </div>
              </div>
              <Button variant={['primary']} onClick={() => selectOption(i)}>{messages.createPayment.selectPayment}</Button>
            </div>
          })}
        </div>
        <div className={selectedOptionSubtitle}>{ messages.createPayment.finalPayment }</div>
      </>}
    </>
  );
};

export default CreatePaymentStep;
