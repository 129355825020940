import React, { useState } from 'react';

import { stepper, hasBackArrow, planTag, paymentMethodWrapper, paymentButtons, mobileArrow, webArrow } from '../styles.scss';
import { cn, redirect } from '../../../utils';

import BackArrow from '../../../static/images/icons/BackArrow';

import stepFourSvg from '../../../static/images/icons/stepFour.svg';

import stepFiveCustomPlanSvg from '../../../static/images/icons/stepFiveCustomPlan.svg';

import { Button, PaymentMethodForm, PlanDetailBox } from '../../../components';

import allMessages from '../messages';
import { useLocale } from '../../../hooks';
import { paths } from '../../../routes';
import PaymentFrequencyMessage from '../../../components/PaymentFrequencyMessage';

const PaymentMethodStep = ({
  referenceNumber,
  paymentPlan,
  currentBalance,
  selectedPlanType
}) => {
  const { formatMessage, messages } = useLocale(allMessages);
  const isFullPayment = paymentPlan.numPayments === 1;
  const title = isFullPayment ? messages.fullPaymentTitle : messages.title;

  const [currentStep, setCurrentStep] = useState(stepFiveCustomPlanSvg);

  return (
    <>
      <div className={stepper}>
        <img src={currentStep} />
      </div>

      <div className={planTag}>{messages[selectedPlanType]}</div>
      <h1>{title}</h1>
      <p>{messages.steps.paymentMethod.subtitle}</p>

      <div className={paymentMethodWrapper}>
        <PaymentMethodForm
          className={paymentButtons}
          accountId={referenceNumber}
          onSubmit={() => redirect(paths.paymentConfirm)}
          callbackFunction={() => setCurrentStep(stepFourSvg)}
        />
        <PlanDetailBox
          planType={messages[selectedPlanType]}
          referenceNumber={referenceNumber}
          paymentPlan={paymentPlan}
          currentBalance={currentBalance}
          paymentFrequency={<PaymentFrequencyMessage />}
          fullPayment={isFullPayment}
          withCurrentBalance
        />
      </div>
    </>
  )
};

export default PaymentMethodStep;
