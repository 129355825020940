import React, { useState } from 'react';

import { useLocale, useStateView } from '../../../hooks';

import { PaymentMethodForm } from '../../../components';

import stepTwoSvg from '../../../static/images/icons/stepTwoSettlement.svg';
import stepThreeSvg from '../../../static/images/icons/stepThreeSettlement.svg';

import stepThreeCustomSvg from '../../../static/images/icons/stepThreeCustomSettlement.svg';
import stepFourCustomSvg from '../../../static/images/icons/stepFourCustomSettlement.svg';

import { stepper } from '../../PaymentPlanOfferLandingPage/styles.scss';
import { informationWrapper, paymentForm, wrapperClass } from '../styles.scss';


import allMessages from '../messages';
import StepHeader from './StepHeader';

import { paymentPlanView } from '../../../state/views';
import { PAYMENT_PLAN_TYPES } from '../../../constants';

const SelectPaymentStep = ({ 
  referenceNumber,
  settlementOffers,
  handleToggle = () => {},
  onSubmit = () => {}
}) => {
  const { messages } = useLocale(allMessages);
  const [ paymentPlan ] = useStateView(paymentPlanView);

  const isSettleInFull = paymentPlan.planType === PAYMENT_PLAN_TYPES.settleInFull;
  const [ step, setStep ] = useState(isSettleInFull ? stepTwoSvg : stepThreeCustomSvg);
  const title = isSettleInFull ? messages.paymentStep.title : messages.paymentStep.variant;

  return (
    <>
      <StepHeader
        step={step}
        referenceNumber={referenceNumber}
        settlementOffers={settlementOffers}
        handleToggle={handleToggle}
      />

      <div className={informationWrapper}>
        <h2>{title}</h2>
        <p>{messages.paymentStep.subtitle}</p>

        <PaymentMethodForm
          className={wrapperClass}
          formClass={paymentForm}
          accountId={referenceNumber}
          onSubmit={onSubmit}
          callbackFunction={() => setStep(isSettleInFull ? stepThreeSvg : stepFourCustomSvg)}
        />
      </div>
    </>
  )
};

export default SelectPaymentStep;
