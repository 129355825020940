import React, { useEffect, useState } from 'react';
import { useParams, useStateView } from '../../hooks';
import { localeView } from '../../state/views';

import Dropdown from '../Dropdown';
import { languageDropdown } from './styles.scss';
import { cn } from '../../utils';
import { string } from 'prop-types';
import Button from '../Button';

const localeItems = [
  {
    label: 'En español',
    value: 'en',
    smallLabel: 'ES'
  },
  {
    label: 'In English',
    value: 'es',
    smallLabel: 'EN'
  }
];

const LanguageSelect = ({ className }) => {
  const { lang } = useParams();
  const [ locale, setLocale ] = useStateView(localeView);
  const [ selectedItem, setSelectedItem ] = useState(localeItems[0]);

  useEffect(() => {
    if (lang && lang !== locale) {
      setLocale(lang);
      location?.reload();
    }
  }, [ lang ]);

  useEffect(() => {
    if (locale !== selectedItem.value) {
      setSelectedItem(localeItems.find(item => item.value === locale));
    }
  }, []);

  const getItemLabel = () => {
    return window.innerWidth >= 480 ? selectedItem.label : selectedItem.smallLabel;
  }

  const handleChange = () => {
    const htmlElement = document?.querySelector('html');
    const currentValue = selectedItem.value;
    const language = currentValue === 'en' ? { value: 'es', index: 1 } : { value: 'en', index: 0 };
    setSelectedItem(localeItems[language.index]);

    if (htmlElement) {
      htmlElement.setAttribute('lang', language.value);
    }

    if (language.value !== locale) {
      setLocale(language.value);
    }
  };

  return (
    <Button variant={['small', 'transparent']} className={languageDropdown} onClick={handleChange}>
      {getItemLabel()}
    </Button>
  )
};

LanguageSelect.propTypes = {
  className: string
};

export default LanguageSelect;
