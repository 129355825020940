import React, { useEffect } from 'react';
import { useLocale, useStateView } from '../../../hooks';

import stepTwoSvg from '../../../static/images/icons/stepTwoCustomSettlement.svg';
import StepHeader from './StepHeader';

import allMessages from '../messages';

import { informationWrapper } from '../styles.scss';
import { RepaymentFrequencyBoxes } from '../../../components';
import { negotiatedPlanView } from '../../../state/views';

const SelectFrequencyStep = ({ 
  settlementOffers,
  referenceNumber,
  handleToggle = () => {}
}) => {
  const { messages } = useLocale(allMessages);
  const [ negotiatedPlan ] = useStateView(negotiatedPlanView);

  return (
    <>
      <StepHeader
        step={stepTwoSvg}
        referenceNumber={referenceNumber}
        settlementOffers={settlementOffers}
        handleToggle={handleToggle}
      />

      <div className={informationWrapper}>
        <h2>{messages.frequencyStep.title}</h2>
        <p>{messages.frequencyStep.subtitle}</p>
      </div>

      <RepaymentFrequencyBoxes />
    </>
  )
};

export default SelectFrequencyStep;
