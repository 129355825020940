import { useEffect, useState } from 'react';
import { scrubObject } from '../utils/scrubObject';

export default view => {
  const { store, read, write } = view;
  const { key, state } = read();
  const [ , setKey ] = useState(key);

  const clear = () => write(read(store.initialState).state);
  const writeOrUpdate = state => write(state);
  writeOrUpdate.update = partialState => {
    const { state } = read();

    const newState = typeof state === 'object' && typeof partialState === 'object'
      ? { ...state, ...scrubObject(partialState) }
      : partialState;
    
    write(newState);
  };

  useEffect(() => {
    const callback = () => {
      const { key } = read();
      setKey(key);
    };

    store.subscribeView(view, callback);
    return () => store.unsubscribeView(view, callback);
  }, [ view, store ]);

  return [ state, writeOrUpdate, clear ];
};
