import React, { useEffect, useRef, useState } from 'react';
import { useLocale, useOnce, useStateView } from '../../hooks';
import { accountView, documentsView } from '../../state/views';
import { getDocument, getDocuments } from '../../service/account';

import allMessages from './messages';

import { AccountDetails, Button, Spinner } from '../../components';
import { DownloadIcon } from '../../components/icons';

import { documentsListItem, documentsEmpty, documentsPageStyle } from './styles.scss';
import { MIXPANEL_EVENTS } from '../../constants';
import EmptyPage from '../../components/EmptyPage/index.js';
import { mixpanel } from '../../utils';
import { useRollbar } from '@rollbar/react';

const DocumentsPage = () => {
  const { messages } = useLocale(allMessages);
  const [ { accountId } ] = useStateView(accountView);
  const [ documents, setDocuments ] = useStateView(documentsView);
  const [ documentContents, setDocumentContents ] = useState({});
  const [ isLoading, setIsLoading ] = useState(false);
  const downloadLinkRef = useRef();
  const rollbar = useRollbar();

  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewDocumentsPage));

  useEffect(() => {
    if (documents) return;

    setIsLoading(true);
    
    // get list of documents for this account
    getDocuments(accountId)
      .then(({ documents }) => {
        if (documents?.[0]?.documentId === 'No documents available') return;
        setDocuments(documents?.sort((a, b) => a.documentName > b.documentName ? 1 : -1) || [])
      })
      .catch(err => {
        rollbar.error('Error fetching documents', err, { accountId });
        setDocuments([]);
      })
      .finally(() => setIsLoading(false));
  }, [ accountId ]);
  
  const createDownloadHandler = ({ documentId, documentName }) => 
    async () => {
      mixpanel.track(MIXPANEL_EVENTS.downloadDocument, { fileName: documentName });
  
      if (documentId in documentContents === false) {
        await getDocument({ accountId, documentId })
          .then(({ documentBase64 }) => {
            documentContents[documentId] = documentBase64;
          });
      }

      const base64 = documentContents[documentId];
      const a = downloadLinkRef.current;
      a.download = documentName;
      a.href = `data:application/octet-stream;base64,${base64.replace(/(\r|\n)/gm, '')}`;
      a.click();
      setDocumentContents({ ...documentContents });
    };

  return <div className={documentsPageStyle}>
    <h1>{ messages.title }</h1>
    {
      isLoading ? (
        <Spinner />
      ) : (
        <>
          {!documents?.length && (
            <div className={documentsEmpty}>
              <EmptyPage PageTitle={messages.empty} />
            </div>
          )}
          {documents instanceof Array && documents.length > 0 && (
            <>
              <section><AccountDetails /></section>
              <div>
                {documents.map(document => {
                  const { documentId, documentName } = document;

                  return (
                    <section key={`document-${accountId}-${documentId}`} className={documentsListItem}>
                      <div>
                        <p><strong>{documentName}</strong></p>
                      </div>
                      <Button onClick={createDownloadHandler(document)} variant={['outline', 'alternate']}>
                        <DownloadIcon />{messages.download}
                      </Button>
                    </section>
                  );
                })}
              </div>
            </>
          )}
        </>
      )
    }
    <a style={{ display: 'none' }} ref={downloadLinkRef} />
  </div>;
};

export default DocumentsPage;
