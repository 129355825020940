import React from 'react';
import { string } from 'prop-types';

const TabContent = ({ group, id, className, children, active }) => {
  return <div data-tab-group={group} data-tab-id={`${group}-${id}`} className={className} style={{ display: active ? 'block' : 'none' }}>
    { children }
  </div>;
};

TabContent.propTypes = {
  group: string.isRequired,
  id: string.isRequired,
  className: string
};

export default TabContent;
