import React, { useEffect } from 'react';
import { bool, string } from 'prop-types';

const Tab = ({ group, id, active = false, children, onClick = () => {}, ...props }) => {
  const tabId = `${group}-${id}`;

  useEffect(() => {
    if (active) {
      switchActiveTab(tabId);
    }
  }, []);

  const switchActiveTab = e => {
    const contentElements = Array.prototype.slice.call(document.querySelectorAll(`[data-tab-group="${group}"]`));

    for (const element of contentElements) {
      element.style.display = element.getAttribute('data-tab-id') === tabId
        ? null // unset
        : 'none';
    }

    onClick(e);
  };

  return <>
    <label onClick={switchActiveTab} htmlFor={tabId} {...props}>{ children }</label>
    <input type='radio' name={group} id={tabId} style={{ display: 'none' }} />
  </>;
};

Tab.propTypes = {
  group: string.isRequired,
  id: string.isRequired,
  active: bool
};

export default Tab;
