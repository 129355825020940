export default {
  en: {
    cardTabLabel: 'Credit / Debit Card',
    achTabLabel: 'Bank Account (ACH)',
    emailDisclosure: 'By providing your email address, you consent to receiving correspondence via email from Spring Oaks Capital, LLC or any entities that Spring Oaks Capital, LLC places your account with. This consent will nullify any previous unsubscribe requests received by Spring Oaks Capital, LLC. To protect your privacy, please do not submit any email accounts that are shared or are work email accounts.'
  },
  es: {
    cardTabLabel: 'Tarjeta de crédito / débito',
    achTabLabel: 'Cuenta bancaria (ACH)',
    emailDisclosure: 'Al proporcionar su dirección de correo electrónico, usted acepta recibir correspondencia por correo electrónico de Spring Oaks Capital, LLC o de cualquier entidad con la que Spring Oaks Capital, LLC coloque su cuenta. Este consentimiento anulará cualquier solicitud de cancelación de la suscripción previa que Spring Oaks Capital, LLC haya recibido. Para proteger su privacidad, no envíe ninguna cuenta de correo electrónico compartida o cuenta de correo electrónico laboral',
  }
};
