import React, { useMemo } from 'react';
import { number, string } from 'prop-types';

import { cn } from '../../utils';
import { progressBar } from './styles.scss';

const ProgressBar = ({ value, max = 100, min = 0, className, ...props }) => {
  const percentage = useMemo(
    () => Math.min(1, Math.max((value - min) / (max - min), 0)) * 100,
    [ value, max, min ]
  );

  return <div className={cn(progressBar, className)} { ...props }>
    <div style={{width: `${percentage}%`}}></div>
  </div>;
};

ProgressBar.propTypes = {
  value: number.isRequired,
  max: number,
  min: number,
  className: string
};

export default ProgressBar;
