import React from 'react';
import CreatePaymentStep from './CreatePaymentStep';
import PartialPaymentStep from './PartialPaymentStep';
import { paymentPlanView } from '../../../state/views';
import { useStateView } from '../../../hooks';
import { PAYMENT_PLAN_TYPES } from '../../../constants';

const InitialStep = ({
  progress,
  referenceNumber,
  remainingBalance,
  stepperRef,
  setProgress = () => {},
  setCurrentStep = () => {}
}) => {
  const [ paymentPlan, setPaymentPlan ] = useStateView(paymentPlanView);

  return (
    <>
      {paymentPlan.planType === PAYMENT_PLAN_TYPES.paymentPlan && <CreatePaymentStep
        progress={progress}
        referenceNumber={referenceNumber}
        remainingBalance={remainingBalance}
        stepperRef={stepperRef}
        setProgress={setProgress}
        setCurrentStep={setCurrentStep}
      />}
      {paymentPlan.planType === PAYMENT_PLAN_TYPES.paymentPartial && <PartialPaymentStep
        progress={progress}
        referenceNumber={referenceNumber}
        remainingBalance={remainingBalance}
        stepperRef={stepperRef}
        setProgress={setProgress}
        setCurrentStep={setCurrentStep}
      />}
    </>
  );
};

export default InitialStep;
