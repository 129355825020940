import React, { useEffect, useMemo, useState } from 'react';

import { useLocale, useOnce, useParams, useStateView } from '../../hooks';
import { accountView, redirectView } from '../../state/views';

import { Button } from '../../components';

import { mixpanel, redirect } from '../../utils';
import allMessages from './messages';

import { paths } from '../../routes';

import { disclosures, buttonContainer } from './styles.scss';
import { MIXPANEL_EVENTS } from '../../constants';
import { getDisclosures } from '../../service/account';
import { useRollbar } from '@rollbar/react';
import { useMatch } from 'react-router-dom';

const DisclosuresPage = () => {
  const rollbar = useRollbar();
  const { formatMessage, locale, messages } = useLocale(allMessages);
  const [ redirectUrl, setRedirectUrl ] = useStateView(redirectView);
  const [ account ] = useStateView(accountView);
  const [ { currentCreditorName, solDisclosureID }, setDisclosure ] = useState(account);
  const { referenceNumber } = useParams();
  const isUniqueDisclosuresRoute = !!(useMatch(paths.uniqueDisclosures));

  useEffect(() => {
    if (referenceNumber && isUniqueDisclosuresRoute && !solDisclosureID) {
      getDisclosures(referenceNumber.replace(/\D+$/g, ''))
        .then(response => setDisclosure(response))
        .catch(err => rollbar.error('Error fetching disclosures', { err, referenceNumber, solDisclosureID, isUniqueDisclosuresRoute }));
    }
  }, [ referenceNumber ]);

  const solDisclosure = useMemo(
    () => {
      const key = `solDisclosure${solDisclosureID}`;
      return key in messages
        ? formatMessage(messages[key], { currentCreditorName })
        : false;
    },
    [ solDisclosureID, locale ]
  );

  const acceptDisclosures = () => {
    mixpanel.track(MIXPANEL_EVENTS.acceptDisclosures);

    setRedirectUrl('');
    redirect(redirectUrl || paths.home);
  };

  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewDisclosuresPage));

  return <div className={disclosures}>
    <h1>{ messages.header }</h1>
    <hr/>
    { solDisclosure && <p data-sol-disclosure={solDisclosureID}>{solDisclosure}</p> }
    { messages.defaultDisclosures.map(({ title, subtitle, paragraph }) => <>
      { title && <h3>{title}</h3> }
      { subtitle && <p>{subtitle}</p> }
      <p>{ paragraph }</p>
    </>) }
    <div className={buttonContainer}>
      <Button variant={[ 'full', 'large' ]} onClick={acceptDisclosures} aria-label={messages.continueAria}>{ messages.continue }</Button>
    </div>
  </div>;
};

export default DisclosuresPage;
