export default {
  en: {
    header: 'Previous Payments',
    empty: 'You don\'t have any payments yet',
    columns: {
      amount: 'Amount',
      date: 'Date',
      paymentMethod: 'Payment Method',
      statusCode: 'Status',
      transactionId: 'Transaction Id'
    },
    paymentSources: {
      checking: 'CHECKING',
      savings: 'SAVINGS',
      debit: 'DEBIT',
      credit: 'CREDIT',
      visa: 'VISA',
      mastercard: 'MASTERCARD'
    },
    paymentMethod: {
      'Adjustment': 'Adjustment',
      'Cash': 'Cash',
      'Check': 'Check',
      'eCheck': 'eCheck',
      'Credit Card': 'Credit Card',
      'Debit Card': 'Debit Card',
      'Direct': 'Direct',
      'Money Order': 'Money Order',
      'Other': 'Other',
    },
    statusCode: {
      PENDING: 'Pending',
      PAID: 'Paid',
      FAILURE: 'Failure',
      OTHER: 'Other'
    }
  },
  es: {
    header: 'Pagos anteriores',
    empty: 'Aún no tiene ningún pago',
    columns: {
      amount: 'Monto',
      date: 'Fecha',
      paymentMethod: 'Método de pago',
      statusCode: 'Estado',
      transactionInfo: 'ID de transacción'
    },
    paymentMethod: {
      'Adjustment': 'Ajuste',
      'Cash': 'Efectivo',
      'Check': 'Cheque',
      'eCheck': 'Cheque electrónico',
      'Credit Card': 'Tarjeta de crédito',
      'Debit Card': 'Tarjeta de débito',
      'Direct': 'Directo',
      'Money Order': 'Giro postal',
      'Other': 'Otro',
    },
    statusCode: {
      PENDING: 'Pendiente',
      PAID: 'Pagado',
      FAILURE: 'Fallo',
      OTHER: 'Otro'
    }
  }
};
