export default {
  en: {
    title: 'Oh no!',
    subtitle: 'Something went wrong',
    action: 'This Offer is either invalid or has expired.  Please log into the self service portal to access your account and make a payment.',
    cta: 'Back to Home Page'
  },
  es: {
    title: '¡Oh no!',
    subtitle: 'Algo salió mal',
    action: 'Esta oferta no es válida o ha vencido. Inicie sesión en el portal de autoservicio para acceder a su cuenta y realizar un pago.',
    cta: 'Volver a la página de Inicio'
  }
};
