export default {
  en: {
    heading: 'Set up your Payment{payments?s:}',
    paymentDatePrompt: 'When would you like your payment{payments?s:} to {payments?begin:be scheduled}?',
    paymentDateLabel: 'Select a Date',
    paymentDateFootnote: 'You can select a date up to {days} day{multipleDays?s:} from today.',
    frequency: 'Frequency',
  },
  es: {
    heading: 'Configure su{payments?s:} pago{payments?s:}',
    paymentDatePrompt: '¿Cuándo le gustaría que su{payments?s:} pago{payments?s:} a {payments?comience(n):se programe(n)}?',
    paymentDateLabel: 'Seleccione una fecha',
    paymentDateFootnote: 'Puede seleccionar una fecha de hasta {days} día{multipleDays?s:} a partir de hoy.',
    frequency: 'Frecuencia'
  }
};
