import React from 'react';
import { useLocale, useParams, useStateView } from '../../hooks';

import {
  detailBox,
  wrapper,
  infoRow,
  planTypeTag,
  separator,
  balance,
  greyed,
  webDisplay,
  mobileDisplay,
  alignRight,
  confirmInfo,
  numberPayments
} from './styles.scss'
import { cn, formatCurrency, splitLastPayment, splitUpcomingPayment } from '../../utils';
import { paymentPlanView } from '../../state/views';
import { PAYMENT_PLAN_TYPES } from '../../constants';

import allMessages from './messages'
import ResumedPaymentSummary from '../ResumedPaymentSummary';
import PaymentFrequencyMessage from '../PaymentFrequencyMessage';

const SettlementDetailBox = ({
  settlementOffers,
  referenceNumber,
  showDate = false,
  isSettlementPlan = false,
  isSuccessPage = false,
  isConfirmPage = false
}) => {
  const { formatDate, messages } = useLocale(allMessages);
  const [ paymentPlan ] = useStateView(paymentPlanView);

  const { numPayments, paymentStartDate, planType, paymentSchedule } = paymentPlan;

  let upcomingPayment = '';
  let lastPayment = ''

  if (paymentSchedule.length && paymentStartDate) {
    const startDatePayment = formatDate(paymentStartDate, { month: 'long', day: 'numeric' })
    upcomingPayment = `${messages.upcomingPayment} 1 ${messages.of} ${numPayments} (${messages.dueOn} ${startDatePayment})`;
  
    const lastDatePayment = formatDate(paymentSchedule.at(-1).date, { month: 'long', day: 'numeric', year: 'numeric' });
    lastPayment = `${messages.lastPayment} ${lastDatePayment}`;
  }

  const isPayInFull = planType === PAYMENT_PLAN_TYPES.settleInFull;
  const planTypeText = isPayInFull ? messages.onePayment : messages.customPlan;

  return (
    <>
      <div className={detailBox}>
        <div className={wrapper}>
          <div className={infoRow}>
            <h5>#{referenceNumber}</h5>
            <span className={planTypeTag}>{planTypeText}</span>
          </div>
          <h2>{messages.paymentDetails}</h2>
          <div className={cn(infoRow, separator, webDisplay)}>
            {showDate ?
              <>
                <h5>{upcomingPayment}</h5>
                {!isPayInFull && <h5>{lastPayment}</h5>}
              </>
              : (
                <>
                  <h5>{messages.resolveBalance}</h5>
                  <div className={balance}>
                    <span>$ <strong>{formatCurrency(settlementOffers.offerAmount, '', true)}</strong></span>
                  </div>
                </>
              )
            }
          </div>
          {showDate ?
            <>
              <div className={cn(infoRow, mobileDisplay, isPayInFull ? separator : null)}>
                <h5>{splitUpcomingPayment(upcomingPayment)[0]}</h5>
                <h5 className={alignRight}><strong>{splitUpcomingPayment(upcomingPayment)[1]}</strong></h5>
              </div>
              {!isPayInFull && <div className={cn(infoRow, separator, mobileDisplay)}>
                <h5>{splitLastPayment(lastPayment)[0]}</h5>
                <h5 className={alignRight}><strong>{splitLastPayment(lastPayment)[1]}</strong></h5>
              </div>}
            </> :
            <>
              <div className={cn(infoRow, separator, mobileDisplay)}>
                <h5>{messages.resolveBalance}</h5>
                <div className={balance}>
                  <span>$ <strong>{formatCurrency(settlementOffers.offerAmount, '', true)}</strong></span>
                </div>
              </div>
            </>
          }
        </div>
        {isSettlementPlan && paymentPlan.paymentFrequency ?
          <ResumedPaymentSummary
            frequencyMessage={<PaymentFrequencyMessage />}
            isSuccessPage={isSuccessPage}
          />
          : <div className={isConfirmPage || isSuccessPage ? confirmInfo : infoRow}>
            {isConfirmPage || isSuccessPage ? <>
              <h5>{messages.amount}</h5>
              <div className={infoRow}>
                <h2><span>$ <strong>{formatCurrency(settlementOffers.offerAmount, '', true)}</strong></span></h2>
                <span className={numberPayments}>X1</span>
              </div>
            </> : <>
              <h5>{messages.currentBalance}</h5>
              <div className={cn(balance, greyed)}>
                <span>$ <strong>{formatCurrency(settlementOffers.currentBalance, '', true)}</strong></span>
              </div>
            </>}
          </div>
        }
      </div>
    </>
  )
};

export default SettlementDetailBox;
