import React, { useEffect } from 'react';
import { Header, LanguageSelect, LegalFooter, OfferHeader, PaymentMethodForm, Spinner } from '../../components';
import allMessages from './messages';
import { useLocale, useOnce, useParams, useStateView } from '../../hooks';
import { paths } from '../../routes';
import { paymentPlanView } from '../../state/views';
import { debugConsole, mixpanel, redirect, formatCurrency } from '../../utils';
import { frictionlessPayment, content, disclosures, outerWrapper, titlePart1, titlePart2, repaymentPlanColumns, languageSelect } from './styles.scss';
import { getPaymentPlans } from '../../service/paymentPlan';
import { MIXPANEL_EVENTS, PAYMENT_FREQUENCIES, PAYMENT_INFO_FORMAT, PAYMENT_METHODS, PAYMENT_PLAN_TYPES } from '../../constants';

function FrictionlessLandingPage() {
  const { formatDate, formatMessage, messages } = useLocale(allMessages);
  const [ paymentPlan, setPaymentPlan ] = useStateView(paymentPlanView);
  const { referenceNumber } = useParams();

  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewFrictionlessLandingPage));

  if (!referenceNumber) {
    redirect(paths.specialOfferError);
  }

  useEffect(() => {
    getPaymentPlans(referenceNumber)
      .then(data => {
        const { accountId } = data;

        if ('paymentPlans' in data === false) {
          redirect(paths.specialOfferError);
        }

        const plan = data?.paymentPlans[0];
        const lastPaymentAmount = plan?.paymentSchedule[plan.planLength - 1].paymentAmount;
        const { remainingBalance, paymentSchedule } = plan;

        const transformedPaymentSchedule = [ ...paymentSchedule.map(({ paymentId, paymentAmount, paymentStatus, paymentDate }) => ({
          id: paymentId,
          amount: paymentAmount,
          status: paymentStatus,
          date: paymentDate
        })) ];

        const planObject = {
          planType: PAYMENT_PLAN_TYPES.paymentPlan,
          referenceNumber: accountId,
          paymentStartDate: paymentSchedule[0].paymentDate,
          numPayments: plan.planLength,
          paymentFrequency: PAYMENT_FREQUENCIES.monthly,
          paymentSchedule: transformedPaymentSchedule,
          totalPaymentAmount: remainingBalance,
          standardPaymentAmount: plan.paymentSize,
          lastPaymentAmount:
            plan.planLength > 0 && lastPaymentAmount !== plan.paymentSize
              ? lastPaymentAmount
              : null
        };

        setPaymentPlan(planObject);
      })
      .catch(e => {
        debugConsole.error('Error fetching payment plans on Frictionless page', e);
        redirect(paths.specialOfferError);
      });
  }, [ referenceNumber ]);

  const submitPayment = () => {
    redirect(paths.paymentConfirm);
  }

  return <>
    <div className={languageSelect}>
      <LanguageSelect />
    </div>
    <div className={outerWrapper}>
    <h1>
        <span className={titlePart1}>{ messages.titlePart1 }</span>
        <span className={titlePart2}>{ messages.titlePart2 }</span>
      </h1>

      <section className={frictionlessPayment}>
        {paymentPlan.referenceNumber ? <>
          <OfferHeader currentBalance={paymentPlan.totalPaymentAmount} referenceNumber={referenceNumber} />
          <div className={content}>
            <h3>{messages.planIntro}</h3>

            <div className={repaymentPlanColumns}>
              <div><PaymentMethodForm accountId={paymentPlan.referenceNumber} onSubmit={submitPayment} showDefault={PAYMENT_METHODS.card} /></div>
              <div>
                <h3>{ messages.paymentDates }</h3>
                <hr/>
                <ol>
                  { paymentPlan.paymentSchedule.map(
                    ({ date, amount }, i) => <li key={`${i}-${date}`}>
                        { formatDate(date, PAYMENT_INFO_FORMAT) } - { formatCurrency(amount) }
                      </li>
                  ) }
                </ol>
              </div>
            </div>

            <div className={disclosures}>
              <LegalFooter showCreditReportingMessage></LegalFooter>
            </div>
          </div>
        </> : <Spinner variant="large" />}
      </section>
    </div>
  </>;
}

export default FrictionlessLandingPage;
