import store from '../state/store';
import { paths } from '../routes';
import { redirect } from './redirect';
import { mixpanel } from '.';
import { MIXPANEL_EVENTS } from '../constants';
import { useStateView } from '../hooks';
import { selectedReferenceNumberView } from '../state/views';

export default () => {
  // clear state store, don't notify listeners
  // (so we can call this outside of a component)
  store.clear(false);

  mixpanel.track(MIXPANEL_EVENTS.logOut, { Location: location.href });
  mixpanel.reset();

  // redirect to login
  redirect(paths.login);
};

