import React, { useMemo, useState } from 'react';
import { useLocale } from '../../hooks';
import Dropdown from '../Dropdown';
import { weekdayString } from '../../utils/date';
import { PAYMENT_FREQUENCIES } from '../../constants';

const DayOfWeekDropdown = ({
  onChange = () => {},
  frequency = PAYMENT_FREQUENCIES.weekly,
  ...props
}) => {
  const { locale, messages } = useLocale();

  // current day of week is default
  const [ selectedIndex, setSelectedIndex ] = useState(new Date().getDay());

  const everyMessage = useMemo(
    () => frequency === PAYMENT_FREQUENCIES.biweekly
      ? messages.everyOther
      : messages.every,
    [ frequency, locale ]
  );

  // only generate items once per locale
  const items = useMemo(() => {
    const days = [];

    for (let value = 0; value < 7; ++value) {
      let label = `${everyMessage} ${weekdayString(value, locale)}`;
      label = label[0].toUpperCase() + label.substring(1);

      days.push({
        label,
        value,
        // selection will stay the same when locale changes
        isDefault: value === selectedIndex
      });
    }

    return days;
  }, [ locale ]);

  const handleChange = (item, e) => {
    setSelectedIndex(item.value);

    if (onChange instanceof Function) {
      onChange(item, e);
    }
  };

  return <Dropdown { ...props } items={items} onChange={handleChange} name="dayOfWeek" />;
};

export default DayOfWeekDropdown;
