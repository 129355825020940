import React from 'react';
import { bool, func } from 'prop-types';
import { modal, close } from './styles.scss';

const Modal = ({ visible, closable = true, onClose = () => {}, children }) => {
  const handleClickClose = e => {
    e.preventDefault();
    onClose(e);
  };

  return visible &&
    <div className={modal} onClick={closable ? onClose : () => {}}>
      <dialog open onClick={e => e.stopPropagation()}>
        { closable && <button className={close} onClick={handleClickClose}>✕</button> }
        { children }
      </dialog>
    </div>;
};
  

Modal.propTypes = {
  onClose: func,
  visible: bool.isRequired
};

export default Modal;
