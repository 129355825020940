export default {
  en: {
    onePayment: 'One Payment',
    customPlan: 'Custom Plan',
    paymentDetails: 'Payment Details',
    resolveBalance: 'Resolve your balance for',
    lastPayment: 'Last payment',
    amount: 'Amount'
  },
  es: {
    onePayment: 'Un pago',
    customPlan: 'Plan personalizado',
    paymentDetails: 'Detalles del pago',
    resolveBalance: 'Resuelva su saldo por:',
    lastPayment: 'Último pago',
    amount: 'Monto'
  }
}