import React, { useEffect, useState } from 'react';

import {
  useActivePaymentPlanStatus,
  useLocale,
  useQuiqChat,
  useStateView
} from '../../hooks';
import { accountView, paymentPlanView } from '../../state/views';
import { getRepaymentOptions } from '../../service/paymentPlan';

import { MIXPANEL_EVENTS, PAYMENT_FREQUENCIES } from '../../constants';
import allMessages from './messages.js';
import { cn, debugConsole, formatCurrency, mixpanel, redirect } from '../../utils';

import { Button, Spinner } from '../../components';

import {
  BiweeklyIcon,
  WeeklyIcon,
  MonthlyIcon
} from '../../components/icons';

import {
  createPlanPage,
  selectedFrequency,
  optionList,
  frequencyOptionList,
  repaymentOptionList,
  repaymentSubheading
} from './styles.scss';
import { paths } from '../../routes';

const PaymentPlanCreatePage = () => {
  const [ { accountId, remainingBalance } ] = useStateView(accountView);
  const [ paymentPlan, setPaymentPlan ] = useStateView(paymentPlanView);
  const { formatMessage, messages } = useLocale(allMessages);

  // override the default everyTwoWeeks label
  const repaymentOptionFrequencyLabels = {
    ...messages.paymentFrequencies,
    everyTwoWeeks: messages.paymentFrequencies.biweekly
  };

  const [ paymentPlanOptions, setPaymentPlanOptions ] = useState(false);

  const {
    paymentFrequency
  } = paymentPlan;

  const handleSelectFrequency = paymentFrequency => {
    mixpanel.track(MIXPANEL_EVENTS.frequencySelected, { type: paymentFrequency, remainingBalance });
    setPaymentPlan.update({ paymentFrequency });
  };

  useQuiqChat({ debtId: accountId });
  
  useEffect(() => {
    // fetch payment plan options when selected accountId changes
    getRepaymentOptions(accountId)
      .then(response => {
        setPaymentPlan.update({
          paymentStartDateLimit: response.allowedPlanStartLatency
        });

        const { planOptions } = response;

        setPaymentPlanOptions({
          [PAYMENT_FREQUENCIES.weekly]: planOptions.weekly,
          [PAYMENT_FREQUENCIES.biweekly]: planOptions.biweekly,
          [PAYMENT_FREQUENCIES.monthly]: planOptions.monthly
        });
      });
  }, [ accountId ]);

  const { canCreatePlan } = useActivePaymentPlanStatus();

  if (!canCreatePlan) {
    debugConsole.warn('User has an active payment plan or promise. Redirecting to homepage');
    redirect(paths.home);
  }

  const handleSelectOption = i => {
    const {
      perPaymentAmount,
      planTerm,
      planScheduleFrequency
    } = paymentPlanOptions[paymentFrequency][i];

    mixpanel.track(MIXPANEL_EVENTS.termsSelected, { terms: planTerm, paymentFrequency, remainingBalance });

    setPaymentPlan.update({
      totalPaymentAmount: remainingBalance,
      standardPaymentAmount: perPaymentAmount.standard,
      lastPaymentAmount: perPaymentAmount.last || null,
      numPayments: planTerm * planScheduleFrequency
    });

    redirect(paths.paymentPlanSetup);
  };

  return <div className={createPlanPage}>
      <h1>{ messages.title }</h1>
      { paymentPlanOptions
        ? <>
            <ul className={cn(optionList, frequencyOptionList)}>
              { paymentPlanOptions[PAYMENT_FREQUENCIES.weekly]?.length > 0 && <li>
                <a
                  data-action="select weekly frequency"
                  onClick={() => handleSelectFrequency(PAYMENT_FREQUENCIES.weekly)}
                  className={cn({ [selectedFrequency]: paymentFrequency === PAYMENT_FREQUENCIES.weekly })} >
                  <WeeklyIcon alt="calendar with every week highlighted" />
                  { messages.paymentFrequencies.weekly }
                </a>
              </li> }
              { paymentPlanOptions[PAYMENT_FREQUENCIES.biweekly]?.length > 0 && <li>
                <a
                  data-action="select biweekly frequency"
                  onClick={() => handleSelectFrequency(PAYMENT_FREQUENCIES.biweekly)}
                  className={cn({ [selectedFrequency]: paymentFrequency === PAYMENT_FREQUENCIES.biweekly })} >
                  <BiweeklyIcon alt="calendar with every other week highlighted" />
                  { messages.paymentFrequencies.biweekly }
                </a>
              </li> }
              { paymentPlanOptions[PAYMENT_FREQUENCIES.monthly]?.length > 0 && <li>
                <a
                  onClick={() => handleSelectFrequency(PAYMENT_FREQUENCIES.monthly)}
                  data-action="select monthly frequency"
                  className={cn({ [selectedFrequency]: paymentFrequency === PAYMENT_FREQUENCIES.monthly })} >
                  <MonthlyIcon alt="calendar with one day per month highlighted" />
                  { messages.paymentFrequencies.monthly }
                </a>
              </li> }
            </ul>
            { paymentFrequency && paymentPlanOptions?.[paymentFrequency] instanceof Array
              && <>
              <h3 className={repaymentSubheading}>{ messages.selectOption }</h3>
              <ul className={cn(optionList, repaymentOptionList)}>
                { paymentPlanOptions[paymentFrequency].map((planOption, i) => {
                  const {
                    perPaymentAmount,
                    planTerm,
                    planScheduleFrequency
                  } = planOption;

                  const numPayments = planTerm * planScheduleFrequency;

                  return <li key={`repayment-option-${accountId}-${paymentFrequency}-${i}`}>
                      <a onClick={() => handleSelectOption(i)}>
                        <div>
                          <strong>{ formatCurrency(perPaymentAmount.standard) }</strong> / { repaymentOptionFrequencyLabels[paymentFrequency] }*
                        </div>
                        <div>
                          <Button data-action={`select payment plan ${i + 1}`} variant="alternate">
                            { numPayments } { formatMessage(messages.payments, { multiplePayments: numPayments !== 1 }) }
                          </Button>
                        </div>
                      </a>
                    </li>;
                }) }
              </ul>
              <p>{ messages.finalPayment }</p>
            </> }
          </>
        : <Spinner variant="large" /> }
    </div>;
};

export default PaymentPlanCreatePage;
