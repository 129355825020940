export const isValidStartDate = (date = new Date()) => new Date(date).getDate() < 29;

export const nextValidStartDate = (date = new Date()) => {
  const result = new Date(date);

  if (isValidStartDate(result) === false) {
    result.setMonth(result.getMonth() + 1);
    result.setDate(1);
  }
  
  return result;
};
