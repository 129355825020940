export default {
  en: {
    title: 'Documents',
    empty: 'You don’t have any documents',
    updated: 'Updated',
    download: 'Download'
  },
  es: {
    title: 'Documentos',
    empty: 'No tiene ningún documento',
    updated: 'Actualizado',
    download: 'Descarga'
  }
};
