import { useEffect } from "react";
import { mixpanel, redirect } from "../utils";
import { MIXPANEL_EVENTS } from "../constants";

let linkTrackingEnabled = false;

const handleClickEvent = e => {
  const { target } = e;
  const a = target instanceof HTMLAnchorElement
    ? target
    : target.closest('a');

  const linkName = a?.getAttribute('data-mp-name');

  if (!linkName) return;

  const href = a.href;
  const location = document.location.href;

  if (href) e.preventDefault();

  mixpanel.track(MIXPANEL_EVENTS.clickLink, { linkName, location, href });

  if (href) redirect(href, true); // allow external links
};

// use MutationObserver to track any new links that get created after the
// initial binding, if the browser supports it

const handleMutation = mutations => {
  for (let m of mutations) {
    if (m.type !== 'childList') continue;

    m.addedNodes
      .forEach(node => {
        if (node instanceof HTMLAnchorElement)
          node.addEventListener('click', handleClickEvent);

        if (node.childNodes.length > 0)
          node.childNodes
            .forEach(c => c.addEventListener('click', handleClickEvent));
      });
  }
};

const observerConfig = { childList: true };

const mo = typeof MutationObserver !== 'undefined'
  ? new MutationObserver(handleMutation)
  : null;

const useMixpanelPageTracking = () => {
  useEffect(() => {
    if (linkTrackingEnabled) return; // prevent double bind
    
    document?.querySelectorAll('a')
      .forEach(a => a.addEventListener('click', handleClickEvent));

    mo?.observe(document, observerConfig);
    
    linkTrackingEnabled = true;

    return () => {
      if (!linkTrackingEnabled) return; // prevent double unbind (mainly perf reasons)
    
      document?.querySelectorAll('a')
        .forEach(a => a.removeEventListener('click', handleClickEvent));

      mo?.disconnect();

      linkTrackingEnabled = false;
    };
  }, []);
};

export default useMixpanelPageTracking;
