// a simple function for conditionally setting class names in HTML and JSX.
// behaves like the popular classnames package, but it performs deduping by
// default and ignores any arg that isn't a string or object

const cn = (...args) => {
  const result = {};

  for (let arg of args) {
    // useMemo can turn arrays into array-like objects with typeof === "object"
    const argType = arg instanceof Array ? 'array' : typeof arg;

    if (arg !== null && argType === 'object') {
      for (let className in arg) {
        // enable the className if its value is truthy
        result[className] = !!arg[className];
      }
    } else if (argType === 'string') {
      // enable every className listed in this whitespace-delimited string arg
      (arg.match(/[^\s]+/g) || []).map(className => {
        result[className] = true;
      });
    } else if (argType === 'array') {
      for (let className of arg) {
        result[className] = true
      }
    }
  }

  // return a space-delimited list of enabled classNames
  // for use as a className prop in a React component or
  // the class attribute of an HTML element

  return Object.keys(result)
    .filter(className => result[className])
    .join(' ');
};

export default cn;
