import React, { useEffect, useRef, useState } from 'react';

import { 
  dot,
  dotActive,
  dotList,
  isInitialStep,
  carouselItem
} from './styles.scss';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Spinner from '../Spinner';

const CustomDot = ({ onClick, active, index, carouselState }) => {
  const { currentSlide } = carouselState;

  return (
    <li className={active ? dotActive : dot}>
      <button onClick={() => onClick()} />
    </li>
  );
};

const CustomCarousel = React.memo(({ className, children, ...props }) => {
  const carouselRef = useRef();
  const [ loading, setLoading ] = useState(true);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 480 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1
    }
  };

  useEffect(() => {
    if (props && props.initialSlide) {
      carouselRef.current.goToSlide(props.initialSlide, true);
      setTimeout(() => setLoading(false), 500);
    } else {
      setLoading(false);
    }
  }, [props]);

  return (
    <Carousel
      responsive={responsive}
      arrows={false}
      className={className}
      showDots
      renderDotsOutside
      dotListClass={dotList}
      customDot={<CustomDot />}
      partialVisible={false}
      itemClass={carouselItem}
      ref={carouselRef}
      {...props}
    >
      {loading ? <Spinner /> : children}
    </Carousel>
  )
});

export default CustomCarousel;
