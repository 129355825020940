const transformDateFunction = (a, b) => {
  return date => {
    const digitGroups = date.match(/\d+/g);
    return digitGroups?.length >= 3
      ? `${digitGroups[2].padStart(4, '0')}-${digitGroups[a].padStart(2, '0')}-${digitGroups[b].padStart(2, '0')}`
      : date;
  };
};

const defaultDateTransform = transformDateFunction(1, 0);

const dateOfBirth = {
  en: transformDateFunction(0, 1), // transform mm/dd/yyyy
  es: defaultDateTransform, // transform dd/mm/yyyy 
};

const splitUpcomingPayment = (paymentStr) => {
  const match = paymentStr.match(/(\d.*)/); // Match everything starting from the first number
  if (match) {
    return [
      paymentStr.slice(0, match.index).trim(), // Before the number
      paymentStr.slice(match.index).trim() // From the first number onward
    ];
  }
  return [paymentStr]; // In case no match found
};

const splitLastPayment = (paymentStr) => {
  const match = paymentStr.match(/(\d{1,2}\sde\s\w+|\w+\s\d{1,2},\s\d{4})/i); // Match the date regardless of format
  if (match) {
    return [
      paymentStr.slice(0, match.index).trim(), // Before the date
      paymentStr.slice(match.index).trim() // The date itself and after
    ];
  }
  return [paymentStr]; // In case no match found
};

export {
  dateOfBirth,
  splitUpcomingPayment,
  splitLastPayment
};
