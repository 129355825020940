import React, { useMemo, useState } from 'react';
import { numDaysInMonth } from '../../utils';

import { customDropdown, dropdownDate } from './styles.scss';
import Datepicker from '../Datepicker';
import Button from '../Button';

import { useLocale } from '../../hooks';
import { ACCOUNT_DATE_FORMAT } from '../../constants';

import DropdownArrow from '../../static/images/icons/DropdownArrow'

const DatepickerDropdown = ({ startDateLimit, selectedDate, changeDateCallback = () => {} }) => {
  const [datepickerOpen, setDatepickerOpen] = useState(false);
  const { formatDate, locale } = useLocale();

  const minDate = new Date();

  const numDays = useMemo(
    () => Math.max(numDaysInMonth() - minDate.getDate(), startDateLimit) - 1,
    [minDate.getDate(), startDateLimit]
  );

  const maxDate = useMemo(
    () => {
      const result = new Date(minDate);
      result.setDate(result.getDate() + numDays);
      return result;
    },
    [minDate, numDays]
  );

  const callChangeDateAndClose = (date) => {
    changeDateCallback(date);
    setDatepickerOpen(false);
  }

  return (
    <div className={customDropdown}>
      <Button
        className={dropdownDate}
        variant={['transparent']}
        onClick={() => setDatepickerOpen(!datepickerOpen)}
      >
        {formatDate(selectedDate.paymentStartDate, ACCOUNT_DATE_FORMAT)}
        <DropdownArrow variant />
      </Button>

      {datepickerOpen && <Datepicker
        initialDate={selectedDate.paymentStartDate}
        onChange={(date) => callChangeDateAndClose(date)}
        minDate={minDate}
        name='paymentStartDate'
        maxDate={maxDate}
      />}
    </div>
  )
};

export default DatepickerDropdown;
