import React, { useMemo, useState } from 'react';

import stepOneSvg from '../../../static/images/icons/stepOne.svg';
import { cn } from '../../../utils';
import { useLocale, useStateView } from '../../../hooks';

import allMessages from '../messages';

import {
  stepper,
  isInitialStep
} from '../../PaymentPlanOfferLandingPage/styles.scss';

import { inputWrapper, informationWrapper } from '../styles.scss';

import { Button, DatepickerDropdown } from '../../../components';
import { paymentPlanView } from '../../../state/views';
import { initialState } from '../../../state/store';
import StepHeader from './StepHeader';

const InitialStep = ({
  settlementOffers,
  referenceNumber,
  handleToggle = () => {},
  handleSelectRepaymentDate = () => {},
  handleContinue = () => {}
}) => {
  const { messages } = useLocale(allMessages);
  const [selectedDate, setSelectedDate] = useState({ paymentStartDate: new Date() });

  const [ paymentPlan ] = useStateView(paymentPlanView);
  
  const startDateLimit = paymentPlan.paymentStartDateLimit || initialState.paymentPlan.paymentStartDateLimit;

  const changeDate = (date) => {
    setSelectedDate({ paymentStartDate: new Date(date) });
    handleSelectRepaymentDate(date);
  }

  return (
    <>
      <StepHeader
        initialStep
        step={stepOneSvg}
        referenceNumber={referenceNumber}
        settlementOffers={settlementOffers}
        handleToggle={handleToggle}
      />

      <div className={informationWrapper}>
        <h2>{messages.initialStep.title}</h2>
        <p>{messages.initialStep.subtitle}</p>

        <div className={inputWrapper}>
          <DatepickerDropdown
            startDateLimit={startDateLimit}
            selectedDate={selectedDate}
            changeDateCallback={(date) => changeDate(date)}
          />
          <Button
            variant={[ 'full', 'large', 'primary' ]}
            onClick={() => handleContinue()}
          >
            {messages.continue}
          </Button>
        </div>
      </div>
    </>
  )
}

export default InitialStep;
