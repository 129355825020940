import React from 'react';

import emptyStateSvg from '../../static/images/images/girl-with-computer.svg';
import { useLocale } from '../../hooks';
import Notice from '../Notice';
import { templateString } from '../../utils';

import { emptyState, wrapper } from './styles.scss';

const EmptyState = ({ telLink }) => {
  const { formatMessage, messages } = useLocale();

  return (
    <div className={emptyState}>
      <div className={wrapper}>
        <img src={emptyStateSvg} alt='create payment plan' />
        <h2>{messages.emptyStatePlan}</h2>
      </div>
      <Notice>{formatMessage(messages.paymentContactNotice, { telLink: <strong>{messages.paymentContactNumber}</strong> })}</Notice>
    </div>
  )
};

export default EmptyState;
