import React from 'react';
import { useLocale, useOnce } from '../../hooks';
import allMessages from './messages';
import { PaymentHistory } from '../../components';
import { mixpanel } from '../../utils';
import { MIXPANEL_EVENTS } from '../../constants';

import { paymentHistoryPage } from './styles.scss';

const PaymentHistoryPage = () => {
  const { messages } = useLocale(allMessages);

  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewPaymentHistoryPage));
  
  return <div className={paymentHistoryPage}>
    <h1>{ messages.title }</h1>
    <PaymentHistory />
  </div>;
};

export default PaymentHistoryPage;
