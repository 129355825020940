import React from 'react';
import Dropdown from '../Dropdown';
import { useStateView, useLocale } from '../../hooks';
import { accountSwitchView, referenceNumbersView, selectedReferenceNumberView } from '../../state/views';

import { accountSelect } from './styles.scss';
import { redirect } from '../../utils';
import { paths } from '../../routes';

const AccountSelect = () => {
  const { messages } = useLocale();
  const [ referenceNumbers ] = useStateView(referenceNumbersView);
  const [ selectedReferenceNumber, setSelectedReferenceNumber ] = useStateView(selectedReferenceNumberView);
  const [ , setAccountSwitch ] = useStateView(accountSwitchView);

  if (!selectedReferenceNumber || referenceNumbers.length === 0) return null;

  const items = referenceNumbers.map(item => ({
    label: `#${item.toString()}`,
    value: item,
    isDefault: item === selectedReferenceNumber
  }));
  
  const handleChange = ({value}) => {
    if (value === selectedReferenceNumber) return;
    setSelectedReferenceNumber(value);
    redirect(paths.home);
    setAccountSwitch(true);
  };

  return <Dropdown
    arrowVariant
    className={accountSelect}
    items={items} 
    outline={false}
    onChange={handleChange}
    dropdownTitle={messages.accounts}
    name="accountSelect"
  />
};

export default AccountSelect; 
