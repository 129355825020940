import { useEffect } from "react";

// a lot of time useEffect with an empty dependency array is used for the wrong
// reasons, so this makes the intent explicit

const useOnce = fn => {
  useEffect(fn, []);
};

export default useOnce;
