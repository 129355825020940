import React from 'react';
import { useLocale, useParams, useStateView } from '../../hooks';
import allMessages from './messages';

import { ErrorPageContent } from '../../components';
import { sessionView, shortLinkRedirectView } from '../../state/views';
import { paths } from '../../routes';

const PaymentPlanErrorPage = () => {
  const [ { authenticated } ] = useStateView(sessionView);
  const [ fullUrl ] = useStateView(shortLinkRedirectView);
  const { reason } = useParams();
  const { messages } = useLocale(allMessages);

  return <ErrorPageContent
    { ...messages }
    action={messages[reason === 'declined' ? 'declined' : 'error']}
    href={authenticated ? paths.paymentPlanSetup : fullUrl} />;
};

export default PaymentPlanErrorPage;
