import React, { useState } from 'react';

import { useLocale, useQuiqChat, useStateView } from '../../hooks';
import { accountView, paymentPlanView } from '../../state/views';
import allMessages from './messages';

import { Button, CurrencyInput, MultipleChoice } from '../../components';
import { partialPaymentPage, errorMessage, exactAmount, partialPayment } from './styles.scss';
import { inputValues, mixpanel, redirect } from '../../utils';
import { paths } from '../../routes';
import { MIXPANEL_EVENTS } from '../../constants';

const percentages = [ 
  { label: '10%', value: .1 },
  { label: '25%', value: .25 },
  { label: '50%', value: .5, isDefault: true },
  { label: '75%', value: .75 },
  { label: '100%', value: 1 }
];

const PaymentPartialPage = () => {
  const { messages } = useLocale(allMessages);

  const [ { accountId, remainingBalance } ] = useStateView(accountView);
  const [ , setPaymentPlan ] = useStateView(paymentPlanView);
  const [ selectedValue, setSelectedValue ] = useState(0);
  const [ formError, setFormError ] = useState(false);

  useQuiqChat({ debtId: accountId });
  
  const handleChangePercentage = percentage => {
    const amount = Math.floor(percentage * remainingBalance * 100) / 100;
    setSelectedValue(amount);

    mixpanel.track(
      MIXPANEL_EVENTS.selectPartialPaymentPercentage,
      { percentage, amount, remainingBalance }
    );
  };

  const getFormError = amount => {
    if (amount > remainingBalance)
      return 1;
    else if (amount <= 1)
      return 2;

    return false;
  };

  const handleSubmit = e => {
    e.preventDefault();
    
    const standardPaymentAmount = parseFloat(inputValues(e.target.elements)['exact-amount']);

    const formError = getFormError(standardPaymentAmount);
    setFormError(formError);
    if (formError) return;
    
    setPaymentPlan.update({
      standardPaymentAmount,
      totalPaymentAmount: standardPaymentAmount
    });

    redirect(paths.paymentPlanSetup);
  };

  return <div className={partialPaymentPage}>
      <h1>{ messages.heading }</h1>
      <section className={partialPayment}>
        <h3>{ messages.subheading }</h3>
        <MultipleChoice name="partial-percentage" items={percentages} onChange={handleChangePercentage} />
        <h3>{ messages.enterAmount }</h3>
        <form className={exactAmount} onSubmit={handleSubmit}>
          <CurrencyInput name="exact-amount" defaultValue={selectedValue} />
          <Button data-action="go to payment setup" type="submit">{ messages.continue }</Button>
        </form>
        <p className={errorMessage}>{ formError && (formError === 1 ? messages.amountTooHigh : messages.amountTooLow) }</p>
      </section>
    </div>;
};

export default PaymentPartialPage;
