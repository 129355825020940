import { useContext, useEffect } from 'react';
import { string } from 'prop-types';

import { mixpanel } from '../../utils';
import { MIXPANEL_AB_TESTING } from '../../constants';
import { ExperimentContext } from './ExperimentProvider';

const Variant = ({ experiment, variant, children }) => {
  const experimentStatus = useContext(ExperimentContext);
  const isVariantActive = experimentStatus[experiment]?.[variant];

  useEffect(() => {
    if (isVariantActive) {
      mixpanel.track(MIXPANEL_AB_TESTING.startExperiment, {
        'Experiment name': experiment,
        'Variant Name': variant
      });
    }
  }, [experiment, variant, isVariantActive]);

  return isVariantActive && children ? children : null;
};

Variant.propTypes = {
  experiment: string.isRequired,
  variant: string.isRequired
};

export default Variant;
