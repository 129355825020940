import React, { useState, useEffect } from 'react';

import { useLocale } from '../../../hooks';

import {
  paymentStepper,
  selected,
  stepper,
  currentStep,
  dropdownBox,
  twoGrid,
  flexBox,
  paymentButton,
  paneRow,
  dayOfWeekDropdown,
  progressBar,
  title,
  sidePannel
} from '../styles.scss';

import allMessages from '../messages';
import DropdownArrow from '../../../static/images/icons/DropdownArrow';
import DatepickerDropdown from '../../../components/DatepickerDropdown';
import useStateView from '../../../hooks/useStateView';
import { paymentPlanView } from '../../../state/views';

import { BankIcon, CardIcon, CloseIcon } from '../../../components/icons';
import { Button, CreditDisclosure, DayOfWeekDropdown, Tab } from '../../../components';
import PlanDetailBox from '../../../components/PlanDetailBox';
import { initialState } from '../../../state/store';
import PaymentFrequencyMessage from '../../../components/PaymentFrequencyMessage';

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import PaymentMethodIframes from '../../../components/PaymentMethodIframes';
import { PAYMENT_FREQUENCIES, PAYMENT_METHODS, PAYMENT_PLAN_TYPES } from '../../../constants';

import rightArrowIcon from '../../../static/images/icons/rightArrow.svg'
import { cn } from '../../../utils';

const SetupPaymentStep = ({
  referenceNumber,
  currentBalance,
  stepperRef,
  submitCallback = () => {},
  progress,
  setProgress
}) => {
  const { formatDate, messages } = useLocale(allMessages);
  const [ paymentPlan, setPaymentPlan ] = useStateView(paymentPlanView);

  const [selectedDate, setSelectedDate] = useState({ paymentStartDate: new Date() });
  const [methodType, setMethodType] = useState('');

  const startDateLimit = paymentPlan.paymentStartDateLimit || initialState.paymentPlan.paymentStartDateLimit;

  const changeDate = (date) => {
    const newStartDate = date ? new Date(date) : new Date();
    const paymentDayOfWeek = newStartDate.getDay();

    setSelectedDate({ paymentStartDate: newStartDate, paymentDayOfWeek: paymentDayOfWeek });
    setPaymentPlan({ ...paymentPlan, paymentStartDate: newStartDate, paymentDayOfWeek: paymentDayOfWeek });
  }

  const handleSelectDayOfWeek = day => {
    const selectedStartDate = new Date();
    selectedStartDate.setDate(selectedStartDate.getDate() + (7 + day.value - selectedStartDate.getDay()) % 7);
    setPaymentPlan.update({ paymentDayOfWeek: day.value, paymentStartDate: selectedStartDate });
  };

  useEffect(() => {
    if (!paymentPlan.paymentStartDate) {
      changeDate();
    }
  }, []);
  
  const isFullPayment = paymentPlan.numPayments === 1 && paymentPlan.planType === PAYMENT_PLAN_TYPES.payInFull;
  let upcomingPayment = '';

  if (isFullPayment) {
    const startDatePayment = formatDate(paymentPlan.paymentStartDate, { month: 'long', day: 'numeric' });
    upcomingPayment = `${messages.upcomingPayment} 1 ${messages.of} ${paymentPlan.numPayments} (${messages.dueOn} ${startDatePayment})`;
  }

  const submitPayment = () => {
    submitCallback();
    setMethodType('');
    setProgress({ ...progress, value: progress.value + 1 });
  };

  const changeMethodType = (methodType) => {
    setMethodType(methodType);
    setProgress({ ...progress, value: progress.value + 1 });
  };

  const resetMethodType = () => {
    setMethodType('');
    setProgress({ ...progress, value: progress.value - 1 });
  }

  return (
    <>
      <progress className={progressBar} value={progress.value/progress.total} />

      <div className={stepper}>
        <h5>{messages.payments}</h5>
        <img src={rightArrowIcon} />
        <h5 className={currentStep}>{messages.setupPayment.title}</h5>
      </div>

      <h2 className={title}>{messages.setupPayment.title}</h2>
      <p>{messages.setupPayment.subtitle}</p>

      <div className={twoGrid}>
        <div className={flexBox}>
          <div className={dropdownBox}>
            <p>{messages.setupPayment.instruction}</p>
            {(paymentPlan.paymentFrequency === PAYMENT_FREQUENCIES.monthly || paymentPlan.numPayments === 1) && <DatepickerDropdown
              startDateLimit={startDateLimit}
              selectedDate={selectedDate}
              changeDateCallback={(date) => changeDate(date)}
            />}
            {[PAYMENT_FREQUENCIES.weekly, PAYMENT_FREQUENCIES.biweekly].includes(paymentPlan.paymentFrequency) && <>
              <DayOfWeekDropdown
                className={dayOfWeekDropdown}
                frequency={paymentPlan.paymentFrequency}
                onChange={handleSelectDayOfWeek}
                fullWidth
              />
            </>}
          </div>

          <Tab group="payment-method" id="ach" onClick={() => changeMethodType(PAYMENT_METHODS.ach)}>
            <Button
              variant={['transparent', 'full']}
              className={methodType === PAYMENT_METHODS.ach ? cn(selected, paymentButton) : paymentButton}
            >
              <BankIcon alt="bank" />
              <p>{ messages.achTabLabel }</p>
            </Button>
          </Tab>

          <Tab group="payment-method" id="card" onClick={() => changeMethodType(PAYMENT_METHODS.card)}>
            <Button
              variant={['transparent', 'full']}
              className={methodType === PAYMENT_METHODS.card ? cn(selected, paymentButton) : paymentButton}
            >
              <CardIcon alt="credit and debit cards" />
              <p>{ messages.cardTabLabel }</p>
            </Button>
          </Tab>
        </div>

        <PlanDetailBox
          referenceNumber={referenceNumber}
          paymentPlan={paymentPlan}
          currentBalance={currentBalance}
          planType={messages.plans[paymentPlan.planType]}
          upcomingPayment={upcomingPayment}
          lastPayment='last payment'
          paymentFrequency={<PaymentFrequencyMessage />}
          isConfirmPage={isFullPayment}
          fullPayment={isFullPayment}
          showFrequency={paymentPlan.planType !== PAYMENT_PLAN_TYPES.paymentPartial}
        />
      </div>

      <CreditDisclosure />

      <SlidingPane
        from="right"
        isOpen={!!methodType}
        onRequestClose={() => resetMethodType('')}
        hideHeader
        className={sidePannel}
        width='500px'
      >
        <div className={paneRow}>
          <h2>{methodType === PAYMENT_METHODS.card ? messages.cardDetails : messages.bankDetails}</h2>
          <Button variant={['transparent']} onClick={() => resetMethodType('')}>
            <CloseIcon />
          </Button>
        </div>
        <PaymentMethodIframes
          accountId={referenceNumber}
          methodType={methodType}
          onSubmit={() => submitPayment()}
        />
      </SlidingPane>
    </>
  )
};

export default SetupPaymentStep;