import React, { useEffect } from 'react';
import { useParams, useStateView } from '../../hooks';
import {
  redirectView,
  shortLinkRedirectView,
} from '../../state/views';
import { mixpanel, redirect, replaceUrl } from '../../utils';
import { getFullUrl } from '../../service/landingPage';
import { Spinner } from '../../components';
import { paths } from '../../routes';
import { MIXPANEL_EVENTS, MIXPANEL_SPLASH_PAGE_NAMES } from '../../constants';
import store from '../../state/store';
import { generatePath } from 'react-router-dom';

const ShortLinkRedirectPage = () => {
  const { ref, shortUrlPath } = useParams();
  const [ , setShortLink ] = useStateView(shortLinkRedirectView);
  const [ , setRedirectUrl ] = useStateView(redirectView);

  useEffect(() => {
    store.clear(false);

    getFullUrl({ ref, shortUrlPath: shortUrlPath.replace(/[^a-z0-9].*$/gi, '') })
      .then(shortLink => {
        setShortLink({
          ...shortLink,
          ref,
          shortUrlPath
        });
        
        let { fullUrlPath, referenceNumber, verified } = shortLink;

        const fullUrlPrefix = fullUrlPath?.match(/^[^/]+/)?.[0];

        let landingPage = 'UNKNOWN';
        switch (fullUrlPrefix) {
          case 'payment-plan':
            landingPage = MIXPANEL_SPLASH_PAGE_NAMES.PPLP;
            break;
          case 'special-offer':
            landingPage = MIXPANEL_SPLASH_PAGE_NAMES.SOLP;
            break;
          case 'pay-now':
            landingPage = MIXPANEL_SPLASH_PAGE_NAMES.FOLP;
            break;
        }
        
        mixpanel.identify(referenceNumber);
        mixpanel.track(
          MIXPANEL_EVENTS.clickedShortLink,
          {
            hashCode: shortUrlPath,
            ref,
            verified,
            landingPage
          }
        );
        
        if (!fullUrlPath) {
          redirect(paths.accountError);
          return;
        }
        
        fullUrlPath = `/${fullUrlPath}`;
        
        if (ref === 'e') {
          redirect(fullUrlPath);
        } else {
          setRedirectUrl(fullUrlPath);
          redirect(generatePath(paths.uniqueDisclosures, { referenceNumber }));
        }
      })
      .catch(() => redirect(paths.specialOfferError));

    setShortLink();
  }, [ ref, shortUrlPath ]);

  return <Spinner variant="large"/>;
};

export default ShortLinkRedirectPage;
