import React from 'react';

import { cn } from '../../../utils';
import { useLocale } from "../../../hooks";
import { Button, PlanDetailBox, RepaymentFrequencyBoxes } from '../../../components';

import allMessages from '../messages';
import {
  stepper,
  planTag,
  webArrow,
  mobileArrow
} from '../styles.scss';

import stepThreeSvg from '../../../static/images/icons/stepThree.svg';
import PaymentFrequencyMessage from '../../../components/PaymentFrequencyMessage';

const SetupCustomPaymentStep = ({ 
  paymentPlan,
  currentBalance,
  referenceNumber,
  proposedFrequency
}) => {
  const { formatMessage, messages } = useLocale(allMessages);

  const {
    paymentFrequency,
    paymentDayOfWeek,
    paymentStartDate,
  } = paymentPlan;

  return (
    <>
      <div className={stepper}>
        <img src={stepThreeSvg} />
      </div>

      <div className={planTag}>{messages.customPlan}</div>
      <h1>{messages.steps.createPlan.title}</h1>
      <p>{messages.steps.createPlan.subtitle}</p>

      <PlanDetailBox
        referenceNumber={referenceNumber}
        paymentPlan={paymentPlan}
        currentBalance={currentBalance}
        planType={messages.customPlan}
        isCustomPlan
        paymentFrequency={<PaymentFrequencyMessage />}
        selectedAmount={paymentPlan.standardPaymentAmount}
      />
      <RepaymentFrequencyBoxes initialFrequency={proposedFrequency} />
    </>
  )
};

export default SetupCustomPaymentStep;