const scrubObject = (obj = {}, emptyValues = [ undefined, null ]) => {
  const result = {};

  for (let key of Object.keys(obj)) {
    const val = obj[key];

    if (emptyValues.includes(val) === false) {
      result[key] = val;
    }
  }

  return result;
};

export { scrubObject };
