import React from 'react';
import TextInput from '../TextInput';
import { func, string } from 'prop-types';

/*
  A fancy TextInput that accepts an additional mask property, a string which allows
  the input to be both filtered and formatted.

  The following characters have a special meaning in the mask string:

  'a' - Accepts any letter, case insensitive
  '0' - Accepts any digit
  '*' - Matches any of the above

  Any other character will be displayed as-is. See the dateOfBirth field in LoginPage/index.js
  for example usage.
*/

const characterMasks = {
  'a': s => /[a-z]/.test(s),
  '0': s => /\d/.test(s),
  '*': s => {
    for (let char in characterMasks) {
      if (char === '*') continue;
      if (characterMasks[char](s)) return true;
    }

    return false;
  }
};

const MaskedInput = ({ mask, onChange = () => {}, ...props }) => {
  const handleChange = e => {
    const value = e.target.value || '';
    let newValue = '';
    
    let valueIndex = 0;
    for (let maskIndex = 0; maskIndex < mask.length && valueIndex < value.length; ++maskIndex) {
      const maskChar = mask[maskIndex];

      if (maskChar in characterMasks) {
        // this is a mask character, find the next
        // matching value character and add it
        while (valueIndex < value.length) {
          const valueChar = value[valueIndex];
          ++valueIndex;

          if (characterMasks[maskChar](valueChar)) {
            newValue += valueChar;
            break;
          }
        }
      } else {
        // this is a display character
        newValue += maskChar;
      }
    }

    e.target.value = newValue;
    onChange(e);
  };

  return <TextInput { ...props } onChange={handleChange}/>;
};

MaskedInput.propTypes = {
  mask: string,
  onChange: func
};

export default MaskedInput;
