export default {
  en: {
    home: 'Home',
    payments: 'Payments',
    paymentHistory: 'Payment History',
    documents: 'Documents',
    referenceNumber: 'Ref. Number',
    disclosures: 'Disclosures',
    logout: 'Log out'
  },
  es: {
    home: 'Inicio',
    payments: 'Pagos',
    paymentHistory: 'Historial de pagos',
    documents: 'Documentos',
    referenceNumber: 'Número de ref.',
    disclosures: 'Divulgaciones',
    logout: 'Cerrar sesión'
  }
};
