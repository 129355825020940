import React, { useMemo } from 'react';
import { array, func, object, string } from 'prop-types';
import { cn } from '../../utils';
import { optionList } from './styles.scss';

const OptionList = ({
  className,
  name,
  onChange = () => {},
  options,
  optionComponent: Option,
  optionBaseProps = {},
  ...props
}) => {
  const getChangeHandler = i => {
    return e => onChange(options[i], e);
  };

  const optionNodes = useMemo(
    () => options.map((option, i) => {
      const optionProps = { ...optionBaseProps, ...option };

      const id = `${name}-input-${i}`;

      return <li key={id}>
          <input type="radio" id={id} name={name} onChange={getChangeHandler(i)} />
          <label htmlFor={id}><Option { ...optionProps }/></label>
        </li>;
    }),
    [ options ]
  );

  return <ul { ...props } className={cn(optionList, className)}>{ optionNodes }</ul>;
};

OptionList.propTypes = {
  className: string,
  name: string.isRequired,
  onChange: func,
  options: array.isRequired,
  optionBaseProps: object,
  optionComponent: func.isRequired
};

export default OptionList;
