import React from 'react';
import { arrayOf, oneOfType, string } from 'prop-types';
import { cn } from '../../utils';
import { spinner, spinnerAlternate, spinnerLarge, spinnerSmall } from './styles.scss';
import { useStyleVariants } from '../../hooks';

const variants = {
  alternate: spinnerAlternate,
  large: spinnerLarge,
  small: spinnerSmall
};

const Spinner = ({ variant, className = '' }) => {
  const classNames = useStyleVariants(variants, variant);
  return <div className={cn(spinner, classNames, className)}></div>;
};

Spinner.propTypes = {
  variant: oneOfType([string, arrayOf(string)]),
  className: string,
};

export default Spinner;
