export default {
  en: {
    noPaymentsYet: 'No payments yet',
    paid: 'You have paid',
    payInFull: 'Pay full balance',
    viewPaymentPlans: 'View payment plans',
    startPlan: 'Start your plan!',
  },
  es: {
    noPaymentsYet: 'Todavía no hay pagos',
    paid: 'Ha pagado',
    payInFull: 'Pague en su totalidad',
    viewPaymentPlans: 'Ver planes de pago',
    startPlan: '¡Comience su plan!',
  }
}