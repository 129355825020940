import React, { useEffect, useMemo, useState } from 'react';

import { Button, CurrencyInput, Datepicker, RepaymentDateDropdown, Variant, DatepickerDropdown } from "../../../components";

import { cn, formatCurrency, numDaysInMonth } from '../../../utils';
import { useLocale, useStateView } from "../../../hooks";

import allMessages from '../messages';
import {
  stepper,
  dropdownDate,
  paymentDatePicker,
  leftInfo,
  rightInfo,
  reversed,
  amountError,
  error,
  planTag,
  webArrow,
  mobileArrow,
  customDropdown,
  continueButton,
  withoutInput
} from '../styles.scss';

import stepTwoSvg from '../../../static/images/icons/stepTwo.svg';
import BackArrow from '../../../static/images/icons/BackArrow';
import DropdownArrow from '../../../static/images/icons/DropdownArrow';

import { PAYMENT_FREQUENCIES, PAYMENT_PLAN_TYPES, ACCOUNT_DATE_FORMAT } from '../../../constants';
import { initialState } from '../../../state/store';
import { paymentPlanView, selectedPlanTypeView } from '../../../state/views';

const ProposePlanStep = ({
  defaultSpecialPlan,
  paymentPlan,
  proposedAmount,
  referenceNumber,
  handleSubmitPlan
}) => {
  const { formatMessage, formatDate, messages } = useLocale(allMessages);
  const [selectedAmount, setSelectedAmount] = useState(0);

  const [selectedDate, setSelectedDate] = useState({ paymentStartDate: new Date() });
  const [ , setPaymentPlan ] = useStateView(paymentPlanView);
  const [ selectedPlanType ] = useStateView(selectedPlanTypeView);

  const startDateLimit = paymentPlan.paymentStartDateLimit || initialState.paymentPlan.paymentStartDateLimit;

  const getDateObject = (date = undefined) => {
    const newStartDate = date ? new Date(date) : new Date();
    const paymentDayOfWeek = newStartDate.getDay();

    const dateObject = { paymentStartDate: newStartDate, paymentDayOfWeek: paymentDayOfWeek };
    setSelectedDate(dateObject);
    return dateObject;
  }

  const changeDate = (date) => {
    const dateObject = getDateObject(date);
    setPaymentPlan({ ...paymentPlan, ...dateObject });
  };

  useEffect(() => {
    if (!selectedDate) {
      setSelectedDate(getDateObject());
    }
  }, []);

  const isCustomPlan = !paymentPlan.numPayments || paymentPlan.numPayments > 1;

  const title = isCustomPlan ? messages.steps.createPlan.title : messages.steps.payInFull.title;
  const subtitle = isCustomPlan ? messages.steps.createPlan.instructions : messages.steps.payInFull.subtitle;

  return (
    <>
      <div className={stepper}>
        <img src={stepTwoSvg} />
      </div>

      <div className={planTag}>{messages[selectedPlanType]}</div>
      <h1>{title}</h1>
      <p>{subtitle}</p>

      <div className={cn(paymentDatePicker, reversed)}>
        <div className={rightInfo}>
          <h3>#{referenceNumber}</h3>
          <h1>
            <span>$</span>
            {formatCurrency(defaultSpecialPlan.totalPaymentAmount, '', true)}
          </h1>
          <h3>{messages.currentBalance}</h3>
        </div>

        <div className={cn(leftInfo, isCustomPlan ? '' : withoutInput)}>
          {isCustomPlan &&
            <CurrencyInput
              className={proposedAmount && !paymentPlan.proposedAmountApproved ? error : ''}
              name="proposedAmount"
              onChange={(e) => setSelectedAmount(e.target.value)}
              defaultValue={0}
            />
          }

          {selectedDate &&
            <DatepickerDropdown
              startDateLimit={startDateLimit}
              selectedDate={selectedDate}
              changeDateCallback={(date) => changeDate(date)}
            />
          }

          <Button
            variant={[ 'full', 'large', 'primary' ]}
            data-action="submit proposed amount"
            className={isCustomPlan ? '' : continueButton}
            onClick={() => handleSubmitPlan(selectedAmount, selectedDate)}
          >
            {isCustomPlan ? messages.steps.createPlan.submit : messages.steps.payInFull.submit}
          </Button>

          {proposedAmount && !paymentPlan.proposedAmountApproved && (
            <>
              <p className={amountError} data-action="proposed plan error">
                {proposedAmount >= defaultSpecialPlan.totalPaymentAmount
                  ? messages.steps.createPlan.proposedAmountTooHigh
                  : messages.steps.createPlan.proposedAmountTooLow}
              </p>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ProposePlanStep;