import React, { useEffect, useMemo, useRef } from 'react';
import { any, bool, func, number, string } from 'prop-types';

import { toggle, toggleItem } from './styles.scss';
import { cn } from '../../utils';

// an incremental id is a reliable solution when you need a unique id but don't want
// to require that the user provide one
let nextId = 0;

const Toggle = ({
  children,
  className,
  name = `toggle-${nextId++}`,
  onChange = () => {},
  ...props
}) => {
  const handleChange = e => {
    if (e.target.name !== name || !e.target.checked) return; 
    // TODO mixpanel event
    onChange(e);
  };

  // get Toggle.Item children, and inject the id and index into their props

  // the children prop isn't a true Array and can even be a single node, but React
  // provides some utility functions we can use to perform some Array-like operations
  let i = 0;
  const mappedChildren = React.Children.map(
    children,
    child => child.type === Toggle.Item
      ? React.cloneElement(child, { toggleName: name, index: i++ })
      : child
  );

  return <div onChange={handleChange} className={cn(toggle, className)} { ...props }>{ mappedChildren }</div>;
};

Toggle.propTypes = {
  name: string,
  onChange: func
};

// some React component libraries like to define components this way if the component
// is intended to be used only with its "parent". it's convenient because we only need
// to export/import it once, and if someone wants to just write <Item> in their jsx
// they can destructure the Item component function from Toggle themselves

Toggle.Item = ({
  children,
  className,
  active,
  index,
  toggleName,
  toggleChangeHandler = () => {},
  value,
  ...props
}) => {
    const inputRef = useRef(null);

    // useMemo can give a nice boost in performance and not just for super expensive
    // calculations; we should probably be using it more often
    const id = useMemo(
      () => `${toggleName}-${index}`,
      [ toggleName, index ]
    );

    return <>
        <input ref={inputRef} type="radio" id={id} name={toggleName} value={value} defaultChecked={active}/>
        <label htmlFor={id} className={cn(toggleItem, className)} { ...props }>
          { children }
        </label>
      </>;
}

Toggle.Item.propTypes = {
  active: bool,
  index: number,
  toggleName: string,
  toggleChangeHandler: func,
  value: any
};

export default Toggle;
