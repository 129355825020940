import React from 'react';
import { Link } from 'react-router-dom';

import headerLogoSrc from '../../static/images/icons/logoIcon.svg';
import headerSmallLogoSrc from '../../static/images/icons/logoMinIcon.svg';
import LanguageSelect from '../LanguageSelect';
import { paths } from '../../routes';

import { header, logo, menu, mobileLogo, tabletLogo } from './styles.scss';

import menuSrc from '../../static/images/icons/menu.svg';
import Button from '../Button';

import { useStateView } from '../../hooks';
import { menuToggledView } from '../../state/views';
import { cn } from '../../utils';

const Header = ({ isSplashScreen = false }) => {
  const [ menuToggled, setMenuToggled ] = useStateView(menuToggledView);

  return (
    <>
      <div className={header}>
        {isSplashScreen ? 
          <Link className={mobileLogo} data-mp-name="Header Logo" to={paths.home}>
            <img src={headerSmallLogoSrc} />
          </Link>
          : <Button id='menu' onClick={() => setMenuToggled(!menuToggled)}>
            <img src={menuSrc} className={menu} />
          </Button>
        }
        <Link data-mp-name="Header Logo" to={paths.home}>
          <img src={headerLogoSrc} className={cn(logo, isSplashScreen ? tabletLogo : null)} />
        </Link>
        <LanguageSelect />
      </div>
    </>
  );
}

export default Header;