export const leftPad = (s, length, char = '0') => {
  while (s.length < length) {
    s = char + s;
  }

  return s;
};

export const rightPad = (s, length, char = '0') => {
  while (s.length < length) {
    s = s + char;
  }

  return s;
};
