export default {
  en: {
    mostChosen: 'Proposed',
    paymentPlan: 'Payment Plan',
    title: 'Payment plan to resolve your balance',
    goBack: 'Go Back',
    customPlan: 'Custom Plan',
    currentBalance: 'Current Balance',
    referenceNumber: 'Reference Number',
    payInFullPlan: 'Pay in Full',
    fullPaymentTitle: 'Pay full to resolve your balance',
    steps: {
      initial: {
        planIntro: 'Based on your current balance, we\'ve created this repayment plan for you',
        instructions: 'If this plan works for you, click {acceptButtonText} to schedule your payments. If not, click {createButtonText} to offer a plan that works for you.',
        acceptDefaultPlan: 'Accept payment plan',
        createPlan: 'Create my own plan',
        payInFull: 'Pay Full Balance',
      },
      createPlan: {
        title: 'Create your payment plan',
        instructions: 'Enter the amount you want to pay every month and then select the date for your first payment',
        submit: 'Make Offer',
        proposedAmountTooHigh: 'The proposed amount is too high. Please enter a lower amount',
        proposedAmountTooLow: 'The proposed amount is too low. Please enter a higher amount',
        subtitle: 'Review the details below and proceed to payment'
      },
      paymentDate: {
        title: 'Congratulations!',
        instructions: 'Pick the date of your first payment',
        subtitle: 'Select your date and frequency for your payment',
      },
      paymentMethod: {
        subtitle: 'Choose your payment method'
      },
      acceptCustomPlan: {
        title: 'Your plan is set!',
        subtitle: 'Review the details below and proceed to payment',
        button: 'Select Payment Method'
      },
      paymentConfirm: {
        heading: {
          confirmPayment: 'Confirm and pay',
          confirmPaymentMethod: 'Confirm Your Payment Method',
          confirmPaymentPlan: 'Confirm Your Payment Plan'
        },
        submit: 'Authorize'
      },
      payInFull: {
        title: 'Complete your full payment',
        subtitle: 'Select the date for your payment',
        instructions: 'Review the details below and proceed to payment',
        submit: 'Continue'
      }
    },
    copyrightNotice: '© 2024 Spring Oaks Capital, LLC, All Rights Reserved.',
    choose: 'OR'
  },
  es: {
    mostChosen: 'Propuesto',
    paymentPlan: 'Plan de pago',
    title: 'Plan de pago para resolver su saldo',
    goBack: 'Volver',
    customPlan: 'Plan Personalizado',
    currentBalance: 'Saldo actual',
    referenceNumber: 'Número de referencia',
    payInFullPlan: 'Pague en su totalidad',
    fullPaymentTitle: 'Pague su totalidad para resolver su saldo',
    steps: {
      initial: {
        planIntro: 'En función de su saldo actual, hemos creado este plan de pago para usted:',
        instructions: 'Si este plan le funciona, haga clic en {acceptButtonText} para programar sus pagos. Si no es así, haga clic en {createButtonText} a fin de ofrecer un plan que funcione para usted.',
        acceptDefaultPlan: 'Aceptar el plan de pago',
        createPlan: 'Crear mi propio plan',
        payInFull: 'Pague en su totalidad',
      },
      createPlan: {
        title: 'Cree su plan de pago',
        instructions: 'Ingrese la cantidad que desea pagar cada mes y luego seleccione la fecha para su primer pago.',
        submit: 'Volver a calcular',
        proposedAmountTooHigh: 'El importe propuesto es demasiado alto. Ingrese un pago más bajo.',
        proposedAmountTooLow: 'El importe propuesto es demasiado bajo. Ingrese un pago más alto.',
        subtitle: 'Revise los detalles a continuación y proceda al pago.'
      },
      paymentDate: {
        title: '¡Felicitaciones!',
        instructions: 'Seleccione la fecha de su pago:',
        subtitle: 'Seleccione la fecha y la frecuencia de su pago',
      },
      paymentMethod: {
        subtitle: 'Elija su método de pago'
      },
      acceptCustomPlan: {
        title: 'Su plan está fijado!',
        subtitle: 'Revise los detalles a continuación y proceda al pago.',
        button: 'Seleccione el método de pago'
      },
      paymentConfirm: {
        heading: {
          confirmPayment: 'Confirme su pago',
          confirmPaymentMethod: 'Confirme su método de pago',
          confirmPaymentPlan: 'Confirme su plan de pago'
        },
        submit: 'Autorizar'
      },
      payInFull: {
        title: 'Complete su pago total',
        subtitle: 'Seleccione la fecha de su pago',
        instructions: 'Revise los detalles a continuación y proceda al pago.',
        submit: 'Continuar'
      }
    },
    copyrightNotice: '© 2024 Spring Oaks Capital, LLC, Todos los derechos reservados.',
    choose: 'O'
  }
};
