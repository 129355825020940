import debugConsole from './debugConsole';

const defaultRead = () => undefined;
const defaultWrite = o => o;
const defaultKey = o => JSON.stringify(o);

let nextId = 1;

export default class Store {
  constructor({ initialState = {}, storageKey = `state-store-${nextId++}`, persist = defaultWrite }) {
    this.storageKey = storageKey;
    this.persist = persist;
    this.initialState = { ...initialState };
    this.state = {
      ...initialState,
      ...(JSON.parse(sessionStorage.getItem(this.storageKey)) || {})
    };
    this.views = [];
  }

  clear(notify = true) {
    // reset state to initial values, optionally notify listeners
    this.state = { ...this.initialState };
    sessionStorage.setItem(this.storageKey, JSON.stringify(this.state));

    debugConsole.log(`State store ${this.storageKey} reset to initial state`);

    if (notify) {
      this.notifyViews();
    }
  }

  createView({ name = 'view', read = defaultRead, write = defaultWrite, getKey = defaultKey }) {
    return {
      store: this,
      read: (stateObject = this.state) => {
        const state = read(stateObject);
        const key = getKey(state);

        return { state, key };
      },
      write: async newState => {
        if (newState instanceof Promise) newState = await newState;
        
        const oldState = this.state;
        const incomingChange = write(newState);

        this.state = {
          ...this.state,
          ...incomingChange
        };

        const stateToPersist = this.persist(this.state);
        sessionStorage.setItem(this.storageKey, JSON.stringify(stateToPersist));

        const timestamp = new Date().toTimeString().split(' ')[0] + '.' + (Date.now() % 1000);
        debugConsole.groupCollapsed(`${timestamp} state update via ${name}`);
        debugConsole.log('Incoming change:', incomingChange);
        debugConsole.log('Previous state:', oldState);
        debugConsole.log('Current state:', this.state);
        debugConsole.groupEnd();

        this.notifyViews();
      }
    };
  }

  subscribeView(view, callback) {
    if (!this.views.find(v => v.view === view && v.callback === callback)) {
      this.views.push({ view, callback });
    }
  }

  unsubscribeView(view, callback) {
    const i = this.views.findIndex(v => v.view === view && v.callback === callback);

    if (i > -1) {
      this.views.splice(i, 1);
    }
  }

  notifyViews() {
    for (const { callback } of this.views) {
      callback();
    }
  }
};
