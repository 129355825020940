import React from 'react';
import { number, string } from 'prop-types';
import { settlementOption } from './styles.scss';
import { useLocale } from '../../hooks';
import { formatCurrency } from '../../utils';

const SettlementOfferOption = ({ standardPaymentAmount, numPayments }) => {
  const { messages, formatMessage } = useLocale();

  return <div className={settlementOption}>
    <p>
      <strong>{ formatCurrency(standardPaymentAmount) }</strong>
      { ' / ' }
      { messages.paymentFrequencies.monthly }
    </p>
    <div>
      { numPayments }{ ' ' }
      { formatMessage(messages.payments, { multiplePayments: numPayments !== 1 }) }
    </div>
  </div>;
}

SettlementOfferOption.propTypes = {
  standardPaymentAmount: string.isRequired,
  numPayments: number.isRequired
};

export default SettlementOfferOption;
