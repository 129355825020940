import React, { useEffect, useMemo, useState } from 'react';
import { Button, DatepickerDropdown, RepaymentDateDropdown, RepaymentFrequencyBoxes, Variant } from '../../../components';

import stepTwoSvg from '../../../static/images/icons/stepTwo.svg';

import { cn, formatCurrency } from '../../../utils';
import { useLocale, useStateView } from '../../../hooks';

import allMessages from '../messages';

import {
  stepper,
  hasBackArrow,
  planTag,
  paymentDatePicker,
  leftInfo,
  rightInfo,
  dropdownDate,
  webArrow,
  mobileArrow
} from '../styles.scss'

import { initialState } from '../../../state/store';
import { paymentPlanView } from '../../../state/views';
import { PAYMENT_FREQUENCIES, PAYMENT_PLAN_TYPES } from '../../../constants';

const SetupPaymentStep = ({
  paymentPlan,
  referenceNumber,
  handleFrequencyChange,
  proposedFrequency
}) => {
  const { formatMessage, messages } = useLocale(allMessages);
  const [ , setPaymentPlan ] = useStateView(paymentPlanView);

  const [selectedDate, setSelectedDate] = useState({ paymentStartDate: new Date() });
  const startDateLimit = paymentPlan.paymentStartDateLimit || initialState.paymentPlan.paymentStartDateLimit;

  const changeDate = (date) => {
    const newStartDate = date ? new Date(date) : new Date();
    const paymentDayOfWeek = newStartDate.getDay();

    setSelectedDate({ paymentStartDate: newStartDate, paymentDayOfWeek: paymentDayOfWeek });
    setPaymentPlan({ ...paymentPlan, paymentStartDate: newStartDate, paymentDayOfWeek: paymentDayOfWeek });
  }

  useEffect(() => {
    if (!paymentPlan.paymentStartDate) {
      changeDate();
    }
  }, []);

  return (
    <>
      <div className={stepper}>
        <img src={stepTwoSvg} />
      </div>
      <div className={planTag}>{messages.paymentPlan}</div>
      <h1>{messages.title}</h1>
      <p>{messages.steps.paymentDate.subtitle}</p>

      <div className={paymentDatePicker}>
        <div className={leftInfo}>
          <p>{messages.steps.paymentDate.instructions}</p>
          <DatepickerDropdown
            startDateLimit={startDateLimit}
            selectedDate={selectedDate}
            changeDateCallback={(date) => changeDate(date)}
          />
        </div>
        <div className={rightInfo}>
          <h3>#{referenceNumber}</h3>
          <h1>
            <span>$</span>
            {formatCurrency(paymentPlan.totalPaymentAmount, '', true)}
          </h1>
          <h3>{messages.currentBalance}</h3>
        </div>
      </div>
      <RepaymentFrequencyBoxes initialFrequency={proposedFrequency} />
    </>
  )
};

export default SetupPaymentStep;