import axios from 'axios';
import { httpClient } from '../utils';

export const getIPv4 = () => axios.get(process.env.IPV4_ENDPOINT);
export const getAccounts = accountIds => httpClient.post('/accounts', { accountIds });
export const getAccount = accountId => getAccounts([ accountId ]);
export const getDocuments = accountId => httpClient.post('/documents', { accountId });
export const getDocument = ({ accountId, documentId }) => httpClient.post('/document', { accountId, documentId });
export const getDisclosures = accountId => httpClient.post('/disclosures', { debtID: accountId });
export const login = ({ ssnLastFour, dateOfBirth, lastName, referenceNumber }) =>
  httpClient.post('/login', { ssnLastFour, dateOfBirth, lastName, referenceNumber });
export const verifyIdentity = ({ ssnLastFour, dateOfBirth, fullUrl, shortUrl, ref, debtId }) =>
  httpClient.post('/verify-identity', { ssnLastFour, dateOfBirth, debtId, fullUrl, shortUrl, ref });
