// extracted by mini-css-extract-plugin
var _1 = "rzP36YfNzgU34zCQAVzq";
var _2 = "noZirGsBR89rNeFKQfCn";
var _3 = "_y0kKZU9RJ0T1CKpbYKN";
var _4 = "e9OewDIgQNklZ3sAeWZ4";
var _5 = "YnjKNnnsXGQoYLHgVpQ8";
var _6 = "aNV_rIuKcegOb7gXwFW1";
var _7 = "Pld7pmRa_MMP794MTbL0";
var _8 = "iCS79PHzkQtYZb56_0XY";
var _9 = "rIyAlMKWP3cXSYlUXeNS";
var _a = "ZTOGWayIGfKf9zeJDM0g";
var _b = "ftmmhO6LL0BoC6RzgocV";
var _c = "UKvxGe3uGecZ8jZTu35z";
export { _1 as "alignRight", _2 as "balance", _3 as "confirmInfo", _4 as "detailBox", _5 as "greyed", _6 as "infoRow", _7 as "mobileDisplay", _8 as "numberPayments", _9 as "planTypeTag", _a as "separator", _b as "webDisplay", _c as "wrapper" }
