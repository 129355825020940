import paths from './paths';
import {
  HomePage,
  LoginPage,
  VersionPage,
  NotFoundPage,

  SelectAccountPage,
  AccountErrorPage,
  DocumentsPage,
  PaymentHistoryPage,
  
  DisclosuresPage,
  
  PaymentPlanPage,
  PaymentPartialPage,
  PaymentPlanCreatePage,
  PaymentPlanSetupPage,
  PaymentPlanErrorPage,
  
  SettlementOfferLandingPage,
  PaymentPlanOfferLandingPage,
  PaymentConfirmPage,
  PaymentSuccessPage,
  SpecialOfferErrorPage,
  FrictionlessLandingPage,

  ShortLinkRedirectPage
} from '../pages';

// these will probably be evaluated in order, so for paths with params to be
// routed correctly, keep specificity in mind when ordering routes here

export default [
  // top level page routes
  {
    path: paths.home,
    component: HomePage,
    requireAuth: true
  },
  {
    path: paths.login,
    component: LoginPage,
    hideNav: true
  },
  {
    path: paths.version,
    component: VersionPage
  },
  {
    path: paths.notFound,
    component: NotFoundPage
  },
  {
    path: '*', // no specificity issue here, '*' is special
    component: NotFoundPage
  },

  // account page routes
  {
    path: paths.selectAccount,
    component: SelectAccountPage,
    hideNav: true
  },
  {
    path: paths.accountError,
    component: AccountErrorPage
  },
  {
    path: paths.documents,
    component: DocumentsPage,
    requireAuth: true
  },
  {
    path: paths.paymentHistory,
    component: PaymentHistoryPage,
    requireAuth: true
  },

  // disclosures page routes
  {
    path: paths.disclosures,
    component: DisclosuresPage,
    requireAuth: false
  },
  {
    path: paths.homeDisclosures,
    component: DisclosuresPage,
    requireAuth: true,
    hideNav: true
  },
  {
    path: paths.uniqueDisclosures,
    component: DisclosuresPage,
    requireAuth: false,
  },

  // payment plan page routes
  {
    path: paths.paymentPlan,
    component: PaymentPlanPage,
    requireAuth: true
  },
  {
    path: paths.paymentPartial,
    component: PaymentPartialPage,
    requireAuth: true
  },
  {
    path: paths.paymentPlanSetup,
    component: PaymentPlanSetupPage,
    requireAuth: true
  },
  {
    path: paths.paymentPlanCreate,
    component: PaymentPlanCreatePage,
    requireAuth: true
  },
  {
    path: paths.paymentPlanError,
    component: PaymentPlanErrorPage
  },
  {
    path: paths.paymentConfirm,
    component: PaymentConfirmPage
  },
  {
    path: paths.paymentSuccess,
    component: PaymentSuccessPage
  },

  // special offer page routes
  {
    path: paths.settlementOfferLandingPage,
    component: SettlementOfferLandingPage,
    hideNav: true
  },
  {
    path: paths.paymentPlanOfferLandingPage,
    component: PaymentPlanOfferLandingPage,
    hideNav: true
  },
  {
    path: paths.specialOfferError,
    component: SpecialOfferErrorPage,
    hideNav: true
  },
  {
    path: paths.frictionlessLandingPage,
    component: FrictionlessLandingPage,
    hideNav: true
  },

  // shortlink goes last so the param matching doesn't clobber other rules
  {
    path: paths.shortLink,
    component: ShortLinkRedirectPage,
    hideNav: true
  }
];
