// get values of all inputs belonging to this form
// and return them as an object { inputName: inputValue }
const inputValues = (elements, scrubEmptyFields) => {
  const formInputs = Array.from(elements);
  const result = {};

  for (let input of formInputs) {
    const { checked, name, type, value } = input;

    let finalValue = '';
    
    switch (type) {
      case 'button':
      case 'submit':
        // ignore buttons
        break;
      case 'checkbox':
        finalValue = checked;
        break;
      case 'radio':
        // discard values of unchecked radio buttons
        if (!checked) continue;
        // fall through
      default:
        finalValue = value;
        break;
    }

    // remove blank fields if scrub is truthy
    if (!scrubEmptyFields || finalValue !== '') {
      result[name] = finalValue;
    }
  }

  return result;
};

const formEventValues = (e, scrubEmptyFields = true) =>
  inputValues(e.target.elements, scrubEmptyFields);

export {
  formEventValues,
  inputValues
};
