const generateQueryString = params => {
  let result = '';
  let prefix = '?';
  
  for (let key in params) {
    result += `${prefix}${key}=${encodeURIComponent(params[key])}`;
    prefix = '&';
  }

  return result;
};

const getQueryParams = (url = window?.location?.href) => {
  const location = new URL(url);
  const queryString = location?.search;
  const queryParams = {};

  if (queryString) {
    const keyValuePairs = queryString.substring(1).split('&');

    for (const pair of keyValuePairs) {
      const kv = pair.split('=');
      queryParams[kv[0]] = decodeURIComponent(kv[1]);
    }
  }

  return queryParams;
};

const mergeQueryParams = (newParams, url = window?.location?.href) => {
  const loc = new URL(url);
  const mergedParams = { ...getQueryParams(url), ...newParams };
  const queryString = generateQueryString(mergedParams);

  return new URL(`${loc.origin}${loc.pathname}${queryString}${loc.hash}`);
};

export { generateQueryString, getQueryParams, mergeQueryParams };
