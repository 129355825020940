import React from 'react';
import { string, node } from 'prop-types';
import { Link } from 'react-router-dom';

import headerLogoSrc from '../../static/images/icons/logoIcon.svg';
import headerSmallLogoSrc from '../../static/images/icons/logoMinIcon.svg';

import { paths } from '../../routes';

import {
  wrapper,
  leftPannel,
  logo,
  leftPannelImgClass,
  rightPannel,
  logoWrapper,
  logoSmall
} from './styles.scss';
import LanguageSelect from '../LanguageSelect';
import { useStateView } from '../../hooks';
import { selectedReferenceNumberView } from '../../state/views';

const SidePannels = ({ leftPannelHeader, rightPannelHeader, children, leftPannelImage }) => {
  const [ selectedReferenceNumber ] = useStateView(selectedReferenceNumberView);

  return (
    <div className={wrapper}>
      <div className={leftPannel}>
        <Link data-mp-name="Header Logo" to={selectedReferenceNumber ? paths.home : {}}>
          <img className={logo} src={headerLogoSrc} />
        </Link>
        <h4>{leftPannelHeader}</h4>
        <img className={leftPannelImgClass} src={leftPannelImage} />
      </div>
      <div className={rightPannel}>
        <div className={logoWrapper}>
          <Link data-mp-name="Header Logo" to={paths.home}>
            <img className={logo} src={headerLogoSrc} />
            <img className={logoSmall} src={headerSmallLogoSrc} />
          </Link>
          <LanguageSelect />
        </div>
        <h4>{rightPannelHeader}</h4>
        {children}
      </div>
    </div>
  )
};

SidePannels.propTypes = {
  leftPannelHeader: string,
  rightPannelHeader: string,
  children: node
};

export default SidePannels;
