import { Children } from 'react';
import { string } from 'prop-types';
import Tab from '../Tab';
import TabContent from '../TabContent';

const addChildToGroup = (child, group, id) => {
  child.props = child.props || {};
  child.props.group = group;
  child.props.id = id.toString();
  child.props.key = `${group}-${id}`;
}

const TabGroup = ({ group, children }) => {
  const childrenArray = Children.toArray(children);

  let nextTabId = 0;
  let nextGroupId = 0;

  return childrenArray.map(child => {
    if (child.type === Tab) {
      addChildToGroup(child, group, nextTabId++);
    } else if (child.type === TabContent) {
      addChildToGroup(child, group, nextGroupId++);
    }

    return child;
  });
};

TabGroup.propTypes = {
  group: string.isRequired
};

export default TabGroup;
