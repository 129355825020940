import React from 'react';
import ResumedPaymentSummary from '../ResumedPaymentSummary';

import { detailBox, wrapper, infoRow, separator, planTypeTag, balance, webDisplay, mobileDisplay, alignRight } from './styles.scss';
import { cn, formatCurrency, splitLastPayment, splitUpcomingPayment } from '../../utils';
import { useLocale, useStateView } from '../../hooks';
import { PAYMENT_PLAN_TYPES } from '../../constants';
import { selectedStepView, splashPageView } from '../../state/views';

const PlanDetailBox = ({
  referenceNumber,
  planType,
  paymentPlan,
  currentBalance,
  upcomingPayment,
  lastPayment,
  paymentFrequency,
  isSuccessPage,
  isCustomPlan = false,
  selectedAmount,
  fullPayment,
  withCurrentBalance,
  isConfirmPage,
  showFrequency,
  telLink
}) => {
  const { messages } = useLocale();
  const [ comesFromSplash ] = useStateView(splashPageView);
  const [ currentStep ] = useStateView(selectedStepView);

  const isPartialPayment = paymentPlan.planType === PAYMENT_PLAN_TYPES.paymentPartial && (isConfirmPage || isSuccessPage);
  const showCurrentBalance = (!fullPayment || fullPayment && withCurrentBalance) && !isPartialPayment && currentBalance;
  const showReferenceNumber = !!referenceNumber;
  const hasPaymentsMessages = upcomingPayment && lastPayment;

  const fullPaymentTitle = currentStep !== 'setup' ? messages.fullBalance : messages.payInFull;

  return (
    <div className={detailBox}>
      <div className={wrapper}>
        {showReferenceNumber ? 
          <>
            <div className={infoRow}>
              <h5>#{referenceNumber}</h5>
              <span className={planTypeTag}>{planType}</span>
            </div>
            <h2>{fullPayment ? fullPaymentTitle : messages.planDetail}</h2>
          </> : <>
            <div className={cn(separator, infoRow)}>
              <h2>{messages.planDetail}</h2>
              <span className={planTypeTag}>{planType}</span>
            </div>
          </>
        }

        {showCurrentBalance && (
          <div className={cn(infoRow, hasPaymentsMessages ? null : separator)}>
            <h5>{messages.currentBalance}</h5>
            <div className={balance}>
              <span>$ <strong>{formatCurrency(currentBalance, '', true)}</strong></span>
            </div>
          </div>
        )}

        {hasPaymentsMessages && (
          <>
            <div className={cn(infoRow, separator, webDisplay)}>
              <h5>{fullPayment ? ((isSuccessPage || isConfirmPage) ? upcomingPayment : splitUpcomingPayment(upcomingPayment)[0] ) : upcomingPayment}</h5>
              {(isConfirmPage || isSuccessPage) && (fullPayment || paymentPlan.planType === PAYMENT_PLAN_TYPES.paymentPartial) ?
                <div className={balance}>
                  <span>$ <strong>{formatCurrency(currentBalance, '', true)}</strong></span>
                </div>
                : <h5 className={alignRight}>{fullPayment ? splitUpcomingPayment(upcomingPayment)[1] : lastPayment}</h5>
              }
            </div>
            <div className={cn(infoRow, mobileDisplay, fullPayment || isPartialPayment ? separator : null)}>
              <h5>{splitUpcomingPayment(upcomingPayment)[0]}</h5>
              <h5 className={alignRight}><strong>{splitUpcomingPayment(upcomingPayment)[1]}</strong></h5>
            </div>
            {!fullPayment && !isPartialPayment &&
              <div className={cn(infoRow, mobileDisplay, separator)}>
                <h5>{splitLastPayment(lastPayment)[0]}</h5>
                <h5><strong>{splitLastPayment(lastPayment)[1]}</strong></h5>
              </div>
            }
          </>
        )}
      </div>

      <ResumedPaymentSummary
        paymentPlan={paymentPlan}
        frequencyMessage={paymentFrequency}
        isSuccessPage={isSuccessPage}
        isCustomPlan={isCustomPlan}
        selectedAmount={selectedAmount}
        fullPayment={fullPayment}
        showFrequency={showFrequency}
        telLink={telLink}
      />
    </div>
  )
};

export default PlanDetailBox;
