import React from 'react';
import { useLocale } from '../../hooks';
import allMessages from './messages';

import { ErrorPageContent } from '../../components';
import { paths } from '../../routes';

const SpecialOfferErrorPage = () => {
  const { messages } = useLocale(allMessages);
  return <ErrorPageContent href={paths.login} { ...messages }/>;
};

export default SpecialOfferErrorPage;
