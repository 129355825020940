import React from 'react';

import { wrapper } from './styles.scss';
import { SuccessIcon } from '../icons';
import { useLocale } from '../../hooks';

const AccountSwitchPopup = () => {
  const { messages } = useLocale();

  return (
    <div className={wrapper}>
      <SuccessIcon />
      <span>{messages.accountSwitched}</span>
    </div>
  )
};

export default AccountSwitchPopup;