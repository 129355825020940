import React, { useRef } from 'react';
import { any, arrayOf, bool, func, node, shape, string } from 'prop-types';
import { multipleChoice } from './styles.scss';

const MultipleChoice = ({ items, name, selectMultiple = false, onChange = () => {} }) => {
  const fieldsetRef = useRef(null);

  const inputName = `multiple-choice-${name}`;

  const handleChange = e => {
    const inputs = Array.prototype.slice.call(fieldsetRef?.current?.querySelectorAll('input:checked') || []);
    
    const result = selectMultiple
      ? inputs.map(input => input.value)
      : inputs[0]?.value;

    onChange(result, e);
  };

  return <fieldset ref={fieldsetRef} onChange={handleChange} className={multipleChoice}>
    { items.map((item, i) => {
      const id = `${inputName}-${i}`;

      return <>
        <input
          id={id}
          name={inputName}
          type={selectMultiple ? 'checkbox' : 'radio'}
          value={item.value}
          key={id + '-input'}/>
        <label htmlFor={id} key={id + '-label'}>
            { item.label }
        </label>
      </>;
    }) }
  </fieldset>;
};

MultipleChoice.propTypes = {
  items: arrayOf(shape({
    value: any.isRequired,
    label: node
  })).isRequired,
  name: string.isRequired,
  selectMultiple: bool,
  onChange: func
};

export default MultipleChoice;
