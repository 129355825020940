export default {
  en: {
    title: 'Choose Your Payment Frequency',
    selectOption: 'Select the option that works best for you',
    finalPayment: '* The final payment of your plan may be less than your other payments to reflect the remaining balance and avoid overpayment.',
  },
  es: {
    title: 'Elija su frecuencia de pago',
    selectOption: 'Seleccione la opción que mejor le convenga',
    finalPayment: '* El pago final de su plan puede ser menor que sus otros pagos para reflejar el saldo restante y evitar el pago excesivo.',
  }
};
