import React, { useState } from 'react';
import { paths } from '../../routes';
import PlanDetailBox from '../PlanDetailBox';
import { useLocale, useStateView } from '../../hooks';
import { generatePaymentScheduleFromPaymentPlan, paymentFrequencyFromSchedule } from '../../utils';
import Expander from '../Expander';
import AccountDetails from '../AccountDetails';
import PaymentFrequencyMessage from '../PaymentFrequencyMessage';
import { paymentPlanView } from '../../state/views';
import EmptyState from './EmptyState';
import Spinner from '../Spinner';

import { hiddenMobile } from './styles.scss';

const AccountDetailsBox = ({
  hasPlan,
  paymentPlans,
  detailsLabel,
}) => {
  const { messages } = useLocale();
  const [ paymentPlan, setPaymentPlan ] = useStateView(paymentPlanView)
  const [ loading, setLoading ] = useState(true);

  if (loading && hasPlan && paymentPlans && paymentPlans.length) {
    const currentPaymentPlan = paymentPlans.map(paymentPlan => {
      const dateParts = paymentPlan.paymentSchedule[0].paymentDate.split('-');
      const firstDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
      const paymentPlanObject = {
        ...paymentPlan,
        numPayments: paymentPlan.planLength,
        standardPaymentAmount: paymentPlan.paymentSize,
        lastPaymentAmount: paymentPlan.paymentSchedule.at(-1).paymentAmount,
        paymentFrequency: paymentFrequencyFromSchedule(paymentPlan.paymentSchedule),
        paymentDayOfWeek: firstDate.getDay(),
        paymentStartDate: firstDate
      };
      return paymentPlanObject;
    });
    setPaymentPlan(currentPaymentPlan[0]);
    setLoading(false);
  }

  return (
    <div>
      {hasPlan && paymentPlans ? (
        <PlanDetailBox
          planType={messages.paymentPlan}
          paymentPlan={paymentPlan}
          paymentFrequency={<PaymentFrequencyMessage />}
          telLink
        />
      ) : <EmptyState />}
      <Expander className={hiddenMobile} label={detailsLabel}>
        <AccountDetails />
      </Expander>
    </div>
  )
};

export default AccountDetailsBox;
