export default {
  en: {
    title: 'Special offer to resolve your balance',
    payInFullLabel: 'One Payment',
    createPlanLabel: 'Create a Plan',
    amount: 'Amount',
    initialStep: {
      title: '1. Set up your payment',
      subtitle: 'When would you like your payment to be scheduled?'
    },
    frequencyStep: {
      title: '2. Select your frequency',
      subtitle: 'Select your frequency for your payment'
    },
    paymentStep: {
      title: '2. Select payment method',
      subtitle: 'Choose your payment method',
      variant: '3. Select payment method'
    },
    continue: 'Continue',
  },
  es: {
    title: 'Oferta especial para resolver su saldo',
    payInFullLabel: 'Un pago',
    createPlanLabel: 'Cree un plan',
    amount: 'Monto',
    initialStep: {
      title: '1. Configure su pago',
      subtitle: '¿Cuándo desea que se programe su pago?'
    },
    frequencyStep: {
      title: '2. Seleccione la frecuencia de su pago',
      subtitle: 'Elija la frecuencia para su pago'
    },
    paymentStep: {
      title: '2. Seleccione el método de pago',
      subtitle: 'Elija su método de pago',
      variant: '3. Seleccione el método de pago'
    },
    continue: 'Continuar',
  }
};
