import React from 'react';
import { followLink } from '../../utils';
import { Link } from 'react-router-dom';

// Sometimes we want to perform an asynchronous action when a link is clicked,
// but the link's default navigation behavior takes us to the next page before
// the process is finished. For example, an HTTP request could be aborted before
// the response is received, or worse, before the request is made.

// This LazyLink component creates a Link that politely waits for your onClick
// handler to return, and any Promise it returns to resolve before navigating.
// You can optionally prevent navigation by returning `false` from the click
// callback or its returned Promise.

// The `target` attribute on the underlying Link is fully supported.

const LazyLink = ({ children, onClick, ...props }) => {
  const handleClick = e => {
    // just navigate if no custom onClick handler is set
    if (typeof onClick !== 'function') return;

    // prevent automatic navigation
    e.preventDefault();

    // wrap onClick in a Promise in case it doesn't already return one,
    // we do this instead of Promise.resolve(onClick(e)) so that if onClick
    // throws, the error will flow through the reject handler
    new Promise(resolve => resolve(onClick(e)))
      .then(result => {
        if (result === false) return;
        followLink(e.target); // navigate "manually"
      })
      .catch(err => {
        // fail open: onClick errors won't prevent navigation
        console.error(err);
        followLink(e.target);
      });
  };

  return <Link onClick={handleClick} { ...props }>{ children }</Link>;
};

// we don't introduce any properties Link doesn't already have, so copy them
LazyLink.propTypes = { ...Link.propTypes };

export default LazyLink;
