export default {
  en: {
    journey: 'The journey to being debt-free starts with a single step',
    heading: 'Welcome!',
    subheading: 'Select an account to get started',
    body: 'You will be able to switch accounts at any time',
    referenceNumber: 'Reference Number',
    storeBrand: 'Store Brand',
    currentBalance: 'Current Balance',
  },
  es: {
    journey: 'El camino para estar libre de deudas comienza con un solo paso',
    heading: '¡Bienvenido!',
    subheading: 'Seleccione una cuenta para comenzar',
    body: 'Podrá cambiar de cuenta en cualquier momento',
    referenceNumber: 'Número de referencia',
    storeBrand: 'Marca de la tienda',
    currentBalance: 'Saldo actual',
  }
};
