export default {
  en: {
    heading: {
      confirmPayment: 'Confirm and pay',
      confirmPaymentMethod: 'Confirm and pay',
      confirmPaymentPlan: 'Confirm and pay'
    },
    submit: 'Authorize'
  },
  es: {
    heading: {
      confirmPayment: 'Confirmar y pagar',
      confirmPaymentMethod: 'Confirmar y pagar',
      confirmPaymentPlan: 'Confirmar y pagar'
    },
    submit: 'Autorizar'
  }
};
