import React from "react";
import { HumanSittingIcon } from "../icons";
import { string } from 'prop-types';

import {emptyPage} from "./styles.scss"

function EmptyPage({PageTitle}) {
  return <div className={emptyPage}><HumanSittingIcon alt="No results found" />
  <h3>{PageTitle}.</h3></div>;
}


EmptyPage.propTypes = {
  PageTitle: string.isRequired,
};

export default EmptyPage;