export const formatCurrency = (num, currencySymbol = '$', withoutCurrency = false) => {
  let str = parseCurrencyFloat(num).toLocaleString('en');
  const decimalIndex = str.indexOf('.');

  if (decimalIndex > -1 && decimalIndex === str.length - 2) {
    str += '0';
  }

  return str
    .replace(/(^-?)/, `$1${withoutCurrency ? '' : currencySymbol}`)
    .replace(/(\.\d\d).*$/g, '$1')
    .replace(/\.0+$/g, '');
};

export const getStandardAndLastPayments = (totalAmount, numPayments) => {
  const standardPaymentAmount = (totalAmount / numPayments).toFixed(2);
  const remainder = totalAmount - standardPaymentAmount * (numPayments - 1);
  const lastPaymentAmount = remainder !== standardPaymentAmount && remainder;
  
  return { standardPaymentAmount, lastPaymentAmount };
};

export const parseCurrencyFloat = str =>
  parseFloat((str || 0).toString().replace(/[^.0-9-]/g, '').replace(/(\.\d\d).*$/g, '$1'))
