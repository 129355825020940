import axios from 'axios';
import { sessionView } from '../state/views';
import { logout } from '../utils';
import { clearSessionId } from './mixpanel';

const httpClient = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

httpClient.interceptors.request.use(config => {
  // add auth headers to each request
  const newHeaders = {
    'x-api-key': process.env.API_KEY
  };

  const { authToken } = sessionView.read().state;

  if (authToken) {
    newHeaders.Authorization = `Bearer ${authToken}`;
  }

  config.headers = {
    ...config.headers,
    ...newHeaders
  };

  return config;
});

httpClient.interceptors.response.use(
  // handle token refresh in response
  async response => {
    const { authToken } = response.data;

    if (authToken) {
      const expires = JSON.parse(atob(authToken.split('.')[1])).expires * 1000;
      sessionView.write({
        authToken,
        expires,
        authenticated: true
      });
    }

    return response?.data?.data;
  },
  // handle session expiration in response
  err => {
    if (err?.response?.status === 403 && sessionView.read().state?.authenticated) {
      // session expired, logout
      alert('Session expired. Returning to login page.');
      clearSessionId();
      logout();
    }

    return Promise.reject(err);
  }
);

export default httpClient;
