export default {
  en: {
    notification: 'This is a communication with a debt collector. This is an attempt to collect a debt and any information obtained will be used for that purpose. {knowYourRightsLink}',
    disclosuresLink: 'Know your rights',
    accountContact: 'Trouble accessing your account? {contactUsLink} or call us at {phoneNumber}',
    copyrightNotice: '© 2024 Spring Oaks Capital, LLC, All Rights Reserved.',
    contactUsUrl: 'https://springoakscapital.com/contact-us',
    contactUsLink: 'Contact us'
  },
  es: {
    notification: 'Esta es una comunicación con un cobrador de deudas. Este es un intento de cobrar una deuda y cualquier información obtenida se utilizará para ese propósito. {knowYourRightsLink}',
    disclosuresLink: 'Conozca sus derechos',
    accountContact: '¿Tiene problemas para acceder a su cuenta? {contactUsLink} o llámenos al {phoneNumber}',
    copyrightNotice: '© 2024 Spring Oaks Capital, LLC, todos los derechos reservados.',
    contactUsUrl: 'https://springoakscapital.com/contact-us',
    contactUsLink: 'Comuníquese con nosotros'
  }
};
