export default {
  en: {
    titlePart1: 'Provide ',
    titlePart2: 'Payment Information',

    subtitle: 'Select your payment method of preference',
    payInFullLabel: 'One Payment',
    createPlanLabel: 'Create a Plan',
    accountContact: 'If you have trouble accessing your account\nplease call us at 866-539-7525 or through the {contactUsLink} page.',
    copyrightNotice: '© 2024 Spring Oaks Capital, LLC, All Rights Reserved.',

    setupPayment: 'Set up your payment',
    paymentInstructions: 'When would you like your payment to be scheduled?',
    paymentLabel: 'Select a date',
    paymentFootnote: 'You can select a date up to {latency} day{multipleDays?s:} from today.',
    planIntro: 'Below is the repayment plan that was previously discussed:',

    selectPayments: 'Select your payments',
    selectMonthAndDay: 'Select month & day of payments',
    paymentDates: 'Payment Dates',
    paymentDate: 'Payment Date',
    amount: 'Amount',

    paymentMethod: 'Select your payment method',
    noObligation: 'Spring Oaks Capital, LLC is not obligated to renew this offer.'
  },
  es: {
    titlePart1: 'Proporcione ',
    titlePart2: 'la información de pago',
    subtitle: 'Seleccione su método de pago preferido',
    payInFullLabel: 'Un pago',
    createPlanLabel: 'Cree un plan',
    accountContact: 'Si tiene problemas para acceder a su cuenta, llámenos al 866-539-7525 o a través de la página {contactUsLink}.',
    copyrightNotice: '© 2024 Spring Oaks Capital, LLC, Todos los derechos reservados.',

    setupPayment: 'Seleccione sus pagos',
    paymentInstructions: '¿Cuándo desea que se programe su pago?',
    paymentLabel: 'Seleccione una fecha',
    paymentFootnote: 'Puede seleccionar una fecha hasta {latency} día{multipleDays?s:} a partir de hoy.',
    planIntro: 'A continuación, se detalla el plan de reembolsos que se analizó anteriormente:',
    
    selectPayments: 'Seleccione sus pagos',
    selectMonthAndDay: 'Seleccione el mes y día de los pagos',
    paymentDates: 'Fechas de pago',
    paymentDate: 'Fecha de pago',
    amount: 'Monto',
    
    paymentMethod: 'Seleccione su método de pago',
    noObligation: 'Spring Oaks Capital, LLC no está obligada a renovar esta oferta.'
  }
};
