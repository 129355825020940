import React from 'react'
import Button from '../Button';
import { formatCurrency, mixpanel, redirect, cn } from '../../utils';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { infoRow, balance, secondary, svgContainer, loaderInfo, paymentsMade, withText, startPlan } from './styles.scss';
import { useLocale, useStateView } from '../../hooks';
import { paymentPlanView, paymentHistoryView, selectedStepView, referenceView } from '../../state/views';
import { MIXPANEL_EVENTS, PAYMENT_FREQUENCIES, PAYMENT_PLAN_TYPES } from '../../constants';
import { paths } from '../../routes';

import noPaymentsSvg from '../../static/images/images/no-payments.svg';

import allMessages from './messages';

const ActivePlan = ({ hasPlan, percentage, amountPaid, remainingBalance, paymentStatus }) => {
  const { messages, formatMessage } = useLocale(allMessages);

  const [ history ] = useStateView(paymentHistoryView);
  const [ paymentPlan, setPaymentPlan, clearPaymentPlan ] = useStateView(paymentPlanView);
  const [ , setSelectedStep ] = useStateView(selectedStepView);
  const [ , setReference ] = useStateView(referenceView);

  const handlePayInFull = () => {
    mixpanel.track(MIXPANEL_EVENTS.clickPayInFull);

    clearPaymentPlan();
    setPaymentPlan({
      planType: PAYMENT_PLAN_TYPES.payInFull,
      numPayments: 1,
      standardPaymentAmount: remainingBalance,
      paymentFrequency: PAYMENT_FREQUENCIES.monthly,
      totalPaymentAmount: remainingBalance
    });

    setSelectedStep('setup');
    setReference(paths.home);
    redirect(paths.paymentPlan);
  };

  const handleClickRepaymentOptions = () => {
    mixpanel.track(MIXPANEL_EVENTS.clickMorePaymentOptions);

    redirect(paths.paymentPlan);
  };

  const PaymentsMade = () => {
    if (hasPlan) {
      return (
        <>
          <div className={paymentsMade}>
            {paymentStatus.paid}
          </div>
          <p>{messages.of} {paymentStatus.total} {formatMessage(messages.payments, { multiplePayments: true })}</p>
        </>
      )
    } else {
      if (history && history.length) {
        const paymentsHistory = history.filter(payment => payment.statusCode === 'PAID').length;
        return (
          <>
            <div className={paymentsMade}>{paymentsHistory}</div>
            <p>{formatMessage(messages.payments, { multiplePayments: true })}</p>
          </>
        )
      }
    }

    return;
  }

  return (
    <>
      <div className={svgContainer}>
        <div style={{ width: 250, height: 150 }}>
          <CircularProgressbar
            strokeWidth={6}
            value={percentage}
            circleRatio={0.5}
            styles={buildStyles({
              rotation: 0.75,
              strokeLinecap: 'round',
              pathColor: "#0563C7",
              trailColor: "#EAECF0",
              backgroundColor: "#EAECF0"
            })}
          />
          <div className={cn(loaderInfo, amountPaid > 0 ? withText : null)}>
            {amountPaid > 0 ? <PaymentsMade />
              : <>
                <img src={noPaymentsSvg} />
                <p>{messages.noPaymentsYet}</p>
              </>
            }
          </div>
        </div>
      </div>
      {!hasPlan && amountPaid === 0 && <h2 className={startPlan}>{messages.startPlan}</h2>}
      <div className={infoRow}>
        <h2>{messages.paid}</h2>
        <div className={balance}>
          <span>$ <h5>{formatCurrency(amountPaid, '', true)}</h5></span>
        </div>
      </div>
      <div className={infoRow}>
        <h2>{messages.remainingBalance}</h2>
        <div className={balance}>
          <span className={secondary}>$ <h6>{formatCurrency(remainingBalance, '', true)}</h6></span>
        </div>
      </div>
      {!hasPlan && <Button
        onClick={handleClickRepaymentOptions}
        data-action="select more repayment options"
        variant={['primary', 'full']}
      >
        {messages.viewPaymentPlans}
      </Button>}
      {remainingBalance > 0 && <Button
        onClick={handlePayInFull}
        data-action="select pay in full"
        variant={[ hasPlan ? 'primary' : 'secondary', 'full']}
      >
        {messages.payInFull}
      </Button>}
    </>
  )
};

export default ActivePlan;
