import React, { useEffect, useMemo, useRef, useState } from 'react';
import { clamp, cn, debugConsole, inputValues } from '../../utils';
import { bool, func, number, object, string } from 'prop-types';
import { stepContainer } from './styles.scss';

const nop = () => {};

const Step = ({
  name,
  active = false,
  stepperName,
  stepIndex,
  stepper,
  onStepIn = nop,
  onStepOut = nop,
  onStepShow = nop,
  children,
  ...props
}) => {
  // use the below data attributes on children of Step components to make easy
  // step navigation buttons

  const clickHandler = useMemo(() => {
    return e => {
      const { target } = e;

      if (target.hasAttribute('data-prev-step')) {
        stepper.prevStep();
      } else if (target.hasAttribute('data-next-step')) {
        stepper.nextStep();
      } else if (target.hasAttribute('data-goto-step')) {
        stepper.goToStep(parseInt(target.getAttribute('data-goto-step'), 10));
      } else if (target.hasAttribute('data-back-button')) {
        window?.history?.go(-1);
      }
    };
  }, [ stepper ]);

  useEffect(() => {
    if (active) onStepShow(stepper, stepIndex);
  }, [ active ]);

  const activeProp = active
    ? { 'data-active-step': true }
    : {};

  return <div onClick={clickHandler} data-step-index={stepIndex} { ...activeProp } { ...props }>{ children }</div>;
};

Step.propTypes = {
  name: string,
  active: bool,
  onStepIn: func,
  onStepOut: func,

  // used internally
  stepperName: string,
  stepIndex: number,
  stepper: object
};

// these 4 helpers let the Stepper component take advantage of the back/forward buttons

const hasHistoryState = name => name in (window?.history?.state?.steppers || {});
const getHistoryState = name => window?.history?.state?.steppers?.[name];
const setHistoryState = (name, state, replace = false) => {
  // sneakily merge Stepper state into immutable state object so we can
  // mash every stepper into window.history.state.steppers without deleting
  // anything else in window.history.state
  
  const newState = { ...(window?.history?.state || {}) };
  newState.steppers = newState.steppers || {};
  newState.steppers[name] = state;

  // if replace is truthy, a new entry in the browser history won't be created
  
  window?.history?.[replace ? 'replaceState' : 'pushState'](newState, '');
  return state;
};

const Stepper = React.forwardRef(({
  initialStep = 0,
  name,
  children,
  className,
  onStep = nop,
  ...props
}, ref) => {
  const rootRef = useRef(null);

  if (!hasHistoryState(name)) {
    setHistoryState(name, initialStep, true);
  }

  const currentStep = getHistoryState(name);
  const [ activeStep, setActiveStep ] = useState(currentStep);
  
  const setStep = step => {
    onStep(step);
    setActiveStep(step);
  };
  
  if (currentStep !== activeStep) {
    setStep(currentStep);
  }

  const handlePopState = () => {
    if (hasHistoryState(name)) {
      // trigger rerender on back button push
      setStep(getHistoryState(name));
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  // filter Step components in children
  const childrenArray = React.Children.toArray(children);
  const steps = childrenArray.filter(child => child.type === Step);
  const numSteps = childrenArray.length;

  // the stepper object will allow Step components that are direct descendents
  // of this component to control step flow and see some Stepper state

  const pushStep = step => {
    setHistoryState(name, step);
    const stepName = steps[step].props.name || step;
    // TODO mixpanel event
    
    setStep(step);
  };

  let stepping = false;
  const stepper = {
    getCurrentStep: () => getHistoryState(name),
    // go directly to step
    goToStep: async step => {
      if (step < 0 || step >= numSteps) {
        debugConsole.error(`Invalid step index ${step} in Stepper component "${name}"`);
        return;
      }

      // get values of any forms in that step and shove them into one object

      const stepInputs = rootRef?.current?.querySelectorAll('[data-active-step] input, [data-active-step] textarea');
      const inputFields = inputValues(stepInputs);

      if (stepping) {
        // don't call stepOut handlers twice if we step from inside a step handler
        pushStep(step);
        return;
      }

      stepping = true;

      // call any step event handlers, if the handlers explicitly return false then cancel stepping
      
      const stepOut = steps[currentStep]?.props?.onStepOut?.(inputFields, stepper, step);
      const stepOutResult = stepOut instanceof Promise ? await stepOut : stepOut;

      if (stepOutResult === false) {
        stepping = false;
        return;
      }

      const stepIn = steps[step]?.props?.onStepIn?.(inputFields, stepper, step);
      const stepInResult = stepOut instanceof Promise ? await stepIn : stepIn;

      stepping = false;

      if (stepInResult !== false) {
        pushStep(step);
      }
    },

    // go forward or back a number of steps
    moveStep: delta => stepper.goToStep(clamp(currentStep + delta, 0, numSteps - 1)),

    // provided for convenience when writing step logic
    nextStep: () => stepper.moveStep(1),
    prevStep: () => stepper.moveStep(-1),

    // data to pass to Step component as props
    name,
    numSteps,
    currentStep,
    element: rootRef?.current
  };

  // set ref on container element create

  useEffect(() => {
    ref.current = stepper;
  }, [ ref, stepper.element ]);

  // iterate over this component's children and inject a few props
  // into any Step components that are direct descendants

  const activeChildren = useMemo(
    () => childrenArray.map((child, i) => {
      return child.type === Step
        ? React.cloneElement(child, {
            active: currentStep === i,
            stepIndex: i,
            stepperName: name,
            stepper
          })
        : child;
    }),
    [ currentStep, ...childrenArray ]
  );

  return <div
    ref={rootRef}
    className={cn(stepContainer, className)}
    { ...props }>
      { activeChildren }
    </div>;
});

Stepper.propTypes = {
  name: string.isRequired,
  initialStep: number
};

export { Step, Stepper };
